import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";

import successImage from "../Images/Seccess.png";
import noSuccessImage from "../Images/noSuccess.png";
import loadingImage from "../Images/4322935.jpg";
//
function EmailConfirmed() {
  const location = useLocation();
  const history = useNavigate();
  const [email, setEmail] = useState();
  const [isVerified, setIsVerified] = useState(null);
  const [pointerEvent, setPointerEvent] = useState(null);
  const [transition, setTransition] = useState(null);
  const [opacity, setOpacity] = useState(null);
  const [wrapperOpacity, setWrapperOpacity] = useState();
  const [wrapperTransform, setWrapperTransform] = useState();
  const [wrapperTransition, setWrapperTransition] = useState();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const [message, setMessage] = useState("");

  useEffect(() => {
    let parameters = location.search;
    const queryParams = new URLSearchParams(parameters);

    const finalEmail = queryParams.get("userEmail");
    const finalKey = queryParams.get("key");

    console.log("Email:", finalEmail);
    console.log("Key:", finalKey);
    // let keyIndex = parameters.indexOf("&key=");

    // let finalEmail = parameters.slice(11, keyIndex);
    // let finalKey = parameters.slice(keyIndex + 5);

    // let finalEmail = new URLSearchParams(location.search).get("userEmail");
    // let finalKey = new URLSearchParams(location.search).get("key");

    let verified = false;
    if (finalEmail && finalKey) {
      setEmail(finalEmail);

      axios
        .post(
          "/emailVerification",
          { email: finalEmail, code: finalKey }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Methods":
          //       "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          //   },
          // }
        )
        .then((data) => {
          setMessage(data.data.message);
          setIsVerified(data.data.verified);

          setPointerEvent("auto");
          setTransition("all 300ms ease-in-out");
          setOpacity("1");
          setWrapperOpacity("1");
          setWrapperTransform("scale(1)");
          setWrapperTransition(
            "opacity 250ms 500ms ease, transform 350ms 500ms ease"
          );
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response.data.message);
            setIsVerified(error.response.data.verified);
            // console.log("Error1:", error.response.data.message);
            // setMessage(error.response.data.message);
          } else if (error.request) {
            // message = "No response from the server. Please try again later.";
            // console.log("No response from the server. Please try again later.");
            setMessage("No response from the server. Please try again later.");
            setIsVerified("unknown");
          } else {
            // message = "An error occurred. Please try again.";
            // console.log("An error occurred. Please try again.");
            setMessage("An error occurred. Please try again.");
            setIsVerified("unknown");
          }
        });
    } else {
      setIsVerified("unknown");
      setMessage(
        "Invalid verification link. Please sign up again to receive a new verification link."
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (isVerified !== null) {
      setTimeout(() => {
        if (isVerified == true) {
          history("/login", { state: { email: email } });
        } else {
          history("/signup");
        }
      }, 10000);
    }
  }, [isVerified]);

  return (
    <Container>
      <EmailVerificationNoteContainer>
        {isVerified == true ? (
          <div>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={successImage}
                  alt="green iguana"
                />
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Email Verified
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {message}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        ) : isVerified == false ? (
          <div>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={noSuccessImage}
                  alt="green iguana"
                />
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Email Not Verified
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {message}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        ) : isVerified == "unknown" ? (
          <div>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={noSuccessImage}
                  alt="green iguana"
                />
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Error
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {message}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        ) : (
          <div>
            <div>
              <Card sx={{ maxWidth: 345, minWidth: 300 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="300"
                    image={loadingImage}
                    alt="green iguana"
                  />
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <LoadingBox>Loading...</LoadingBox>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
        )}

        <VerificationLogo />
        <VerificationNote></VerificationNote>
      </EmailVerificationNoteContainer>
    </Container>
  );
}

export default EmailConfirmed;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
`;

const EmailVerificationNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const VerificationLogo = styled.div``;
const VerificationNote = styled.div``;
const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
