import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowTeamName from "../pages/showTeamName";

function ITTNotification() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isAccepted, setIsAccepted] = useState();
  const [isOptionDisabled, setIsOptionDisabled] = useState();
  const location = useLocation();
  useEffect(() => {
    // console.log("Haminja:", location.state);
    if (location.state) {
      setIsOptionDisabled(location.state.disabled);
    }
  }, [location.state]);

  useEffect(() => {
    // console.log("Something here:", isAccepted);
  }, [isAccepted]);

  const onClickSubmitAnswer = () => {
    if (isAccepted) {
      if (isAccepted == "yes") {
        axios.post("/addUserToTeam", {
          email: user.email,
          teamId: location.state.teamId,
        });
      }
      setIsOptionDisabled(true);
      axios.post("/disableNote", {
        email: user.email,
        noteId: location.state.noteId,
      });
    }
  };

  return (
    <Container>
      {location.state ? (
        <NoteContainer>
          <NoteTitle>{location.state.title}</NoteTitle>
          <NoteContent>
            <div style={{ marginBottom: "5px" }}>Dear {user.fullName},</div>
            <div>
              We are delighted to invite you to join our team today. You can
              collaborate with the other members and share the status of your
              courses with your supervisor(s). You will be able to find the team
              name under My Company menu option. By clicking on the team name,
              you can find the member roles and request to leave the team
              whenever you want.
            </div>
            {/* <NoteOptions>
              <YesOption>
                <input
                  type="radio"
                  name="acceptInvitation"
                  style={{ marginRight: "5px" }}
                  value="yes"
                  onChange={(e) => setIsAccepted(e.target.value)}
                  disabled={isOptionDisabled}
                />
                Yes
              </YesOption>
              <NoOption>
                <input
                  type="radio"
                  name="acceptInvitation"
                  style={{ marginRight: "5px" }}
                  value="no"
                  onChange={(e) => setIsAccepted(e.target.value)}
                  disabled={isOptionDisabled}
                />
                No
              </NoOption>
            </NoteOptions> */}
            {/* <NoteSubmit
              onClick={() => onClickSubmitAnswer()}
              disabled={isOptionDisabled}
            >
              Submit the Answer
            </NoteSubmit> */}
          </NoteContent>
        </NoteContainer>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default ITTNotification;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;

const NoteContainer = styled.div`
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  text-align: justify;
`;
const NoteTitle = styled.div`
  margin-bottom: 10px;
  display: flex;
`;
const NoteContent = styled.div``;

const NoteOptions = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const YesOption = styled.div`
  margin-right: 10px;
`;
const NoOption = styled.div``;
const NoteSubmit = styled.button`
  padding: 10px;
  cursor: pointer;
`;
