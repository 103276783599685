import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowCourseStatus from "../Components/ShowCourseStatus";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import firebaseApp from "../firebase";
import { getAuth, signOut } from "firebase/auth";

import { useNavigate } from "react-router-dom";

import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar,
} from "@mui/x-data-grid";
import axios from "axios";
import { style } from "@mui/system";

const columns = [
  { id: "name", label: "Full Name" },
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "name", label: "Course Name" }, //, minWidth: 100
  { id: "status", label: "Course Status" },
];

const dataGridColumns = [
  { field: "userName", headerName: "Full Name", width: 150, flex: 1 },
  { field: "userEmail", headerName: "User Email", width: 150, flex: 1 },
  { field: "courseName", headerName: "Course Name", width: 150, flex: 1 },
  { field: "expirationDate", headerName: "Course Status", width: 150, flex: 1 },
  { field: "certificateId", headerName: "Certificate#", width: 150, flex: 1 },
  //   { field: "courseId", headerName: "Course Status", width: 150, flex: 1 },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
// should work on it
function LaraCheckUserCourseStatus(props) {
  const history = useNavigate();
  const [teamMembers, setTeamMembers] = useState(null);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [dataGridData, setDataGridData] = useState(null);
  const [finalDataGridData, setFinalDataGridData] = useState(null);

  const [dataGridPageSize, setDataGridPageSize] = useState(5);

  const [courseInfo, setCourseInfo] = useState(null);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [certificateId, setCertificateId] = useState("");
  const [searchResult, setSearchResult] = useState("");

  const onClickSearchInspectors = () => {
    if (
      userEmail.trim() != "" ||
      userName.trim() != "" ||
      certificateId.trim() != ""
    ) {
      axios
        .post("/returnInspectorsSearchResult", {
          email: userEmail,
          name: userName,
          certId: certificateId,
        })
        .then((data) => {
          console.log("Result:", data.data);
          setSearchResult(data.data);
        });
    }
  };

  //   useEffect(() => {
  //     if (props.data) {
  //       let arr = props.data;
  //       let newArr = [];
  //       arr.forEach((items) => {
  //         items.sharedCourses.forEach((crsId) => {
  //           newArr.push({
  //             teamId: items.teamId,
  //             sharedCourse: crsId,
  //             userEmail: items.userEmail,
  //             role: items.role,
  //           });
  //         });
  //       });
  //       Promise.all(newArr).then(() => setTeamMembers(newArr));
  //     }
  //   }, [props.data]);

  // useEffect(() => {
  //   if (teamMembers) {
  //     let tempArr = [...teamMembers];
  //     // console.log("Team members:", recordProps);
  //     tempArr.forEach((item, key) => {
  //       item.id = key;
  //     });
  //     // console.log("Temp array:", tempArr);
  //     setMainRecordProps(tempArr);
  //     setDataGridData(tempArr);
  //   }
  //   //   return () => {
  //   //     setMainRecordProps(null);
  //   //   };
  // }, [teamMembers]);

  useEffect(() => {
    // axios.post("/michiganUsersStatus").then((data) => {
    //   setTeamMembers(data.data);
    // });
    // should get it in certificate
    // axios.post("/getAllCourses").then((data) => {
    //   setCourseInfo(data.data);
    // });
    // return () => {
    //   setMainRecordProps(null);
    //   setTeamMembers(null);
    // };
  }, []);

  // const extractDataGridData = async () => {
  //   // let tempDataGridArr = [...dataGridData];
  //   // let mainDataGridArr = [...dataGridData];
  //   let tempDataGridArr = [...mainRecordProps];
  //   let mainDataGridArr = [...mainRecordProps];
  //   let allCourseInfoArr = [...courseInfo];
  //   // console.log("in extractDataGridData:", allCourseInfoArr);
  //   // console.log("main data grid:", tempDataGridArr.length);
  //   for (var i = 0; i < tempDataGridArr.length; i++) {
  //     //   const courseStat = await axios.post("/courseStatus", {
  //     //     courseId: tempDataGridArr[i].sharedCourse,
  //     //     userEmail: tempDataGridArr[i].userEmail,
  //     //   });
  //     //   console.log("main data grid:", i);
  //     let username = await axios.post("/testAUser", {
  //       email: tempDataGridArr[i].userEmail,
  //     });
  //     // console.log("User Name:", username.data.displayName);

  //     const courseIndex = allCourseInfoArr.findIndex(
  //       (item) => item._ref._path.segments[1] === tempDataGridArr[i].courseId
  //     );
  //     //   console.log("index:", courseIndex);
  //     const courseExpirationDate = allCourseInfoArr[courseIndex]._fieldsProto;
  //     //   console.log("course info:", courseExpirationDate);

  //     let date = new Date(tempDataGridArr[i].certifiedAt);
  //     let newDate = new Date(
  //       date.setMonth(
  //         date.getMonth() +
  //           parseInt(courseExpirationDate.expireMonth.integerValue)
  //       )
  //     );

  //     let current = new Date();
  //     let isCourseExpired = newDate < current ? true : false;

  //     mainDataGridArr[i].courseStatus = tempDataGridArr[i].isCertified
  //       ? isCourseExpired
  //         ? "Expired"
  //         : "Certified"
  //       : Number(tempDataGridArr[i].progressing.toFixed(2)) + "%";

  //     mainDataGridArr[i].courseName = courseExpirationDate.name.stringValue;
  //     mainDataGridArr[i].userName = username.data.displayName;
  //   }

  //   setFinalDataGridData(mainDataGridArr);
  // };

  // const onClickLaraLogOut = () => {
  //   const auth = getAuth(firebaseApp);
  //   signOut(auth)
  //     .then(() => {
  //       localStorage.removeItem("larauser");
  //       props.changeReset(props.propReset + 1);
  //       props.changeLaraUser(null);
  //       history("/");
  //     })
  //     .catch((error) => {
  //       // An error happened.
  //     });
  // };

  // useEffect(() => {
  //   if (mainRecordProps && courseInfo) {
  //     //   console.log("team members:", mainRecordProps);
  //     //   setFinalDataGridData(mainRecordProps);
  //     extractDataGridData();
  //   }
  // }, [mainRecordProps, courseInfo]);

  // useEffect(() => {
  //   if (finalDataGridData) {
  //     // console.log("This is data grid:", finalDataGridData);
  //   }
  // }, [finalDataGridData]);

  // useEffect(() => {
  //   console.log(userName);
  // }, [userName]);

  return (
    <Container>
      <FilteringContainer>
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          onChange={(e) => setUserName(e.target.value)}
          value={userName}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          onChange={(e) => setUserEmail(e.target.value)}
          value={userEmail}
        />
        <TextField
          id="outlined-basic"
          label="Certification ID"
          variant="outlined"
          onChange={(e) => setCertificateId(e.target.value)}
          value={certificateId}
        />
        <Button variant="contained" onClick={() => onClickSearchInspectors()}>
          Search
        </Button>
      </FilteringContainer>
      <CustomTableContainer>
        {searchResult !== null && searchResult && searchResult.length > 0 ? (
          <TableOfContentContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Full Name</TableCell>
                  <TableCell align="left">Course Name</TableCell>
                  <TableCell align="left">Certification ID</TableCell>
                  <TableCell align="left">Expiration Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.userName}</TableCell>
                    <TableCell align="left">{row.courseName}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.certificateId}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(row.expirationDate).toDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableOfContentContainer>
        ) : (
          <div></div>
        )}
      </CustomTableContainer>
      {/* {finalDataGridData ? (
        <CustomTable>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              columns={dataGridColumns}
              rows={finalDataGridData}
              components={{
                Toolbar: GridToolbar,
              }}
              style={{ backgroundColor: "white" }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={dataGridPageSize}
              onPageSizeChange={(newPageSize) =>
                setDataGridPageSize(newPageSize)
              }
            />
          </div>
          <LaraLogOutHolder>
            <LaraLogOutButton onClick={() => onClickLaraLogOut()}>
              Log Out
            </LaraLogOutButton>
          </LaraLogOutHolder>
        </CustomTable>
      ) : (
        <div>Loading...</div>
      )} */}
    </Container>
  );
}

export default LaraCheckUserCourseStatus;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;

const CustomTableContainer = styled.div`
  margin-top: 15px;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;
const LaraLogOutHolder = styled.div`
  margin-top: 10px;
  display: flex;
`;
const LaraLogOutButton = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: #09323b;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: 600;
`;
const CustomTable = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
const Header = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
`;
// const TableBody = styled.div`
//   overflow: auto;
// `;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
// const TableRow = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;
padding:5px;
`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 28px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);

const FilteringContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  justify-items: center;
  gap: 5px;
`;
const TableOfContentContainer = styled.div``;
