import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import PaginationTable from "./PaginationTable";
import TeamMemeberTable from "./TeamMemeberTable";

function TeamMembers() {
  const location = useLocation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [teamId, setTeamId] = useState(location.state.teamID);
  const [teamMembers, setteamMembers] = useState(null);
  const [teamMemberEmail, setTeamMemberEmail] = useState(null);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onChangeTeamMemberEmail = (e) => {
    setTeamMemberEmail(e.target.value);
  };

  const onClickAddTeamMember = () => {
    if (isEmail(teamMemberEmail) && !isEmpty(teamMemberEmail)) {
      axios
        .post("/findTeamMember", { teamId: teamId, userEmail: teamMemberEmail })
        .then((data) => {
          // console.log("data size:", data.data.dataLength);
          if (data.data.dataLength === 0) {
            axios
              .post("/addTeamMember", {
                userEmail: teamMemberEmail,
                teamId: teamId,
                teamSupervisor: user.email,
              })
              .then((data) => {
                setteamMembers([...teamMembers, { useEmail: teamMemberEmail }]);
              });
          } else {
            // console.log("This user is in the team");
          }
        });
    } else {
      // console.log("The email is invalid!");
    }
  };

  useEffect(() => {
    if (teamId) {
      axios.post("/returnTeamMembers", { teamId: teamId }).then((data) => {
        // console.log("Team members:", data.data);
        setteamMembers(data.data);
      });
    }

    // console.log("Team ID:", location.state.teamID);
  }, [teamId]);

  // useEffect(() => {
  //   console.log(teamMembers);
  // }, [teamMembers]);

  return (
    <Container>
      {/* <AddTeamMember>
        <AddTeamMemberInput
          type="text"
          placeholder="Email..."
          onChange={(e) => onChangeTeamMemberEmail(e)}
        />
        <AddTeamMemberButton onClick={() => onClickAddTeamMember()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-plus-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
        </AddTeamMemberButton>
        <AddTeamMembersButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-cloud-upload-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z"
            />
          </svg>
        </AddTeamMembersButton>
      </AddTeamMember> */}
      {/* {teamMembers ? <PaginationTable data={teamMembers} /> : <div></div>} */}
      {teamMembers && teamMembers?.length !== 0 ? (
        <TeamMemeberTable data={teamMembers} />
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default TeamMembers;

const Container = styled.div`
  padding: 25px;
`;

const AddTeamMember = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 20px;
`;
const AddTeamMemberInput = styled.input`
  padding: 9px;
  margin-right: 5px;
  /* width: 300px; */
  width: 100%;
`;
const AddTeamMemberButton = styled.button`
  padding: 7px;
  width: 50px;
  margin-right: 5px;
`;
const AddTeamMembersButton = styled.button`
  padding: 5px;
  padding-top: 9px;
  width: 50px;
`;
