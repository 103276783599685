import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";

function TeamMemeberTable(props) {
  const [recordProps, setRecordProps] = useState(props.data);
  const [emailSearchDisplay, setEmailSearchDisplay] = useState([]);
  const [courseNameEntered, setCourseNameEntered] = useState("");

  const onChangeEmailSearch = (e) => {
    if (recordProps) {
      let newArray = [...recordProps];
      for (var i = 0; i < newArray.length; i++) {
        if (
          newArray[i].useEmail
            .toUpperCase()
            .indexOf(e.target.value.toUpperCase()) > -1
        ) {
          newArray[i].displayEmail = false;
        } else {
          newArray[i].displayEmail = true;
        }
      }
      setRecordProps(newArray);
    }
  };

  const onChangeCourseSearch = (e) => {
    if (recordProps) {
      // console.log("Entering something:", e.target.value);
      setCourseNameEntered(e.target.value);

      // let newArray = [...recordProps];
      // for (var i = 0; i < newArray.length; i++) {
      //   if (
      //     newArray[i].courseName
      //       .toUpperCase()
      //       .indexOf(e.target.value.toUpperCase()) > -1
      //   ) {
      //     newArray[i].displayCourse = false;
      //   } else {
      //     newArray[i].displayCourse = true;
      //   }
      // }
      // setRecordProps(newArray);
    }
  };

  const onChangeStatusSearch = (e) => {
    const status = e.target.value;
    if (status === "pending") {
      if (recordProps) {
        let newArray = [...recordProps];
        for (var i = 0; i < newArray.length; i++) {
          if (!newArray[i].isCertified && !newArray[i].isChecked) {
            newArray[i].displayStatus = false;
          } else {
            newArray[i].displayStatus = true;
          }
        }
        setRecordProps(newArray);
      }
    } else if (status === "firstHalf") {
      if (recordProps) {
        let newArray = [...recordProps];
        for (var i = 0; i < newArray.length; i++) {
          if (
            !newArray[i].isCertified &&
            newArray[i].isChecked &&
            newArray[i].progressing <= 50
          ) {
            newArray[i].displayStatus = false;
          } else {
            newArray[i].displayStatus = true;
          }
        }
        setRecordProps(newArray);
      }
    } else if (status === "secondHalf") {
      if (recordProps) {
        let newArray = [...recordProps];
        for (var i = 0; i < newArray.length; i++) {
          if (
            !newArray[i].isCertified &&
            newArray[i].isChecked &&
            newArray[i].progressing > 50
          ) {
            newArray[i].displayStatus = false;
          } else {
            newArray[i].displayStatus = true;
          }
        }
        setRecordProps(newArray);
      }
    } else if (status === "certified") {
      if (recordProps) {
        let newArray = [...recordProps];
        for (var i = 0; i < newArray.length; i++) {
          if (newArray[i].isCertified && newArray[i].isChecked) {
            newArray[i].displayStatus = false;
          } else {
            newArray[i].displayStatus = true;
          }
        }
        setRecordProps(newArray);
      }
    } else {
      if (recordProps) {
        let newArray = [...recordProps];
        for (var i = 0; i < newArray.length; i++) {
          newArray[i].displayStatus = false;
        }
        setRecordProps(newArray);
      }
    }
  };

  //   useEffect(() => {
  //     console.log("Dataaaa:", props.data);
  //   }, []);

  useEffect(() => {
    // console.log("My props:", recordProps);
  }, [recordProps]);

  return (
    <Container>
      <Table>
        <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
          <CourseSearchContainer>
            <CourseSearch
              onChange={(e) => onChangeCourseSearch(e)}
              placeholder="Course..."
            />
            <CourseSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </CourseSearchButton>
          </CourseSearchContainer>
          <StatusSearchContainer>
            <StatusSearch
              defaultValue="select"
              onChange={(e) => onChangeStatusSearch(e)}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="firstHalf"> {`<=`}50%</option>
              <option value="secondHalf">{`>`}50%</option>
              <option value="certified">Certified</option>
            </StatusSearch>
            <StatusSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </StatusSearchButton>
          </StatusSearchContainer>
        </SearchTable>
        <Header>
          <FirstHeader>Student Email</FirstHeader>
          <SecondHeader>Course Name</SecondHeader>
          <ThirdHeader>Course Progress</ThirdHeader>
        </Header>
        <TableBody>
          {recordProps.map((item, key) => (
            <TableRow
              style={{
                display:
                  !item?.displayEmail &&
                  !item?.displayCourse &&
                  !item?.displayStatus
                    ? ""
                    : "none",
              }}
            >
              <FirstColumn>{item.useEmail}</FirstColumn>
              <SecondColumn>
                {/* {item.courseName} */}
                <ShowCourseName
                  courseId={item.courseId}
                  keyProp={key}
                  courseName={courseNameEntered}
                  changeData={(val) => setRecordProps(val)}
                  allData={recordProps}
                />
              </SecondColumn>
              <ThirdColumn>
                {item.isCertified
                  ? "Certified"
                  : item.isChecked
                  ? item.progressing
                  : "Pending"}
              </ThirdColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default TeamMemeberTable;

const Container = styled.div`
  /* padding: 20px; */
`;
const Table = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
const Header = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
`;
const TableBody = styled.div`
  height: 500px;
  overflow: auto;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
`;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const TableRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;
padding:5px;
`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 28px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);
