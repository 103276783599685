import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Questions from "./Questions";

function QuizPage(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [courseId, setCourseId] = useState(props.courseId);
  const [lectureId, setLectureId] = useState(props.lectureId);
  const [questions, setQuestions] = useState(null);
  const [isHidden, setIsHidden] = useState(true);
  const [isDiabled, setIsDisabled] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [grading, setGrading] = useState(0);
  const [displayGrade, setDisplayGrade] = useState(true);
  const [bestGrade, setBestGrade] = useState(0);

  useEffect(() => {
    if (courseId && lectureId) {
      axios
        .post("/extractQuizCourseContent", { courseId, lectureId })
        .then((data) => {
          // console.log("first data:", data.data);
          // console.log(Object.entries(data.data));
          let quizArr = Object.keys(data.data);
          let finalQuizes = [];
          let count = 0;
          while (quizArr.length > 0 && count < 5) {
            let rndInt = Math.floor(Math.random() * quizArr.length) + 1;
            finalQuizes.push(data.data[quizArr[rndInt - 1]]);
            quizArr.splice(rndInt - 1, 1);
            count = count + 1;
          }
          // console.log(finalQuizes);
          Promise.all(finalQuizes).then(() => setQuestions(finalQuizes));
          // setQuestions(data.data);
        });
    }
  }, [courseId, lectureId]);

  useEffect(() => {
    // console.log("Outer isHidden:", isHidden);
  }, [isHidden]);

  useEffect(() => {
    // console.log("Grade outer:", grading);
  }, [grading]);

  const changeHiddenDisable = () => {
    // console.log("grade:", grading);
    setIsDisabled(true);
    setIsHidden(false);
    setButtonDisable(true);
    setDisplayGrade(false);
    axios
      .post("/retunBestGrade", {
        courseId: courseId,
        lectureId: lectureId,
        userEmail: user.email,
        grade: grading,
      })
      .then((data) => {
        setBestGrade(data.data.grade);
        props.menuReload(new Date().getTime());
      });
    // setQuestions(null);
  };

  return (
    <Container>
      {questions ? (
        <div>
          <Questions
            key={"QuestionKey"}
            questions={questions}
            isHidden={isHidden}
            isDiabled={isDiabled}
            buttonDisable={() => setButtonDisable()}
            grade={(val) => setGrading(val)}
          />
          <ButtonContainer>
            <SubmitQuiz
              onClick={() => changeHiddenDisable()}
              disabled={buttonDisable}
              style={{
                backgroundColor: buttonDisable ? "gray" : "#5fb05f",
                cursor: buttonDisable ? "default" : "pointer",
              }}
            >
              Submit
            </SubmitQuiz>
          </ButtonContainer>
          <GradeContainer style={{ display: displayGrade ? "none" : "block" }}>
            Your best grade is {Number(bestGrade.toFixed(1))}/100.
          </GradeContainer>
        </div>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default QuizPage;

const Container = styled.div``;
const SubmitQuiz = styled.button`
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

const GradeContainer = styled.div``;
