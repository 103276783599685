import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import CourseInnerContent from "../Components/CourseInnerContent";
import LecturePage from "../Components/LecturePage";
import MoreInfoPage from "./MoreInfoPage";
import QuizPage from "./QuizPage";
import { style } from "@mui/system";

function CourseContent(props) {
  const history = useNavigate();
  const { state } = useLocation();
  const [courseContentInfo, setCourseContentInfo] = useState(null);
  const [courseInforType, setCourseInforType] = useState(null);
  const [lectureInfoId, setLectureInfoId] = useState(null);
  const [resetContent, setResetContent] = useState(0);
  const [marylandDocument, setMarylandDocument] = useState("");
  // const [lectureVideoTime, setLectureVideoTime] = useState(0);

  const [componentReload, setComponentReload] = useState(0);
  const [resetMenu, setResetMenu] = useState(0);

  const returnCourseContent = (type, courseId) => {
    // console.log("Video Time is:");
    setComponentReload(new Date().getTime());
    if (type == courseInforType) {
      setCourseInforType(type);
      setResetContent(resetContent + 1);
    } else {
      setCourseInforType(type);
    }

    setLectureInfoId(courseId);
  };

  useEffect(() => {
    // console.log("type:", courseInforType);
  }, [courseInforType]);

  useEffect(() => {
    axios
      .post("/extractCourseContent", { courseId: state.courseID })
      .then((data) => {
        setCourseContentInfo(data.data);
      });

    axios.get("/extractMarylandDoc").then((data) => {
      setMarylandDocument(data.data);
    });
  }, []);

  useEffect(() => {
    // console.log("Reset menu:", resetMenu);
  }, [resetMenu]);

  return (
    <Container>
      <CourseMenuContainer>
        {courseContentInfo ? (
          courseContentInfo.map((item) => (
            <CourseCheckboxContainer
              onClick={() => returnCourseContent(item.type, item.id)}
            >
              <CourseInnerContent
                lectureId={item.id}
                courseId={state.courseID}
                key={resetMenu}
              />
              {item.title}
            </CourseCheckboxContainer>
          ))
        ) : (
          <div></div>
        )}
      </CourseMenuContainer>
      <CourseContentContainer>
        {courseInforType ? (
          courseInforType == "video" ? (
            lectureInfoId ? (
              <LecturePage
                lectureId={lectureInfoId}
                courseId={state.courseID}
                menuReload={(val) => setResetMenu(val)}
                key={resetContent}
                // videoTime={(val) => setLectureVideoTime(val)}
                // reload={componentReload}
              />
            ) : (
              <WelcomePage>Welcome</WelcomePage>
            )
          ) : courseInforType == "text" ? (
            <MoreInfoPage
              lectureId={lectureInfoId}
              courseId={state.courseID}
              key={resetContent}
              // reload={componentReload}
            >
              Some more info
            </MoreInfoPage>
          ) : (
            <QuizPage
              // key={componentReload}
              lectureId={lectureInfoId}
              courseId={state.courseID}
              menuReload={(val) => setResetMenu(val)}
              key={resetContent}
              // reload={componentReload}
            >
              The quiz page
            </QuizPage>
          )
        ) : (
          <WelcomePage>
            <WelcomeContent>Welcome</WelcomeContent>
            <DocumentContainer>
              {state.myCourseName.trim() ===
                "Maryland Petroleum Class C Dispenser" ||
              state.myCourseName.trim() ===
                "Maryland Petroleum Class C Generator" ? (
                <a href={marylandDocument} target="_blank">
                  Class C Operator Training Checklist and Certification
                </a>
              ) : (
                <div></div>
              )}
            </DocumentContainer>
          </WelcomePage>
        )}
      </CourseContentContainer>
    </Container>
  );
}

export default CourseContent;

const Container = styled.div`
  padding: 70px 0px 0px 0px;
  display: grid;
  grid-template-columns: 200px auto;
  height: 100%;
  overflow: auto;
`;
const CourseCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const CourseMenuContainer = styled.div`
  border-right: 1px solid black;
  padding-top: 20px;
  padding-left: 20px;
`;
const CourseContentContainer = styled.div`
  padding: 20px;
`;

// const LecturePage = styled.div``;
// const MoreInfoPage = styled.div``;
// const QuizPage = styled.div``;
const WelcomePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const WelcomeContent = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
