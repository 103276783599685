import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

function ShowCourseStatus(props) {
  const [userCourseInfo, setUserCourseInfo] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (props.courseId) {
      axios
        .post("/courseStatus", {
          courseId: props.courseId,
          userEmail: props.userEmail,
        })
        .then((data) => {
          setUserCourseInfo(data.data);
          axios
            .post("/returnMyCoursesInformation", { courseId: props.courseId })
            .then((courseInfo) => {
              // console.log(
              //   "chejoori begam doooset daram:",
              //   courseInfo.data.expireMonth
              // );

              // console.log(
              //   "chejoori begam doooset nadaram:",
              //   data.data.certifiedAt
              // );

              setExpirationDate(courseInfo.data.expireMonth);
            });
        });
    }
  }, [props.courseId]);

  //   useEffect(() => {
  //     console.log("haminja", props.allData);
  //   }, [props.allData]);

  useEffect(() => {
    // console.log("status:", props.courseStatus);
    // console.log("course info:", userCourseInfo);
    // if (userCourseInfo) {

    const status = props.courseStatus;
    if (status === "pending") {
      // console.log("status pending");
      if (props.allData) {
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        if (!userCourseInfo) {
          newArray[props.keyProp].displayStatus = false;
        } else {
          newArray[props.keyProp].displayStatus = true;
        }
        //   }
        props.changeData(newArray);
      }
    } else if (status === "firstHalf") {
      if (props.allData) {
        // console.log("status first half");
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        if (userCourseInfo && userCourseInfo.progressing <= 50) {
          newArray[props.keyProp].displayStatus = false;
        } else {
          newArray[props.keyProp].displayStatus = true;
        }
        //   }
        props.changeData(newArray);
      }
    } else if (status === "secondHalf") {
      if (props.allData) {
        // console.log("status second half");
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        if (
          userCourseInfo &&
          userCourseInfo.progressing > 50 &&
          !userCourseInfo.isCertified &&
          !isExpired
        ) {
          newArray[props.keyProp].displayStatus = false;
        } else {
          newArray[props.keyProp].displayStatus = true;
        }
        //   }
        props.changeData(newArray);
      }
    } else if (status === "certified") {
      if (props.allData) {
        // console.log("status certified");
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        if (userCourseInfo && userCourseInfo.isCertified && !isExpired) {
          newArray[props.keyProp].displayStatus = false;
        } else {
          newArray[props.keyProp].displayStatus = true;
        }
        //   }
        props.changeData(newArray);
      }
    } else if (status === "expired") {
      if (props.allData) {
        // console.log("status certified");
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        if (userCourseInfo && isExpired) {
          newArray[props.keyProp].displayStatus = false;
        } else {
          newArray[props.keyProp].displayStatus = true;
        }
        //   }
        props.changeData(newArray);
      }
    } else {
      if (props.allData) {
        // console.log("false");
        let newArray = [...props.allData];
        //   for (var i = 0; i < newArray.length; i++) {
        newArray[props.keyProp].displayStatus = false;
        //   }
        props.changeData(newArray);
      }
    }
    // }
  }, [props.courseStatus]);

  useEffect(() => {
    if (expirationDate && userCourseInfo) {
      let date = new Date(userCourseInfo.certifiedAt);
      let newDate = new Date(date.setMonth(date.getMonth() + expirationDate));
      let current = new Date();
      // console.log("new date:", newDate);
      // console.log("current date:", current);
      // console.log("is expired?", newDate < current ? "Yes" : "No");
      setIsExpired(newDate < current ? true : false);
    }
  }, [expirationDate, userCourseInfo]);

  return (
    <Container>
      {userCourseInfo
        ? userCourseInfo.isCertified
          ? isExpired
            ? "Expired"
            : "Certified"
          : Number(userCourseInfo.progressing.toFixed(2)) + "%"
        : "Pending"}
    </Container>
  );
}

export default ShowCourseStatus;

const Container = styled.div``;
