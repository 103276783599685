import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Order from "../Components/Order";
import axios from "axios";

function Orders() {
  const [userOrder, setUserOrder] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [teamsName, setTeamsName] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedTeamName, setSelectedTeamName] = useState("");

  useEffect(() => {
    axios.post("/returnOrders", { userId: user.UserID }).then((data) => {
      setUserOrder(data.data);
      // console.log("User order:", data.data);

      // console.log(JSON.parse(data.data[0].metadata.itemsCheckOut));
    });
    axios.post("/returnTeams", { email: user.email }).then((data) => {
      setTeamsName(data.data);
    });
  }, [user]);

  // useEffect(() => {
  //   console.log("It is changed", usersList);
  // }, [Object.entries(usersList).length]);

  const onClickMoveUser = () => {
    // console.log("Move to is clicked");
    if (selectedTeamName !== "" && usersList.length != 0) {
      // console.log(selectedTeamName);
      axios.post("/updateUsersTeamName", {
        userIDs: usersList,
        teamName: selectedTeamName,
      });
    }
  };

  const onClickSelectOptions = (e) => {
    // console.log("This is an option:", e.target.value);
    setSelectedTeamName(e.target.value);
  };

  return (
    <Container>
      {/* <UserTeamTransfer>
        <MoveToButton onClick={() => onClickMoveUser()}>Move to</MoveToButton>
        <TeamList onChange={(e) => onClickSelectOptions(e)}>
          <SelectOptions value={""}>Please select...</SelectOptions>
          {teamsName ? (
            teamsName.map((item) => (
              <SelectOptions value={item.teamId}>{item.teamName}</SelectOptions>
            ))
          ) : (
            <div></div>
          )}
        </TeamList>
      </UserTeamTransfer> */}
      {userOrder ? (
        userOrder.map((item, key) => {
          return (
            <Order
              orderDetail={item}
              key={key}
              usersListProp={(val) => setUsersList(val)}
              list={usersList}
            />
          );
        })
      ) : (
        <div>Loading...</div>
      )}
    </Container>
  );
}

export default Orders;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;
const UserTeamTransfer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MoveToButton = styled.button`
  margin-right: 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: #5fb05f;
  padding: 7px;
  cursor: pointer;
`;
const TeamList = styled.select`
  padding: 5px;
  outline: none;
`;
const SelectOptions = styled.option``;
