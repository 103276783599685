import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAtAqTACpB0zGi_Z3n5MN33lOTnhmtmqfg",
  authDomain: "safet1st-95fb6.firebaseapp.com",
  projectId: "safet1st-95fb6",
  storageBucket: "safet1st-95fb6.appspot.com",
  messagingSenderId: "21943444789",
  appId: "1:21943444789:web:f4bfa9506b31813559987c",
  measurementId: "G-ZQ6T99WDJP",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = getAuth();

export default firebaseApp;
