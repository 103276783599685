import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function LecturePage(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const videoRef = useRef();
  const videoLastTime = useRef(0);
  const [courseId, setCourseId] = useState(props.courseId);
  const [lectureId, setLectureId] = useState(props.lectureId);
  const [tempOutput, setTempOutput] = useState("");
  const [tempNote, setTempNote] = useState("");
  const [lectureBasicData, setLectureBasicData] = useState(null);
  const [shouldCheckTime, setShouldCheckTime] = useState(true);
  const [lectureIsFinished, setLectureIsFinished] = useState(false);
  const [lectureVideoTime, setLectureVideoTime] = useState(-1);

  const [language, setLanguage] = useState("");
  const [updateKey, setUpdateKey] = useState(0);

  const handleChange = (event) => {
    let lang = event.target.value;
    setLanguage(lang);
    // console.log("Language:", event.target.value);

    if (courseId && lectureId && lang) {
      axios
        .post("/extractLectureCourseContentPerLanguage", {
          courseId: courseId,
          lectureId: lectureId,
          language: lang,
        })
        .then((data) => {
          // console.log("New Video Information:", data.data);
          setTempOutput(data.data);
        });
    }
  };

  useEffect(() => {
    return () => {
      // console.log("Last time:", videoLastTime.current);
      axios.post("/setLectureTime", {
        courseId: courseId,
        lectureId: lectureId,
        userEmail: user.email,
        time: videoLastTime.current,
      });
    };
  }, []);

  useEffect(() => {
    if (courseId && lectureId) {
      axios
        .post("/extractLectureCourseContent", {
          courseId: courseId,
          lectureId: lectureId,
        })
        .then((data) => {
          // console.log("Video Information:", data.data);
          setTempOutput(data.data);
        });

      axios
        .post("/extractLectureCourseNote", {
          courseId: courseId,
          lectureId: lectureId,
        })
        .then((data) => {
          setTempNote(data.data);
        });

      axios
        .post("/getUserLectureInfo", {
          courseId: courseId,
          lectureId: lectureId,
          userEmail: user.email,
        })
        .then((lectureData) => {
          // console.log("some lecture info:", lectureData.data);
          setLectureBasicData(lectureData.data);
        });
    }
  }, [courseId, lectureId]);

  useEffect(() => {
    if (lectureBasicData) {
      setShouldCheckTime(lectureBasicData.isFinished);
      setLectureVideoTime(lectureBasicData.time);
      // console.log("User Lecture data:", lectureBasicData);
    }
  }, [lectureBasicData]);

  useEffect(() => {
    // console.log("Course information:", tempOutput);
    setUpdateKey(updateKey + 1);
  }, [tempOutput]);

  useEffect(() => {
    if (lectureIsFinished) {
      axios
        .post("/setLectureIsFinished", {
          courseId: courseId,
          lectureId: lectureId,
          userEmail: user.email,
        })
        .then(() => {
          props.menuReload(new Date().getTime());
        });
    }
  }, [lectureIsFinished]);

  useEffect(() => {
    if (lectureVideoTime >= 0 && tempOutput.trim() != "") {
      // console.log("Reload the video");
      videoRef.current.currentTime = lectureVideoTime;
      // videoRef.current.load();
    }
  }, [lectureVideoTime, tempOutput]);

  const onVideoTimeUpdate = () => {
    let time = videoRef.current.currentTime;
    // console.log("This video is paused", videoRef.current.currentTime);
    // setVideoTime(videoRef.current.currentTime);
    videoLastTime.current = time;
    // console.log("Video duration:", videoRef.current.duration);
    if (!shouldCheckTime && time > (videoRef.current.duration * 90) / 100) {
      // console.log("Greater than 80%");
      setShouldCheckTime(true);
      setLectureIsFinished(true);
    }
  };

  // const onVideoLoad = () => {
  //   if (lectureVideoTime >= 0) {
  //     console.log("Reload after time changing");
  //     videoRef.current.currentTime = lectureVideoTime;
  //   }
  // };

  return (
    <Container>
      {tempOutput ? (
        <MainContainer>
          <VideoContainer>
            <Video
              controls
              ref={videoRef}
              onTimeUpdate={() => onVideoTimeUpdate()}
              controlsList="nodownload"
              key={updateKey}
              // onLoad={() => onVideoLoad()}
              // onPause={() => onVideoPause()}
            >
              <source src={tempOutput} type="video/mp4" />
            </Video>
          </VideoContainer>
          <LanguageSelectContainer>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  label="Language"
                  onChange={handleChange}
                >
                  <MenuItem value={"english"}>English</MenuItem>
                  <MenuItem value={"spanish"}>Español</MenuItem>
                  <MenuItem value={"arabic"}>عربی</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </LanguageSelectContainer>
          <NoteContainer>{tempNote}</NoteContainer>
        </MainContainer>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default LecturePage;

const Container = styled.div`
  width: 100%;
`;
const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Video = styled.video`
  /* max-width: 500px;
  min-width: 300px; */
  width: 100%;
  max-width: 700px;
`;

const MainContainer = styled.div``;
const LanguageSelectContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 50px;
`;

const NoteContainer = styled.div`
  padding: 15px 0 15px 0;
`;
