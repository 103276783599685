import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import CourseContent from "./CourseContent";
// import { useHistory } from "react-router-dom";
import MyCourseBasicInfo from "./MyCourseBasicInfo";

function MyCourses() {
  // const history = useHistory();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [myCourses, setMyCourses] = useState(null);
  useEffect(() => {
    if (user) {
      axios.post("/returnMyCourses", { userEmail: user.email }).then((data) => {
        setMyCourses(data.data);
      });
    }
  }, []);

  // const onClickShowCourseContent = (courseID) => {
  //   history.push("/coursecontent", { courseId: courseID });
  // };

  return (
    <Container>
      <CoursesContainer>
        {myCourses ? (
          myCourses.map((item, key) => (
            // <CourseContent courseInfo={item.courseId}></CourseContent>
            // <MyCourse
            //   onClick={() => onClickShowCourseContent(item.courseId)}
            //   key={key}
            // >
            //   {item.courseName}
            // </MyCourse>

            <MyCourseBasicInfo
              // onClick={() => onClickShowCourseContent(item.courseId)}
              key={key}
              courseID={item.courseId}
              progress={item.progressing}
            />
          ))
        ) : (
          <div></div>
        )}
      </CoursesContainer>
    </Container>
  );
}

export default MyCourses;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;

const CoursesContainer = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px; */
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: 2rem;
`;
