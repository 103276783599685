import React, { useState } from "react";
import styled from "styled-components";
import logo from "../Images/full-logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import topPattern from "../Images/pattern_top.png";
import backgroundImage from "../Images/Login_Background6.png";
import cloud1 from "../Images/cloud1.png";
import cloud2 from "../Images/cloud2.png";
import cloud3 from "../Images/cloud3.png";
import cloud4 from "../Images/cloud4.png";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Signup(props) {
  const history = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");

  const [eightToSixColor, setEightToSixColor] = useState("white");
  const [lowerCaseColor, setLowerCaseColor] = useState("white");
  const [upperCaseColor, setUpperCaseColor] = useState("white");
  const [digitColor, setDigitColor] = useState("white");
  const [specialCharsColor, setSpecialCharsColor] = useState("white");

  const [signUpIsLoading, setSignUpIsLoading] = useState(false);

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleConfirmPasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setConfirmPassword(event.target.value);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
    const eightToSix =
      /^[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    const lowerCase =
      /(?=(.*[a-z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const upperCase =
      /(?=(.*[A-Z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const digit =
      /(?=(.*[0-9]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const specialChar =
      /(?=(.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]){1})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;

    // if (
    //   !isEmpty(event.target.value) &&
    //   (!event.target.value.match(eightToSix) ||
    //     !event.target.value.match(lowerCase) ||
    //     !event.target.value.match(upperCase) ||
    //     !event.target.value.match(digit) ||
    //     !event.target.value.match(specialChar))
    // ) {
    if (event.target.value.match(eightToSix)) {
      setEightToSixColor("green");
    } else {
      setEightToSixColor("white");
      // setPasswordError("Password should contain at least 8 to 16 characters");
    }

    if (event.target.value.match(lowerCase)) {
      setLowerCaseColor("green");
    } else {
      setLowerCaseColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 lowercase characters"
      // );
    }

    if (event.target.value.match(upperCase)) {
      setUpperCaseColor("green");
    } else {
      setUpperCaseColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 uppercase characters"
      // );
    }

    if (event.target.value.match(digit)) {
      setDigitColor("green");
    } else {
      setDigitColor("white");
      // setPasswordError("Password should contain at least 2 digits");
    }

    if (event.target.value.match(specialChar)) {
      setSpecialCharsColor("green");
    } else {
      setSpecialCharsColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 special characters"
      // );
    }
    // }
    // else {
    //   setPasswordError("");
    // }
  };

  const handleClickShowPassword = (event) => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const onCopyPassword = (event) => {
    event.clipboardData.setData("text/plain", "");
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keepUserName = (event) => {
    setUserName(event.target.value);
  };

  // const keepPassword = (event) => {
  //   setPassword(event.target.value);
  //   const eightToSix =
  //     /^[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
  //   const lowerCase =
  //     /(?=(.*[a-z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
  //   const upperCase =
  //     /(?=(.*[A-Z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
  //   const digit =
  //     /(?=(.*[0-9]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
  //   const specialChar =
  //     /(?=(.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;

  //   if (event.target.value.match(eightToSix)) {
  //     setEightToSixColor("green");
  //   } else {
  //     setEightToSixColor("red");
  //   }

  //   if (event.target.value.match(lowerCase)) {
  //     setLowerCaseColor("green");
  //   } else {
  //     setLowerCaseColor("red");
  //   }

  //   if (event.target.value.match(upperCase)) {
  //     setUpperCaseColor("green");
  //   } else {
  //     setUpperCaseColor("red");
  //   }

  //   if (event.target.value.match(digit)) {
  //     setDigitColor("green");
  //   } else {
  //     setDigitColor("red");
  //   }

  //   if (event.target.value.match(specialChar)) {
  //     setSpecialCharsColor("green");
  //   } else {
  //     setSpecialCharsColor("red");
  //   }
  // };
  const onClickCompanyLogo = () => {
    history("/");
  };

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isPassword = (password) => {
    // const re =
    //   /^(?=.*[0-9])(?=(.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]){2})(?=(.*[A-Z]){2})(?=(.*[a-z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    const re =
      /^(?=.*[0-9])(?=(.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]))(?=(.*[A-Z]){2})(?=(.*[a-z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    if (password.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onClickSignUp = () => {
    // console.log("username:", userName);
    // console.log("Password", password);
    if (
      !isEmpty(fullName) &&
      !isEmpty(userName) &&
      !isEmpty(password) &&
      isEmail(userName) &&
      eightToSixColor === "green" &&
      digitColor === "green" &&
      lowerCaseColor === "green" &&
      upperCaseColor === "green" &&
      specialCharsColor === "green" &&
      password == confirmPassword
    ) {
      setSignUpIsLoading(true);
      setPasswordError("");
      setUserNameError("");
      setConfirmPasswordError("");
      setFullNameError("");
      axios
        .post(`/signup`, {
          email: userName,
          password: password,
          fullName: fullName,
        })
        .then((data) => {
          setSignUpIsLoading(false);
          history("/login", {
            state: { email: userName, message: data.data.message },
          });
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with a status other than 200 range
            if (error.response.status === 400) {
              setUserNameError(error.response.data.message);
              // setError400('Bad Request: Please check your input and try again.');
            } else if (error.response.status === 500) {
              setConfirmPasswordError(error.response.data.message);
              // setError500('Internal Server Error: Please try again later.');
            }
          } else {
            setConfirmPasswordError(error.response.data.message);
            // Other errors (network issues, timeout, etc.)
            // console.error('Error fetching data:', error);
          }
          setSignUpIsLoading(false);
        });
    } else {
      if (isEmpty(confirmPassword)) {
        setConfirmPasswordError("Please fill out the confirm password field.");
      } else if (password !== confirmPassword) {
        setConfirmPasswordError("Password does not match.");
        //sjkljkj
      } else {
        setConfirmPasswordError("");
      }

      if (isEmpty(password)) {
        setPasswordError("Please fill out the password field.");
      } else if (
        eightToSixColor !== "green" ||
        digitColor !== "green" ||
        lowerCaseColor !== "green" ||
        upperCaseColor !== "green" ||
        specialCharsColor !== "green"
      ) {
        setPasswordError("Please follow the password rules.");
      } else {
        setPasswordError("");
      }

      if (isEmpty(userName)) {
        setUserNameError("Please fill out the username field.");
      } else if (!isEmail(userName)) {
        setUserNameError("Please enter valid email.");
      } else {
        setUserNameError("");
      }

      if (isEmpty(fullName)) {
        setFullNameError("Please fill out the fullname field.");
      } else {
        setFullNameError("");
      }
    }
  };
  //   if (
  //     // emailVerified &&
  //     isEmpty(passwordError) &&
  //     isEmpty(emailError) &&
  //     isEmpty(overalError)
  //   ) {
  //     localStorage.setItem("user", JSON.stringify(data.data.output));
  //     props.passUser(data.data.output);
  //     // history.push("/");
  //     history.goBack();
  //   } else if (!emailVerified) {
  //   }
  // });
  // }
  // else {
  //   if (!userName) {
  //     setUserNameError("Must not be empty!");
  //   } else if (!isEmail(userName)) {
  //     setUserNameError("Email is not valid!");
  //   } else {
  //     setUserNameError("");
  //   }

  //   if (!password) {
  //     setPasswordError("Must not be empty!");
  //   } else if (!isPassword(password)) {
  //     setPasswordError("Password is not secure!");
  //   } else {
  //     setPasswordError("");
  //   }
  // }
  // };
  // const signIn = () => {
  //   auth
  //     .signInWithPopup(provider)
  //     .then((dat) => {
  //       const newUser = {
  //         name: dat.user.displayName,
  //         photo: dat.user.photoURL,
  //       };
  //       localStorage.setItem("user", JSON.stringify(newUser));
  //       props.passUser(newUser);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };
  const onClickLoginLink = () => {
    history("/login");
  };
  const longText = `
  - 8-16 characters
  - At least 2 lower case characters
  - At least 2 upper case characters
  - At least 2 digits
  - At least 1 special char, can be any of !@#$%^&*+=-?
  `;

  return (
    <Container>
      <HeaderContainer />
      <SignUpFormContainer>
        <SignupForm>
          <Cloud1Container>
            <Cloud1 src={cloud1} />
          </Cloud1Container>
          <Cloud2Container>
            <Cloud2 src={cloud2} />
          </Cloud2Container>
          <Cloud3Container>
            <Cloud3 src={cloud3} />
          </Cloud3Container>
          <MainContent>
            <LogoContainer>
              <SlackImage src={logo} onClick={() => onClickCompanyLogo()} />{" "}
            </LogoContainer>
            <Cloud4Container>
              <Cloud4 src={cloud4} />
            </Cloud4Container>

            {/* <LoginForm>Sign In</LoginForm> */}
            <FullNameContainer>
              {/* <FullName
            placeholder="Full Name"
            onChange={(e) => setFullName(e.target.value)}
          /> */}
              <TextField
                id="outlined-basic"
                label="Fullname"
                variant="outlined"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
              <FullNameError>
                {fullNameError !== "" ? `*${fullNameError}` : ""}
              </FullNameError>
            </FullNameContainer>
            <UserNameContainer>
              {/* <UserName placeholder="Email" onChange={(e) => keepUserName(e)} /> */}
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(e) => keepUserName(e)}
                value={userName}
              />
              <UserNameError>
                {userNameError !== "" ? `*${userNameError}` : ""}
              </UserNameError>
            </UserNameContainer>
            <PasswordContainer>
              {/* <Password
            type="password"
            placeholder="Password"
            onChange={(e) => keepPassword(e)}
          /> */}

              {/* <Tooltip
                title={longText}
                className="password-tooltip"
                data-html="true"
                placement="bottom-start"
              > */}
              {/* <Button sx={{ m: 1 }}>Default Width [300px]</Button> */}

              <FormControl sx={{ m: 1 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  onCopy={onCopyPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* </Tooltip> */}
              <PasswordError>
                {passwordError !== "" ? `*${passwordError}` : ""}
              </PasswordError>

              <ErrorsContainer>
                <PasswordLength style={{ color: `${eightToSixColor}` }}>
                  -8-16 characters
                </PasswordLength>
                <PasswordLoweCase style={{ color: `${lowerCaseColor}` }}>
                  -At least 2 lower case characters
                </PasswordLoweCase>
                <PasswordUpperCase style={{ color: `${upperCaseColor}` }}>
                  -At least 2 upper case characters
                </PasswordUpperCase>
                <PasswordDigits style={{ color: `${digitColor}` }}>
                  -At least 2 digits
                </PasswordDigits>

                <PasswordSpecialChars style={{ color: `${specialCharsColor}` }}>
                  {/* {`*At least 2 special chars, can be any of !@#$%^&*()+=-?;,./{}|":<>[]\' ~_`} */}
                  {/* -At least 2 special characters */}
                  -At least 1 special char, can be any of !@#$%^&*+=-?
                </PasswordSpecialChars>
              </ErrorsContainer>
            </PasswordContainer>
            <ConfirmPasswordContainer>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className="confirm-password-field">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  type={values.showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={handleConfirmPasswordChange("confirmPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="ConfirmPassword"
                />
              </FormControl>
              <ConfirmPasswordError>
                {confirmPasswordError !== "" ? `*${confirmPasswordError}` : ""}
              </ConfirmPasswordError>
              {/* <ConfirmPassword
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          /> */}
            </ConfirmPasswordContainer>
            {!signUpIsLoading ? (
              <LoginButton onClick={() => onClickSignUp()}>Signup</LoginButton>
            ) : (
              <LoadingLoginButton>
                Signup
                <Box sx={{ position: "absolute", lineHeight: "10px" }}>
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[
                          theme.palette.mode === "light" ? 200 : 800
                        ],
                    }}
                    size={20}
                    thickness={4}
                    {...props}
                    value={100}
                  />
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "light" ? "#254336" : "#308fe8",
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                    size={20}
                    thickness={4}
                    {...props}
                  />
                </Box>
              </LoadingLoginButton>
            )}

            <LoginLinkContainer>
              <LoginLink onClick={() => onClickLoginLink()}>Login</LoginLink>
            </LoginLinkContainer>
          </MainContent>
        </SignupForm>
      </SignUpFormContainer>
    </Container>
  );
}

export default Signup;

const Container = styled.div`
  /* margin: 0px 10px 10px 10px; */
  height: 100vh;
  width: 100%;
  /* background: #f9f0ec; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-rows: 50px auto;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  background: url(${topPattern}) repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  opacity: 0.3;
`;

const MainContent = styled.div`
  /* border-left: 2px dashed #9c9fb0;
  border-right: 2px dashed #9c9fb0;
  border-bottom: 2px dashed #9c9fb0; */
  /* padding: 10px; */
  /* position: relative;
  width: 50%;
  max-width: 700px;
  background: white;
  padding: 70px 90px 50px 90px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  z-index: 1;
  width: 100%;
  max-width: 500px;
  background: white;
  padding: 70px 90px 50px 90px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(244, 238, 238, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const SignupForm = styled.div`
  min-width: 370px;
  width: 70%;
  max-width: 500px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;

  background: url(${backgroundImage}) no-repeat bottom center;
  background-color: white;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
`;

const SignUpFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 900;
`;

const SlackImage = styled.img`
  height: 70px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LoginButton = styled.button`
  width: 100%;
  margin-top: 0px;
  /* background-color: #5fb05f; */
  background-color: rgba(17, 34, 17, 0.8);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(17, 34, 17, 1);
  }
`;

const LoadingLoginButton = styled.button`
  width: 100%;
  margin-top: 0px;
  /* background-color: #5fb05f; */
  background-color: #adb5bd;
  color: #ced4da;
  display: flex;

  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: default;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
`;

const UserName = styled.input`
  padding: 11.5px;
  /* margin-top: 15px; */
  width: 100%;
`;
const Password = styled.input`
  padding: 11.5px;
  width: 100%;
`;

const UserNameError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
  /* left: 0; */
`;
const PasswordError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
`;

const UserNameContainer = styled.div`
  width: 100%;
`;

const ErrorsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;

  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 10px 5px 10px;
  /* position: absolute; */
  top: 60px;
  border-radius: 4px;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  z-index: 10000000000000000000000000000000000000000000000000;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(0, 0, 0, 0.8);
  /* background: rgba(244, 238, 238, 0.25); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */

  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
  transform: scale(0.98) translateX(0px) translateY(0px);
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover ${ErrorsContainer} {
    background: rgba(0, 0, 0, 0.8);
    /* background: rgba(244, 238, 238, 0.25); */
    /* backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); */
    transition-delay: 0s;
    border-radius: 5px;
    /* border: 1px solid #bfbfbf; */
    border: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1) translateX(0px) translateY(0px);
  }
`;

const ConfirmPasswordContainer = styled.div`
  /* margin-top: 10px; */
  margin-bottom: 10px;
  width: 100%;
`;
const ConfirmPassword = styled.input`
  padding: 11.5px;
  width: 100%;
`;
const ConfirmPasswordError = styled.p`
  height: 20px;
  font-size: 11px;
  color: red;
`;

const FullNameContainer = styled.div`
  width: 100%;
`;
const FullName = styled.input`
  padding: 11.5px;
  width: 100%;
`;
const FullNameError = styled.p`
  height: 20px;
  font-size: 11px;
  color: red;
`;
const PasswordLength = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;
const PasswordDigits = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;
const PasswordLoweCase = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;
const PasswordUpperCase = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;
const PasswordSpecialChars = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginLinkContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  color: rgb(17, 34, 17);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
`;

const LoginLink = styled.div`
  width: fit-content;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(17, 34, 17);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

const Cloud1Container = styled.div`
  position: absolute;
  top: 10px;
  right: -110px;
`;
const Cloud2Container = styled.div`
  position: absolute;
  top: 190px;
  right: -80px;
`;
const Cloud3Container = styled.div`
  position: absolute;
  top: 190px;
  left: -130px;
`;
const Cloud4Container = styled.div`
  position: absolute;
  top: -6px;
  left: -160px;
`;

const Cloud3 = styled.img`
  width: 240px;
`;
const Cloud1 = styled.img`
  width: 290px;
`;
const Cloud2 = styled.img`
  width: 200px;
`;
const Cloud4 = styled.img`
  width: 300px;
`;

// ==========================================================================
// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import logo from "../Images/full-logo.png";
// import backgroundImage from "../Images/Login_Background6.png";
// import topPattern from "../Images/pattern_top.png";
// import cloud1 from "../Images/cloud1.png";
// import cloud2 from "../Images/cloud2.png";
// import cloud3 from "../Images/cloud3.png";
// import cloud4 from "../Images/cloud4.png";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import TextField from "@mui/material/TextField";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import InputLabel from "@mui/material/InputLabel";

// const Container = styled.div`
//   /* margin: 0px 10px 10px 10px; */
//   height: 100vh;
//   width: 100%;
//   /* background: #f9f0ec; */

//   /* background-repeat: repeat; */
//   /* position: relative;
//   background: url(${backgroundImage}) no-repeat center center fixed;
//   -webkit-background-size: cover;
//   -moz-background-size: cover;
//   -o-background-size: cover;
//   background-size: cover; */

//   display: grid;
//   grid-template-rows: 50px auto;
//   /* &:before {
//     content: " ";
//     display: block;
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     background: url(${backgroundImage}) no-repeat center center fixed;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;*/
//   /* opacity: 0.5; */
//   /* }  */
// `;

// const ContainerHeader = styled.div`
//   background: url(${topPattern}) repeat center center;
//   -webkit-background-size: contain;
//   -moz-background-size: contain;
//   -o-background-size: contain;
//   background-size: contain;
//   opacity: 0.3;
// `;
// const LogoContainer = styled.div`
//   position: absolute;
//   top: -40px;
//   z-index: 2;
//   width: 100%;
//   /* height: 100%; */
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const MainContent = styled.div`
//   /* border-left: 2px dashed #9c9fb0;
//   border-right: 2px dashed #9c9fb0;
//   border-bottom: 2px dashed #9c9fb0; */
//   /* padding: 10px; */
//   z-index: 1;
//   background: white;
//   padding: 100px;
//   border-radius: 5px;
//   box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;

//   /* position: absolute;
//   right: 10%;
//   top: 10%; */

//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   /* backdrop-filter: blur(10px); */
//   /* z-index: 1; */
//   background: rgba(244, 238, 238, 0.25);
//   /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
//   backdrop-filter: blur(4px);
//   -webkit-backdrop-filter: blur(4px);
// `;

// const LoginForm = styled.div`
//   /* padding: 10px; */
//   border-radius: 5px;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 20px;
//   font-weight: 900;

//   position: relative;
//   /* background: url(${backgroundImage}) no-repeat center center fixed; */
//   background: url(${backgroundImage}) no-repeat bottom center;
//   background-color: white;
//   -webkit-background-size: contain;
//   -moz-background-size: contain;
//   -o-background-size: contain;
//   background-size: contain;

//   /* background: url(${backgroundImage}) no-repeat;
//   background-size: cover; */
//   /* background: url(${backgroundImage}) no-repeat right center fixed;
//   -webkit-background-size: contain;
//   -moz-background-size: contain;
//   -o-background-size: contain;
//   background-size: contain; */
// `;

// const BackgroundImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// const SlackImage = styled.img`
//   height: 70px;
//   cursor: pointer;

//   /* margin-bottom: 30px; */
// `;

// const LoginButton = styled.button`
//   width: 100%;
//   margin-top: 0px;
//   /* background-color: #5fb05f; */
//   background-color: rgba(17, 34, 17, 0.8);
//   color: white;
//   border: none;
//   border-radius: 4px;
//   padding: 10px;
//   cursor: pointer;
//   font-weight: 600;
//   font-size: 1rem;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background-color: rgba(17, 34, 17, 1);
//   }
// `;

// const LoginButtonContainer = styled.div`
//   width: 100%;
// `;

// const UserName = styled.input`
//   padding: 11.5px;
//   margin-top: 15px;
// `;
// const Password = styled.input`
//   padding: 11.5px;
// `;

// const UserNameError = styled.p`
//   /* padding: 10px; */
//   height: 20px;
//   font-size: 11px;
//   color: red;
//   /* left: 0; */
// `;
// const PasswordError = styled.p`
//   /* padding: 10px; */
//   height: 20px;
//   font-size: 11px;
//   color: red;
// `;

// const UserNameContainer = styled.div``;
// const PasswordContainer = styled.div``;

// function Login(props) {
//   const history = useNavigate();
//   const location = useLocation();
//   const [userName, setUserName] = useState("");
//   const [password, setPassword] = useState("");
//   const [userNameError, setUserNameError] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const [isEmailVerified, setIsEmailVerified] = useState(false);

//   const [values, setValues] = useState({
//     amount: "",
//     password: "",
//     weight: "",
//     weightRange: "",
//     showPassword: false,
//   });

//   useEffect(() => {
//     if (location.state?.email) {
//       setUserName(location.state.email);
//     }
//   }, []);

//   const onClickCompanyLogo = () => {
//     history("/");
//   };

//   const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
//     setPassword(event.target.value);
//   };

//   const handleClickShowPassword = () => {
//     setValues({
//       ...values,
//       showPassword: !values.showPassword,
//     });
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const keepUserName = (event) => {
//     setUserName(event.target.value);
//     console.log(event.target.value);
//   };

//   const onClickForgotPassword = () => {
//     history("/forgotpasswort");
//   };

//   // const keepPassword = (event) => {
//   //   setPassword(event.target.value);
//   // };

//   const onClickCreateAnAccount = () => {
//     history("/signup");
//   };

//   useEffect(() => {
//     if (!isEmpty(userName)) {
//       setUserNameError("");
//     }
//   }, [userName]);

//   useEffect(() => {
//     if (!isEmpty(password)) {
//       setPasswordError("");
//     }
//   }, [password]);

//   const isEmail = (email) => {
//     const re =
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     if (email.match(re)) return true;
//     else return false;
//   };

//   const isPassword = (password) => {
//     const re =
//       /^(?=.*[0-9])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_])(?=.*[A-Z]{2})(?=.*[a-z]{2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
//     if (password.match(re)) return true;
//     else return false;
//   };

//   const isEmpty = (string) => {
//     if (string.trim() == "") return true;
//     else return false;
//   };

//   const onClickLogin = () => {
//     // console.log("username:", userName);
//     // console.log("Password", password);
//     if (
//       !isEmpty(userName) &&
//       !isEmpty(password)
//       // isPassword(password) &&
//       // isEmail(userName)
//     ) {
//       setPasswordError("");
//       setUserNameError("");
//       axios
//         .post(`/login`, { email: userName, password: password })
//         .then((data) => {
//           // console.log("login output:", data.data.output);
//           const emailVerified = data.data.output.emailVerified;
//           // const passwordError = data.data.output.passworderror;
//           // const emailError = data.data.output.emailerror;
//           const overalError = data.data.output.error;
//           if (emailVerified) {
//             if (userName.trim() == "" || password.trim() == "" || overalError) {
//               // console.log("Something is going wrong!");
//               if (password.trim() == "") {
//                 setPasswordError("Please enter the password.");
//               } else if (overalError == "auth/wrong-password") {
//                 setPasswordError("Wrong Password!");
//               }

//               if (userName.trim() == "") {
//                 setUserNameError("Please enter the email.");
//               } else if (overalError == "auth/invalid-email") {
//                 setUserNameError("Invalid Email!");
//               }
//             } else {
//               localStorage.setItem("user", JSON.stringify(data.data.output));
//               // console.log("user:", data.data.output);
//               props.passUser(data.data.output);
//               history("/", { user: data.data.output });
//             }
//           } else {
//             setUserNameError("Email is not verified.");
//           }
//         });
//       // .then(() => {
//       //   history("/");
//       // });
//     } else {
//       if (isEmpty(password)) {
//         setPasswordError("Please enter the password.");
//       }
//       if (isEmpty(userName)) {
//         setUserNameError("Please enter the email.");
//       }
//     }
//   };
//   //   if (
//   //     // emailVerified &&
//   //     isEmpty(passwordError) &&
//   //     isEmpty(emailError) &&
//   //     isEmpty(overalError)
//   //   ) {
//   //     localStorage.setItem("user", JSON.stringify(data.data.output));
//   //     props.passUser(data.data.output);
//   //     // history.push("/");
//   //     history.goBack();
//   //   } else if (!emailVerified) {
//   //   }
//   // });
//   // }
//   // else {
//   //   if (!userName) {
//   //     setUserNameError("Must not be empty!");
//   //   } else if (!isEmail(userName)) {
//   //     setUserNameError("Email is not valid!");
//   //   } else {
//   //     setUserNameError("");
//   //   }

//   //   if (!password) {
//   //     setPasswordError("Must not be empty!");
//   //   } else if (!isPassword(password)) {
//   //     setPasswordError("Password is not secure!");
//   //   } else {
//   //     setPasswordError("");
//   //   }
//   // }
//   // };
//   // const signIn = () => {
//   //   auth
//   //     .signInWithPopup(provider)
//   //     .then((dat) => {
//   //       const newUser = {
//   //         name: dat.user.displayName,
//   //         photo: dat.user.photoURL,
//   //       };
//   //       localStorage.setItem("user", JSON.stringify(newUser));
//   //       props.passUser(newUser);
//   //     })
//   //     .catch((err) => {
//   //       console.error(err);
//   //     });
//   // };

//   return (
//     <Container>
//       <ContainerHeader />

//       <LoginFormContainer>
//         <LoginForm>
//           <LogoContainer>
//             <SlackImage src={logo} onClick={() => onClickCompanyLogo()} />
//           </LogoContainer>
//           <Cloud1Container>
//             <Cloud1 src={cloud1} />
//           </Cloud1Container>
//           <Cloud2Container>
//             <Cloud2 src={cloud2} />
//           </Cloud2Container>
//           <Cloud3Container>
//             <Cloud3 src={cloud3} />
//           </Cloud3Container>

//           {/* <BackgroundImage src={backgroundImage} /> */}
//           <MainContent>
//             {/* <LoginForm>Sign In</LoginForm> */}
//             <Cloud4Container>
//               <Cloud4 src={cloud4} />
//             </Cloud4Container>
//             <UserNameContainer>
//               {/* <UserName
//                 placeholder="Email"
//                 onChange={(e) => keepUserName(e)}
//                 value={userName}
//               /> */}
//               <TextField
//                 id="outlined-basic"
//                 label="Username"
//                 variant="outlined"
//                 onChange={(e) => keepUserName(e)}
//                 value={userName}
//               />
//               <UserNameError>
//                 {userNameError !== "" ? `*${userNameError}` : ""}
//               </UserNameError>
//             </UserNameContainer>
//             <PasswordContainer>
//               {/* <Password
//                 type="password"
//                 placeholder="Password"
//                 onChange={(e) => keepPassword(e)}
//               /> */}

//               <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
//                 <InputLabel htmlFor="outlined-adornment-password">
//                   Password
//                 </InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-password"
//                   type={values.showPassword ? "text" : "password"}
//                   value={values.password}
//                   onChange={handleChange("password")}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         onMouseDown={handleMouseDownPassword}
//                         edge="end"
//                       >
//                         {values.showPassword ? (
//                           <VisibilityOff />
//                         ) : (
//                           <Visibility />
//                         )}
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Password"
//                 />
//               </FormControl>

//               {/* <TextField
//                 id="outlined-basic"
//                 label="Password"
//                 variant="outlined"
//                 onChange={(e) => keepPassword(e)}
//                 value={userName}
//               /> */}
//               <PasswordError>
//                 {passwordError !== "" ? `*${passwordError}` : ""}
//               </PasswordError>
//             </PasswordContainer>
//             <LoginButtonContainer>
//               <LoginButton onClick={() => onClickLogin()}>Login</LoginButton>
//             </LoginButtonContainer>

//             <SignUpButtonContainer>
//               <SignUpButton onClick={() => onClickCreateAnAccount()}>
//                 Create an account
//               </SignUpButton>
//             </SignUpButtonContainer>
//             <ForgetUsernameContainer>
//               <ForgetPasswordLink onClick={() => onClickForgotPassword()}>
//                 Forgot password?
//               </ForgetPasswordLink>
//             </ForgetUsernameContainer>
//           </MainContent>
//         </LoginForm>
//       </LoginFormContainer>
//     </Container>
//   );
// }

// export default Login;

// const SignUpButtonContainer = styled.div`
//   margin-top: 10px;
//   width: 100%;
// `;
// const SignUpButton = styled.button`
//   border-color: rgba(17, 34, 17);
//   border-radius: 4px;
//   background-color: transparent;
//   padding: 10px;
//   width: 100%;
//   font-weight: 600;
//   font-size: 1rem;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     border-color: #003300;
//     color: #003300;
//   }
// `;

// const ForgetUsernameContainer = styled.div`
//   width: 100%;
//   margin-top: 5px;
//   color: rgb(17, 34, 17);
//   cursor: pointer;
//   font-size: 12px;
// `;

// const Cloud1Container = styled.div`
//   position: absolute;
//   top: 10px;
//   right: -110px;
// `;
// const Cloud2Container = styled.div`
//   position: absolute;
//   top: 190px;
//   right: -80px;
// `;
// const Cloud3Container = styled.div`
//   position: absolute;
//   top: 190px;
//   left: -130px;
// `;
// const Cloud4Container = styled.div`
//   position: absolute;
//   top: -6px;
//   left: -160px;
// `;

// const Cloud3 = styled.img`
//   width: 240px;
// `;
// const Cloud1 = styled.img`
//   width: 290px;
// `;
// const Cloud2 = styled.img`
//   width: 200px;
// `;
// const Cloud4 = styled.img`
//   width: 300px;
// `;

// const LoginFormContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
// `;

// const ForgetPasswordLink = styled.div`
//   width: fit-content;
//   display: flex;
//   justify-content: left;
//   align-items: center;
//   position: relative;

//   &::after {
//     content: "";
//     position: absolute;
//     width: 100%;
//     top: 15px;
//     transform: scaleX(0);
//     height: 2px;
//     bottom: 0;
//     left: 0;
//     background-color: rgb(17, 34, 17);
//     transform-origin: bottom right;
//     transition: transform 0.25s ease-out;
//   }

//   &:hover {
//     &::after {
//       transform: scaleX(1);
//       transform-origin: bottom left;
//     }
//   }
// `;
