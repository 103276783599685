import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import visa from "../Images/Visa_Payment.png";
import amex from "../Images/Amex_Payment.png";
import master from "../Images/Master_Payment.png";
import jcb from "../Images/JCB_Payment.png";
import discover from "../Images/discover_Payment.png";
import bluePay from "../Images/blue_round_Payment.png";
import unionPay from "../Images/union_Pay_Payment.png";
import cvc from "../Images/bacvc_payment.png";
import styled from "styled-components";

// import "./Checkout.css";
const stripePromiseTestMode = loadStripe(
  "pk_test_51HC8ruLYpiPz9FF416QMwOiY1LJDy6oF3JqZ5YuATtIreOoh6ppEnQk5FQlhFzcpWumBZTefBvznFz595REDm48g00uktcs3u7"
);

const stripePromiseLiveMode = loadStripe(
  "pk_live_51HC8ruLYpiPz9FF4gF6Suk6enWcV7kocqHJdU1jMWm1sck1SlJCrsUftYPDDwWoZ8L6Txo9olw0l6Yv5NAZs2eDJ00KJiF3o00"
);

const stripePK = stripePromiseLiveMode;

function CheckOut() {
  const navigate = useNavigate();
  const location = useLocation();
  const { buyerId, buyerEmail, teamId, items, total, teamName } =
    location.state || {
      buyerId: "",
      buyerEmail: "",
      teamId: "",
      items: [],
    };

  const [cardholderName, setCardholderName] = useState("");
  const [country, setCountry] = useState("US");
  const [zipcode, setZipCode] = useState("");

  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [cardBrand, setCardBrand] = useState("");

  const onChangeCardNumber = (event) => {
    setCardBrand(event.brand);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name: cardholderName,
      },
    });

    if (error) {
      console.log("Something wrong with creating payment method");
      return;
    }

    try {
      const response = await axios.post("/create-payment-intents", {
        buyerId: buyerId,
        items: items,
        buyerEmail: buyerEmail,
        teamId: teamId,
        cardholderName: cardholderName,
        paymentMethodId: paymentMethod.id,
        // paymentMethodId: paymentMethod.id,
        // customer: customer.id,
      });

      // const response = await fetch('/create-payment-intent', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({
      //     amount: 1000, // Amount in cents
      //     currency: 'usd',
      //     paymentMethodId: paymentMethod.id,
      //   }),
      // });

      const { clientSecret, status } = response.data;

      console.log("Status:", status);

      if (status === "succeeded") {
        setSuccess(true);
        setProcessing(false);
        localStorage.removeItem("teamCart");
        navigate("/team", {
          state: {
            teamId: teamId,
            forceUpdate: new Date(),
            teamName: teamName,
          },
        });
      } else {
        console.log("Status failed:", status);
      }
    } catch (error) {
      setError("Failed to process payment.", error);
      setProcessing(false);
    }
  };

  return (
    <div className="team-checkout-form-container">
      <div className="left-section">
        <h4 style={{ color: "#959a9b" }}>Pay SafeT1st</h4>
        <div className="team-checkout-total-payment-title">${total}</div>
        <h3>List of items:</h3>
        <Separator />
        <div className="team-checkout-items-list-container">
          {items.map((item) =>
            item.usersNotTookCourse?.length &&
            item.usersNotTookCourse?.length > 0 ? (
              <ItemListContainer>
                <CourseNameContainer>{item.courseName}</CourseNameContainer>
                <TeamCartQuantityPriceContainer>
                  <div>Quantity: {item.usersNotTookCourse?.length}</div>
                  <div>Price: ${item.coursePrice}</div>
                </TeamCartQuantityPriceContainer>
              </ItemListContainer>
            ) : (
              <div></div>
            )
          )}
        </div>
        <Separator />
        <h3>Total Due: ${total}</h3>
      </div>
      <div className="right-section">
        <form onSubmit={handleSubmit}>
          <h2>Pay with card</h2>
          <div className="team-checkout-buyerEmail-container">
            <div>Email</div>
            <div>{buyerEmail}</div>
          </div>

          <div>Card Information</div>
          <div className="team-checkout-card-information-container">
            <div className="team-checkout-card-number-container">
              <div className="team-checkout-card-number-icons-container">
                <div>
                  <CardNumberElement
                    onChange={onChangeCardNumber}
                    options={{
                      style: {
                        base: {
                          fontSize: "14px",
                          color: "#32325d",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div className="card-icons">
                  {/* Display the icons, highlight the detected brand */}
                  <img
                    src={visa}
                    className={cardBrand === "visa" ? "active" : ""}
                    alt="Visa"
                  />
                  <img
                    src={master}
                    className={cardBrand === "mastercard" ? "active" : ""}
                    alt="MasterCard"
                  />
                  <img
                    src={amex}
                    className={cardBrand === "amex" ? "active" : ""}
                    alt="American Express"
                  />
                  <img
                    src={discover}
                    className={cardBrand === "discover" ? "active" : ""}
                    alt="Discover"
                  />
                  <img
                    src={jcb}
                    className={cardBrand === "jcb" ? "active" : ""}
                    alt="JBC"
                  />
                  <img
                    src={unionPay}
                    className={cardBrand === "unionpay" ? "active" : ""}
                    alt="UnionPay"
                  />
                  <img
                    src={bluePay}
                    className={cardBrand === "diners" ? "active" : ""}
                    alt="Diners Club"
                  />
                  {/* Add other icons as needed */}
                </div>
              </div>
            </div>
            <div className="team-checkout-exp-cvc-container">
              <div className="team-checkout-exp-container">
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "14px",
                        color: "#32325d",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                    },
                  }}
                />
              </div>

              <div className="team-checkout-cvc-container">
                <div>
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          fontSize: "14px",
                          color: "#32325d",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div className="team-checkout-cvc-icon-container">
                  <img src={cvc} />
                </div>
              </div>
            </div>
          </div>
          <div className="team-checkout-card-holder-name-container">
            <div>Cardholder Name</div>

            <input
              type="text"
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
              placeholder="Full name on card"
              required
            />
          </div>

          <div>
            <div>Country or Region</div>
            <div className="team-checkout-country-zip-container">
              <div className="team-checkout-country-container">
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required>
                  <option value="US">United States</option>
                  <option value="CA">Canada</option>
                  {/* Add other countries as needed */}
                </select>
              </div>
              <div>
                <input
                  type="text"
                  value={zipcode}
                  onChange={(e) => setZipCode(e.target.value)}
                  placeholder="ZIP"
                  required
                />
              </div>
            </div>
          </div>

          <button
            className="team-checkout-pay-button"
            disabled={processing || !stripe}
            style={{ marginTop: "20px" }}>
            {processing ? "Processing..." : "Pay"}
          </button>

          <div className="stripe-footer">
            <p>
              Powered by{" "}
              <a
                style={{ fontWeight: 600 }}
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer">
                Stripe
              </a>{" "}
              |{" "}
              <a
                href="https://stripe.com/terms"
                target="_blank"
                rel="noopener noreferrer">
                Terms
              </a>{" "}
              <a
                href="https://stripe.com/privacy"
                target="_blank"
                rel="noopener noreferrer">
                Privacy
              </a>
            </p>
          </div>

          {error && (
            <div style={{ color: "red", marginTop: "20px" }}>{error}</div>
          )}
          {success && (
            <div style={{ color: "green", marginTop: "20px" }}>
              Payment Successful!
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

const TeamCartQuantityPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #d9d9d9;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CourseNameContainer = styled.div`
  font-weight: 600;
`;

const ItemListContainer = styled.div`
  font-size: 14px;
`;

// export default CheckOut
const WrappedCheckout = () => (
  <Elements stripe={stripePK}>
    <CheckOut />
  </Elements>
);

export default WrappedCheckout;
