import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

function ShowCourseName(props) {
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    // console.log("Course ID:", props.courseId);
    axios
      .post("/returnCourseName", { courseId: props.courseId })
      .then((data) => {
        setCourseName(data.data);
        let propsCourseName = props.courseNameArrayValue;
        propsCourseName[props.index] = data.data;
        props.changeCourseNameArray(propsCourseName);
      });
  }, [props.courseId]);

  useEffect(() => {
    if (courseName) {
      // console.log("course Info:", courseName);
    }
  }, [courseName]);

  //   useEffect(() => {
  //     console.log("Course Name:", courseName);
  //   }, [courseName]);

  useEffect(() => {
    // let newArray = [...props.allData];
    // for (var i = 0; i < newArray.length; i++) {
    //
    if (
      props?.courseName &&
      props?.courseName.trim() != "" &&
      courseName != "" &&
      props.allData
    ) {
      let newArray = [...props.allData];
      // console.log("I'm here:", props.allData);
      // console.log("I'm here:", props.keyProp);

      if (
        courseName.toUpperCase().indexOf(props.courseName.toUpperCase()) > -1
      ) {
        newArray[props.keyProp].displayCourse = false;
        // console.log("True", newArray[props.keyProp]);
      } else {
        newArray[props.keyProp].displayCourse = true;
        // console.log("False", newArray[props.keyProp]);
      }
      props.changeData(newArray);
    } else if (props.courseName == "" && props.allData) {
      let newArray = [...props.allData];
      newArray[props.keyProp].displayCourse = false;
      props.changeData(newArray);
    }
    // }
    // setRecordProps(newArray);
  }, [props?.courseName]);

  useEffect(() => {
    if (props?.changeState && courseName != "") {
      props.changeState(courseName);
    }
  }, [props?.changeState, courseName]);

  return <Container>{courseName ? courseName : <div></div>}</Container>;
}

export default ShowCourseName;

const Container = styled.div`
  /* margin: 0px 5px 0px 5px; */
  font-weight: 600;
`;
