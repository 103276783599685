import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ITSNotification from "./ITSNotification";
import ITTNotification from "./ITTNotification";
import SimpleNotificSimple from "./SimpleNotification";

function MyNotifications() {
  const history = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [userNotifications, setUserNotifications] = useState();
  useEffect(() => {
    axios.post("/extractNotifications", { email: user.email }).then((data) => {
      setUserNotifications(data.data);
    });
  }, []);

  // useEffect(() => {
  //   if (userNotifications) {
  //     console.log(
  //       "Vaaaaaaaaaaaaaaa",
  //       new Date().toISOString() - Date.parse(userNotifications[0].sentAt)
  //     );
  //   }
  // }, [userNotifications]);

  const onClickNotificationContainer = (inputItem) => {
    axios
      .post("/seenNotification", {
        email: user.email,
        noteId: inputItem.noteId,
      })
      .then(() => {
        if (inputItem.type == "ITT") {
          history("/teaminvitenotification", { state: inputItem });
        } else if (inputItem.type == "ITS") {
          history("/shareinvitenotification", { state: inputItem });
        } else {
          history("/simplenotification", { state: inputItem });
        }
      });
  };
  return (
    <Container>
      {userNotifications ? (
        userNotifications.map(
          (item) => (
            <NotificationsContainer
              onClick={() => onClickNotificationContainer(item)}
            >
              <NotificationTitle
                style={{ fontWeight: item.seen ? "300" : "600" }}
              >
                Subject: {item.title}
              </NotificationTitle>
              <NotificationContent>
                <NotificationSender>From: {item.sender}</NotificationSender>
                <NotificationDate>
                  {Math.round(
                    (Date.parse(new Date().toISOString()) -
                      new Date(item.sentAt).getTime()) /
                      (1000 * 3600 * 24)
                  ) == 0
                    ? "Today"
                    : Math.round(
                        (Date.parse(new Date().toISOString()) -
                          new Date(item.sentAt).getTime()) /
                          (1000 * 3600 * 24)
                      ) == 1
                    ? Math.round(
                        (Date.parse(new Date().toISOString()) -
                          new Date(item.sentAt).getTime()) /
                          (1000 * 3600 * 24)
                      ) + " day ago"
                    : Math.round(
                        (Date.parse(new Date().toISOString()) -
                          new Date(item.sentAt).getTime()) /
                          (1000 * 3600 * 24)
                      ) + " days ago"}
                </NotificationDate>
              </NotificationContent>
            </NotificationsContainer>
          )
          // item.type == "ITT" ? (
          //   <ITTNotification />
          // ) : item.type == "ITS" ? (
          //   <ITSNotification />
          // ) : (
          //   <SimpleNotificSimple />
          // )
        )
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default MyNotifications;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;

const NotificationsContainer = styled.div`
  border-radius: 4px;
  border: 1px solid gray;
  cursor: pointer;
  margin-bottom: 5px;
`;
const NotificationTitle = styled.div`
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
`;
const NotificationDate = styled.div``;
const NotificationSender = styled.div``;
const NotificationContent = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;
