import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../Images/full-logo.png";
import backgroundImage from "../Images/Login_Background6.png";
import topPattern from "../Images/pattern_top.png";
import cloud1 from "../Images/cloud1.png";
import cloud2 from "../Images/cloud2.png";
import cloud3 from "../Images/cloud3.png";
import cloud4 from "../Images/cloud4.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// import { auth, signInWithEmailAndPassword } from "../firebase";
import firebaseApp from "../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const Container = styled.div`
  /* margin: 0px 10px 10px 10px; */
  height: 100vh;
  width: 100%;
  /* background: #f9f0ec; */

  /* background-repeat: repeat; */
  /* position: relative;
  background: url(${backgroundImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */

  display: grid;
  grid-template-rows: 50px auto;
  /* &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${backgroundImage}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
  /* opacity: 0.5; */
  /* }  */
`;

const ContainerHeader = styled.div`
  background: url(${topPattern}) repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  opacity: 0.3;
`;
const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  z-index: 2;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  /* border-left: 2px dashed #9c9fb0;
  border-right: 2px dashed #9c9fb0;
  border-bottom: 2px dashed #9c9fb0; */
  /* padding: 10px; */
  z-index: 1;
  background: white;
  padding: 100px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* position: absolute;
  right: 10%;
  top: 10%; */

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(244, 238, 238, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const LoginForm = styled.div`
  /* padding: 10px; */
  max-width: 400px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;

  position: relative;
  /* background: url(${backgroundImage}) no-repeat center center fixed; */
  background: url(${backgroundImage}) no-repeat bottom center;
  background-color: white;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  /* background: url(${backgroundImage}) no-repeat;
  background-size: cover; */
  /* background: url(${backgroundImage}) no-repeat right center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain; */
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlackImage = styled.img`
  height: 70px;
  cursor: pointer;

  /* margin-bottom: 30px; */
`;

const LoginButton = styled.button`
  width: 100%;
  margin-top: 0px;
  /* background-color: #5fb05f; */
  background-color: rgba(17, 34, 17, 0.8);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(17, 34, 17, 1);
  }
`;

const LoadingLoginButton = styled.div`
  width: 100%;
  margin-top: 0px;
  /* background-color: #5fb05f; */
  background-color: #adb5bd;
  color: #ced4da;
  display: flex;

  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: default;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
`;

const LoginButtonContainer = styled.div`
  width: 100%;
`;

const UserName = styled.input`
  padding: 11.5px;
  margin-top: 15px;
`;
const Password = styled.input`
  padding: 11.5px;
`;

const UserNameError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
  /* left: 0; */
`;
const PasswordError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
`;

const UserNameContainer = styled.div`
  width: 100%;
`;
const PasswordContainer = styled.div``;

function Login(props) {
  const history = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [signUpIsLoading, setSignUpIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [noteMessage, setNoteMessage] = useState("");

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 10,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor:
  //       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  //   },
  // }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (location.state?.email) {
      setUserName(location.state.email);
    }
  }, []);

  useEffect(() => {
    if (location.state?.message) {
      setNoteMessage(location.state?.message);
      setOpen(true);
    }
  }, [location.state?.message]);

  const onClickCompanyLogo = () => {
    history("/");
  };
  // const popUpMessage = isEmailVerified ? (
  //   <div>
  //     <h1>Popup/Modal Windows without JavaScript</h1>
  //     <div class="box">
  //       <a class="button" href="#popup1">
  //         Let me Pop up
  //       </a>
  //     </div>

  //     <div id="popup1" class="overlay">
  //       <div class="popup">
  //         <h2>Here i am</h2>
  //         <a class="close" href="#">
  //           &times;
  //         </a>
  //         <div class="content">
  //           Thank to pop me out of that button, but now i'm done so you can
  //           close this window.
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   <div></div>
  // );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keepUserName = (event) => {
    setUserName(event.target.value);
    // console.log(event.target.value);
  };

  const onClickForgotPassword = () => {
    history("/forgotpasswort");
  };

  // const keepPassword = (event) => {
  //   setPassword(event.target.value);
  // };

  const onClickCreateAnAccount = () => {
    setSignUpIsLoading(true);
    history("/signup");
  };

  useEffect(() => {
    if (!isEmpty(userName)) {
      setUserNameError("");
    }
  }, [userName]);

  useEffect(() => {
    if (!isEmpty(password)) {
      setPasswordError("");
    }
  }, [password]);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isPassword = (password) => {
    const re =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_])(?=.*[A-Z]{2})(?=.*[a-z]{2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    if (password.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onClickLogin = () => {
    const auth = getAuth(firebaseApp);

    // console.log("username:", userName);
    // console.log("Password", password);
    if (
      !isEmpty(userName) &&
      !isEmpty(password) &&
      // isPassword(password) &&
      isEmail(userName)
    ) {
      setPasswordError("");
      setUserNameError("");
      setLoginIsLoading(true);
      axios.post(`/isEmailVerified`, { userEmail: userName }).then((data) => {
        if (data.data.isVerified) {
          // console.log("is verified:", userName);
          // console.log("is verified:", password);
          axios
            .post("/passwordGenerator", { password: password })
            .then((newPassword) => {
              signInWithEmailAndPassword(
                auth,
                userName,
                newPassword.data.newPass
              )
                .then((userCredential) => {
                  let output = {
                    UserID: userCredential.user.uid,
                    fullName: userCredential.user.displayName,
                    email: userCredential.user.email,
                    image: userCredential.user.photoURL,
                    token: userCredential.user.getIdToken(),
                    emailVerified: userCredential.user.emailVerified,
                  };
                  if (!localStorage.getItem("user")) {
                    localStorage.setItem("user", JSON.stringify(output));
                  }
                  props.passUser(output);

                  history("/", { user: output });
                })
                .catch((error) => {
                  setLoginIsLoading(false);
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // console.log("Error message:", errorMessage);
                  if (errorCode == "auth/wrong-password") {
                    setPasswordError("Wrong Password!");
                  }

                  if (errorCode == "auth/invalid-email") {
                    setUserNameError("Invalid Email!");
                  }
                });
            });
        } else {
          setLoginIsLoading(false);
          setUserNameError("Email is not verified.");
        }
      });
      // .then(() => {
      //   history("/");
      // });
    } else {
      setLoginIsLoading(false);
      if (isEmpty(password)) {
        setPasswordError("Please fill in the password.");
      }
      if (isEmpty(userName)) {
        setUserNameError("Please fill in the email.");
      } else if (!isEmail(userName)) {
        setUserNameError("Invalid Email!");
      }
    }
  };
  //   if (
  //     // emailVerified &&
  //     isEmpty(passwordError) &&
  //     isEmpty(emailError) &&
  //     isEmpty(overalError)
  //   ) {
  //     localStorage.setItem("user", JSON.stringify(data.data.output));
  //     props.passUser(data.data.output);
  //     // history.push("/");
  //     history.goBack();
  //   } else if (!emailVerified) {
  //   }
  // });
  // }
  // else {
  //   if (!userName) {
  //     setUserNameError("Must not be empty!");
  //   } else if (!isEmail(userName)) {
  //     setUserNameError("Email is not valid!");
  //   } else {
  //     setUserNameError("");
  //   }

  //   if (!password) {
  //     setPasswordError("Must not be empty!");
  //   } else if (!isPassword(password)) {
  //     setPasswordError("Password is not secure!");
  //   } else {
  //     setPasswordError("");
  //   }
  // }
  // };
  // const signIn = () => {
  //   auth
  //     .signInWithPopup(provider)
  //     .then((dat) => {
  //       const newUser = {
  //         name: dat.user.displayName,
  //         photo: dat.user.photoURL,
  //       };
  //       localStorage.setItem("user", JSON.stringify(newUser));
  //       props.passUser(newUser);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  return (
    <Container>
      <ContainerHeader />

      <LoginFormContainer>
        <LoginForm>
          <LogoContainer>
            <SlackImage src={logo} onClick={() => onClickCompanyLogo()} />
          </LogoContainer>
          <Cloud1Container>
            <Cloud1 src={cloud1} />
          </Cloud1Container>
          <Cloud2Container>
            <Cloud2 src={cloud2} />
          </Cloud2Container>
          <Cloud3Container>
            <Cloud3 src={cloud3} />
          </Cloud3Container>

          {/* <BackgroundImage src={backgroundImage} /> */}
          <MainContent>
            {/* <LoginForm>Sign In</LoginForm> */}
            <Cloud4Container>
              <Cloud4 src={cloud4} />
            </Cloud4Container>
            <UserNameContainer>
              {/* <UserName
                placeholder="Email"
                onChange={(e) => keepUserName(e)}
                value={userName}
              /> */}
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                onChange={(e) => keepUserName(e)}
                value={userName}
              />
              <UserNameError>
                {userNameError !== "" ? `*${userNameError}` : ""}
              </UserNameError>
            </UserNameContainer>
            <PasswordContainer>
              {/* <Password
                type="password"
                placeholder="Password"
                onChange={(e) => keepPassword(e)}
              /> */}

              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              {/* <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                onChange={(e) => keepPassword(e)}
                value={userName}
              /> */}
              <PasswordError>
                {passwordError !== "" ? `*${passwordError}` : ""}
              </PasswordError>
            </PasswordContainer>
            <LoginButtonContainer>
              {!loginIsLoading ? (
                <LoginButton onClick={() => onClickLogin()}>Login</LoginButton>
              ) : (
                <LoadingLoginButton>
                  Login
                  <Box sx={{ position: "absolute", lineHeight: "10px" }}>
                    <CircularProgress
                      variant="determinate"
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[
                            theme.palette.mode === "light" ? 200 : 800
                          ],
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                      value={100}
                    />
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "#254336"
                            : "#308fe8",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                    />
                  </Box>
                </LoadingLoginButton>
              )}
            </LoginButtonContainer>

            <SignUpButtonContainer>
              {!signUpIsLoading ? (
                <SignUpButton onClick={() => onClickCreateAnAccount()}>
                  Create an account
                </SignUpButton>
              ) : (
                <LoadingSignUpButton>
                  Create an account
                  <Box sx={{ position: "absolute", lineHeight: "10px" }}>
                    <CircularProgress
                      variant="determinate"
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[
                            theme.palette.mode === "light" ? 200 : 800
                          ],
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                      value={100}
                    />
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "#254336"
                            : "#308fe8",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                    />
                  </Box>
                </LoadingSignUpButton>
              )}
            </SignUpButtonContainer>
            <ForgetUsernameContainer>
              <ForgetPasswordLink onClick={() => onClickForgotPassword()}>
                Forgot password?
              </ForgetPasswordLink>
            </ForgetUsernameContainer>
          </MainContent>
        </LoginForm>
      </LoginFormContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}>
          {noteMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;

const SignUpButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const SignUpButton = styled.button`
  border-color: rgba(17, 34, 17);
  border-radius: 4px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #003300;
    color: #003300;
  }
`;

const LoadingSignUpButton = styled.button`
  border-color: #adb5bd;
  border-radius: 4px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #adb5bd;
  cursor: default;
`;

const ForgetUsernameContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  color: rgb(17, 34, 17);
  cursor: pointer;
  font-size: 12px;
`;

const Cloud1Container = styled.div`
  position: absolute;
  top: 10px;
  right: -110px;
`;
const Cloud2Container = styled.div`
  position: absolute;
  top: 190px;
  right: -80px;
`;
const Cloud3Container = styled.div`
  position: absolute;
  top: 190px;
  left: -130px;
`;
const Cloud4Container = styled.div`
  position: absolute;
  top: -6px;
  left: -160px;
`;

const Cloud3 = styled.img`
  width: 240px;
`;
const Cloud1 = styled.img`
  width: 290px;
`;
const Cloud2 = styled.img`
  width: 200px;
`;
const Cloud4 = styled.img`
  width: 300px;
`;

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ForgetPasswordLink = styled.div`
  width: fit-content;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(17, 34, 17);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
