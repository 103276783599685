import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowCourseStatus from "./ShowCourseStatus";
import ShowCompanyUserRole from "./ShowCompanyUserRole";
import axios from "axios";

// ================================================================

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbar,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

const columns = [
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "role", label: "User Role" }, //, minWidth: 100
];

// ================================================================
function CompanyMemberRolesTable(props) {
  const [recordProps, setRecordProps] = useState(props.data);
  const [emailSearchDisplay, setEmailSearchDisplay] = useState([]);
  const [courseNameEntered, setCourseNameEntered] = useState("");
  // const [teamMembers, setTeamMembers] = useState([]);
  const [courseStatusSelected, setCourseStatusSelected] = useState("");
  const [emailSelected, setEmailSelected] = useState("");
  const [changesApplied, setChangesApplied] = useState([]);
  // ========================================================

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [searchedRecords, setSearchedRecords] = useState(null);

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: "rating",
        operatorValue: ">",
        value: "2.5",
      },
    ],
  });

  const dataGridColumns = [
    { field: "userEmail", headerName: "User Email", width: 150, flex: 1 },
    {
      field: "userRoleField",
      headerName: "User Role",
      width: 150,
      flex: 1,
      // type: "singleSelect",
      renderCell: (cellValues) => {
        // console.log("Cell value:", cellValues);
        return (
          <ShowCompanyUserRole
            userRole={cellValues.row.role}
            keyProp={cellValues.row.id}
            changeData={(val) => setRecordProps(val)}
            addIndex={(v) => setChangesApplied(v)}
            indexes={changesApplied}
            allData={mainRecordProps}
            roleStatus={courseStatusSelected}
            currentUserRole={props.role}
          />
          // <Radio
          //   checked={cellValues.id === this.state.form.selectedGroup}
          //   onChange={this.handleChangeRadio.bind(this,cellValues.id)}
          //   value={cellValues.id}
          //   name="radio-buttons"
          //   inputProps={{ 'aria-label': {cellValues} }}
          // />
        );
      },
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 10,
  });

  useEffect(() => {
    // console.log("The entire data:", data);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ==========================================================

  const onChangeEmailSearch = (e) => {
    if (recordProps) {
      setEmailSelected(e.target.value);
      // setCourseStatusSelected(e.target.value);
      let searchKey = e.target.value;
      let tempAllArr = [...mainRecordProps];
      let tempSearchArr = [...searchedRecords];
      // console.log("Course status selected:", courseStatusSelected);
      if (
        courseStatusSelected.trim() === "" ||
        courseStatusSelected === "all"
      ) {
        if (searchKey.trim() === "") {
          setSearchedRecords(tempAllArr);
          // console.log("You selected All");
        } else {
          // console.log("Course status selected ma inja inja");
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) >
              -1
            );
          });
          // console.log("You returned:", filtered);
          setSearchedRecords(filtered);
        }
      } else {
        var filtered = tempAllArr.filter(function (value, index, arr) {
          return (
            value.role === courseStatusSelected &&
            value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) > -1
          );
        });
        // console.log("You returned:", filtered);
        setSearchedRecords(filtered);
      }
    }
    // if (recordProps) {
    //   let newArray = [...recordProps];
    //   for (var i = 0; i < newArray.length; i++) {
    //     if (
    //       newArray[i].userEmail
    //         .toUpperCase()
    //         .indexOf(e.target.value.toUpperCase()) > -1
    //     ) {
    //       newArray[i].displayEmail = false;
    //     } else {
    //       newArray[i].displayEmail = true;
    //     }
    //   }
    //   setRecordProps(newArray);
    // }
  };

  const onChangeCourseSearch = (e) => {
    if (recordProps) {
      // console.log("Entering something:", e.target.value);
      setCourseNameEntered(e.target.value);

      // let newArray = [...recordProps];
      // for (var i = 0; i < newArray.length; i++) {
      //   if (
      //     newArray[i].courseName
      //       .toUpperCase()
      //       .indexOf(e.target.value.toUpperCase()) > -1
      //   ) {
      //     newArray[i].displayCourse = false;
      //   } else {
      //     newArray[i].displayCourse = true;
      //   }
      // }
      // setRecordProps(newArray);
    }
  };

  const onChangeStatusSearch = (e) => {
    if (recordProps) {
      setCourseStatusSelected(e.target.value);
      let searchKey = e.target.value;
      let tempAllArr = [...mainRecordProps];
      let tempSearchArr = [...searchedRecords];
      if (emailSelected.trim() == "") {
        if (searchKey == "all") {
          setSearchedRecords(tempAllArr);
          // console.log("You selected All");
        } else {
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return value.role === searchKey;
          });
          // console.log("You returned:", filtered);
          setSearchedRecords(filtered);
        }
      } else {
        if (searchKey == "all") {
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              (value.role === "member" ||
                value.role === "owner" ||
                value.role === "admin") &&
              value.userEmail
                .toUpperCase()
                .indexOf(emailSelected.toUpperCase()) > -1
            );
          });
          setSearchedRecords(filtered);
          // console.log("You selected All");
        } else {
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              value.role === searchKey &&
              value.userEmail
                .toUpperCase()
                .indexOf(emailSelected.toUpperCase()) > -1
            );
          });
          // console.log("You returned:", filtered);
          setSearchedRecords(filtered);
        }
      }
    }

    // const status = e.target.value;
  };

  const onClickSubmitChanges = () => {
    if (changesApplied && changesApplied.length > 0) {
      axios.post("/userRoleChanging", {
        indexArray: changesApplied,
        allData: recordProps,
      });
    }
  };

  // useEffect(() => {
  //   console.log("Dataaaa:", props.data);
  // }, [team]);

  // useEffect(() => {
  //   if (props.data) {
  //     let arr = props.data;
  //     let newArr = [];
  //     arr.forEach((items) => {
  //       items.sharedCourses.forEach((crsId) => {
  //         newArr.push({
  //           teamId: items.teamId,
  //           sharedCourse: crsId,
  //           userEmail: items.userEmail,
  //           role: items.role,
  //         });
  //       });
  //     });
  //     Promise.all(newArr).then(() => setTeamMembers(newArr));
  //   }
  // }, [props.data]);

  useEffect(() => {
    if (props.data) {
      setRecordProps(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (recordProps) {
      let tempArr = [...recordProps];
      // console.log("Team members:", recordProps);
      tempArr.forEach((item, key) => {
        item.id = key;
      });
      // console.log("Temp array:", tempArr);
      setMainRecordProps(tempArr);
    }
    return () => {
      setMainRecordProps(null);
    };
  }, [recordProps]);

  useEffect(() => {
    if (mainRecordProps) {
      // console.log("Temp array:", mainRecordProps);
      setSearchedRecords([...mainRecordProps]);
    }
  }, [mainRecordProps]);

  useEffect(() => {
    // console.log("Temp array 2:", searchedRecords);
  }, [searchedRecords]);

  useEffect(() => {
    // console.log("My Indexes:", changesApplied);
  }, [changesApplied]);

  useEffect(() => {
    return () => {
      setMainRecordProps(null);
      setRecordProps(null);
    };
  }, []);

  return (
    <Container>
      <CustomTable>
        <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
          {/* <CourseSearchContainer>
            <CourseSearch
              onChange={(e) => onChangeCourseSearch(e)}
              placeholder="Course..."
            />
            <CourseSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </CourseSearchButton>
          </CourseSearchContainer> */}
          <StatusSearchContainer>
            <StatusSearch
              defaultValue="select"
              onChange={(e) => onChangeStatusSearch(e)}
            >
              <option value="all">All</option>
              <option value="owner">Owner</option>
              <option value="admin"> Admin</option>
              <option value="member">Member</option>
            </StatusSearch>
            <StatusSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </StatusSearchButton>
          </StatusSearchContainer>
        </SearchTable>
        {/* =================================================================== */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "350px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedRecords ? (
                  searchedRecords
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            display:
                              !row?.displayEmail &&
                              !row?.displayCourse &&
                              !row?.displayStatus
                                ? ""
                                : "none",
                          }}
                        >
                          <TableCell>{row.userEmail}</TableCell>
                          <TableCell>
                            <ShowCompanyUserRole
                              userRole={row.role}
                              keyProp={row.id}
                              changeData={(val) => setRecordProps(val)}
                              // changeData={(val) => setSearchedRecords(val)}
                              addIndex={(v) => setChangesApplied(v)}
                              indexes={changesApplied}
                              allData={mainRecordProps}
                              // roleStatus={courseStatusSelected}
                              currentUserRole={props.role}
                              selectedData={searchedRecords}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={
              searchedRecords
                ? searchedRecords?.length
                : searchedRecords?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* ==================================================================== */}
        {/* <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            columns={dataGridColumns}
            rows={mainRecordProps}
            components={{
              // Toolbar: CustomToolbar,
              Toolbar: GridToolbar,
            }}
            style={{ backgroundColor: "white" }}
            rowsPerPageOptions={[5, 10, 20]}
            // pageSize={dataGridPageSize}
            // onPageSizeChange={(newPageSize) => setDataGridPageSize(newPageSize)}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
          />
        </div> */}
        {/* ======================================================================== */}
        {/* <Header>
          <FirstHeader>Student Email</FirstHeader>
          <ThirdHeader>User Role</ThirdHeader>
        </Header>
        <CustomTableBody>
          {recordProps ? (
            recordProps.map((item, key) => (
              <CustomTableRow
                style={{
                  display:
                    !item?.displayEmail &&
                    !item?.displayCourse &&
                    !item?.displayStatus
                      ? ""
                      : "none",
                }}
              >
                <FirstColumn>{item.userEmail}</FirstColumn>

                <ThirdColumn>
                  <ShowCompanyUserRole
                    userRole={item.role}
                    keyProp={key}
                    changeData={(val) => setRecordProps(val)}
                    addIndex={(v) => setChangesApplied(v)}
                    indexes={changesApplied}
                    allData={recordProps}
                    roleStatus={courseStatusSelected}
                    currentUserRole={props.role}
                  />
                </ThirdColumn>
              </CustomTableRow>
            ))
          ) : (
            <div></div>
          )}
        </CustomTableBody> */}
      </CustomTable>
      {/* hello */}
      {props.role == "owner" || props.role == "admin" ? (
        <SubmitButtonContainer>
          <SubmitButton onClick={() => onClickSubmitChanges()}>
            Submit
          </SubmitButton>
        </SubmitButtonContainer>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default CompanyMemberRolesTable;

const Container = styled.div`
  /* padding: 90px 20px 20px 20px; */
  padding: 20px;
`;
const CustomTable = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
const Header = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
`;
const CustomTableBody = styled.div`
  /* height: 500px; */
  overflow: auto;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
`;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const CustomTableRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;
padding:5px;
`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 28px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  /* cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  background-color: green;
  border: none;
  outline: none; */

  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px 60px;
  background-color: rgba(95, 176, 95, 0.9);
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(95, 176, 95, 1);
  }
`;
