import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

function UserCheckBox(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isInTeam, setIsInTeam] = useState(false);
  const [userEmail, setUserEmail] = useState(props.userEmail);
  const [courseId, setCourseId] = useState(props.courseId);
  const [userCourseId, setUserCourseId] = useState(null);

  useEffect(() => {
    if (userEmail && courseId && user) {
      axios
        .post("/hasUserTeam", {
          userEmail: userEmail,
          courseId: courseId,
          email: user.email,
        })
        .then((boolOutput) => {
          setIsInTeam(boolOutput.data.hasTeam);
          setUserCourseId(boolOutput.data.userCourseId);
        });
    }
  }, [userEmail, courseId]);

  const onCheckboxChange = (e) => {
    if (e.target.checked) {
      // let newArray = props.list;
      // newArray.push({
      //   userEmail: props.userEmail,
      //   courseId: props.courseId,
      //   whoPaid: user.email,
      // });

      // console.log("I'm here", newArray);
      // props.oserdersUsersList(newArray);
      // props.oserdersUsersList(newArray + 1);
      props.oserdersUsersList([
        ...props.list,
        {
          userCourseId: userCourseId,
          userEmail: props.userEmail,
          courseId: props.courseId,
        },
      ]);
    } else {
      let newArray = props.list;
      let anotherNew = newArray.filter(
        (item) => item.userCourseId !== userCourseId
      );
      // console.log("new Delete:", anotherNew);
      props.oserdersUsersList(anotherNew);
      // props.oserdersUsersList(
      //   props.list.filter(
      //     (item) =>
      //       item.userEmail !== props.userEmail &&
      //       item.courseId !== props.courseId
      //   )
      // );
    }
  };

  return (
    <Container>
      {isInTeam ? (
        <UserInnerCheckBox
          type="checkbox"
          onChange={(e) => onCheckboxChange(e)}
        />
      ) : (
        <EmptySpace></EmptySpace>
      )}
    </Container>
  );
}

export default UserCheckBox;

const Container = styled.div``;
const UserInnerCheckBox = styled.input``;

const EmptySpace = styled.div`
  padding-left: 13px;
`;
