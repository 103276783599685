import React, { useEffect, useState } from "react";
import styled from "styled-components";

function ShowCompanyUserRole(props) {
  const [userRole, setUserRole] = useState(props.userRole);
  const [optionsValue, setOptionsValue] = useState(props.userRole);

  // useEffect(() => {
  //   if (props.userRole) {
  //     setUserRole(props.userRole);
  //   }
  // }, [props.userRole]);

  // useEffect(() => {
  //   const role = userRole;
  //   let status = props.roleStatus;
  //   let allDataArray = [...props.allData];
  //   // console.log("Key prop:", props.keyProp);

  //   if (status === "owner") {
  //     if (role == "owner") {
  //       allDataArray[props.keyProp].displayStatus = false;
  //     } else {
  //       allDataArray[props.keyProp].displayStatus = true;
  //     }

  //     props.changeData(allDataArray);
  //   } else if (status === "admin") {
  //     if (role == "admin") {
  //       allDataArray[props.keyProp].displayStatus = false;
  //     } else {
  //       allDataArray[props.keyProp].displayStatus = true;
  //     }

  //     props.changeData(allDataArray);
  //   } else if (status === "member") {
  //     if (role == "member") {
  //       allDataArray[props.keyProp].displayStatus = false;
  //     } else {
  //       allDataArray[props.keyProp].displayStatus = true;
  //     }

  //     props.changeData(allDataArray);
  //   } else {
  //     allDataArray[props.keyProp].displayStatus = false;

  //     props.changeData(allDataArray);
  //   }
  // }, [props.roleStatus]);

  const onChangeRoleOptions = (e) => {
    // console.log("User role", userRole);
    // console.log("User role copy", optionsValue);
    setUserRole(e.target.value);
    // console.log("Salam");
    // setOptionsValue(e.target.value);
    let arr = [...props.indexes];
    let dataArr = [...props.allData];
    let ind = arr.indexOf(props.keyProp);
    if (e.target.value !== optionsValue) {
      if ((arr.length > 0 && ind == -1) || arr.length == 0) {
        dataArr[props.keyProp].role = e.target.value;
        props.changeData(dataArr);
        arr.push(props.keyProp);
        props.addIndex(arr);
      } else if (arr.length > 0 && ind !== -1) {
        dataArr[props.keyProp].role = e.target.value;
        props.changeData(dataArr);
      }
    } else {
      if (arr.length > 0 && ind !== -1) {
        dataArr[props.keyProp].role = e.target.value;
        props.changeData(dataArr);
        arr.splice(ind, 1);
        props.addIndex(arr);
      }
    }
  };

  return (
    <Container>
      <OwnerContainer>
        <OwnerLabel>Owner:</OwnerLabel>

        <OwnerOption
          type="radio"
          name={props.keyProp}
          value="owner"
          checked={userRole == "owner" ? true : false}
          onChange={(e) => onChangeRoleOptions(e)}
          disabled={
            props.currentUserRole == "member" ||
            (userRole == "owner" && props.currentUserRole == "admin") ||
            props.currentUserRole == "admin"
              ? true
              : false
          }
        />
      </OwnerContainer>
      <AdminContainer>
        <AdminLabel>Admin:</AdminLabel>

        <AdminOption
          type="radio"
          name={props.keyProp}
          value="admin"
          checked={userRole == "admin" ? true : false}
          onChange={(e) => onChangeRoleOptions(e)}
          disabled={
            props.currentUserRole == "member" ||
            (userRole == "owner" && props.currentUserRole == "admin")
              ? true
              : false
          }
        />
      </AdminContainer>
      <MemberContainer>
        <MemberLabel>Member:</MemberLabel>

        <MemberOption
          type="radio"
          name={props.keyProp}
          value="member"
          checked={userRole == "member" ? true : false}
          onChange={(e) => onChangeRoleOptions(e)}
          disabled={
            props.currentUserRole == "member" ||
            (userRole == "owner" && props.currentUserRole == "admin") ||
            props.currentUserRole == "admin"
              ? true
              : false
          }
        />
      </MemberContainer>
    </Container>
  );
}

export default ShowCompanyUserRole;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;
const OwnerOption = styled.input``;
const AdminOption = styled.input``;
const MemberOption = styled.input``;

const OwnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AdminContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MemberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OwnerLabel = styled.div`
  margin-right: 2px;
  font-size: 12px;
  height: 100%;
`;
const AdminLabel = styled.div`
  margin-right: 2px;
  font-size: 12px;
  height: 100%;
`;
const MemberLabel = styled.div`
  margin-right: 2px;
  font-size: 12px;
  height: 100%;
`;
