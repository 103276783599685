import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../Images/full-logo.png";
import backgroundImage from "../Images/Login_Background_forgetPass1.png";
import topPattern from "../Images/pattern_top.png";
import cloud1 from "../Images/Login_Background_forgetPass_cloud.png";
import cloud2 from "../Images/Login_Background_forgetPass_sun.png";
import cloud3 from "../Images/cloud3.png";
import cloud4 from "../Images/cloud4.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Container = styled.div`
  /* margin: 0px 10px 10px 10px; */
  height: 100vh;
  width: 100%;
  /* background: #f9f0ec; */

  /* background-repeat: repeat; */
  /* position: relative;
  background: url(${backgroundImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */

  display: grid;
  grid-template-rows: 50px auto;
  /* &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${backgroundImage}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
  /* opacity: 0.5; */
  /* }  */
`;

const ContainerHeader = styled.div`
  background: url(${topPattern}) repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  opacity: 0.3;
`;
const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  z-index: 2;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  /* border-left: 2px dashed #9c9fb0;
  border-right: 2px dashed #9c9fb0;
  border-bottom: 2px dashed #9c9fb0; */
  /* padding: 10px; */
  width: 80%;
  max-width: 600px;
  z-index: 1;
  background: white;
  padding: 50px 70px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* position: absolute;
  right: 10%;
  top: 10%; */

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(244, 238, 238, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const LoginForm = styled.div`
  /* padding: 10px; */

  width: 80%;
  max-width: 600px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;

  position: relative;
  /* background: url(${backgroundImage}) no-repeat center center fixed; */
  /* background: url(${backgroundImage}) no-repeat bottom center;
  background-color: white;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain; */

  /* background: url(${backgroundImage}) no-repeat;
  background-size: cover; */
  /* background: url(${backgroundImage}) no-repeat right center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain; */
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlackImage = styled.img`
  height: 70px;
  cursor: pointer;

  /* margin-bottom: 30px; */
`;

const LoginButton = styled.button`
  width: 100%;
  margin-top: 10px;
  /* background-color: #5fb05f; */

  background-color: rgba(17, 34, 17, 0.8);
  color: white;

  /* background-color: transparent; */
  border: none;

  /* border-color: #5fb05f; */
  /* border: 2px solid #5fb05f; */
  outline: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(17, 34, 17, 1);
    border-color: rgba(95, 176, 95, 0);
    /* color: #5fb05f; */
  }

  &:disabled {
    background: #999;
    color: #555;
    cursor: not-allowed;
  }
`;

const LoadingLoginButton = styled.div`
  width: 100%;
  margin-top: 10px;
  /* background-color: #5fb05f; */

  background-color: #adb5bd;
  color: #ced4da;

  /* background-color: transparent; */
  border: none;

  /* border-color: #5fb05f; */
  /* border: 2px solid #5fb05f; */
  outline: none;
  border-radius: 4px;
  padding: 10px;
  cursor: default;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const LoginButtonContainer = styled.div`
  width: 100%;
`;

const UserName = styled.input`
  padding: 11.5px;
  margin-top: 15px;
`;
const Password = styled.input`
  padding: 11.5px;
`;

const UserNameError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
  /* left: 0; */
`;
const PasswordError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
`;

const UserNameContainer = styled.div`
  width: 100%;
`;
const PasswordContainer = styled.div``;
//
function ForgetPassword(props) {
  const history = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [error, setError] = useState();
  const [isDisable, setIsDisable] = useState();
  const [errorColor, setErrorColor] = useState("red");
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  useEffect(() => {
    if (location.state?.email) {
      setUserName(location.state.email);
    }
  }, []);

  const onClickCompanyLogo = () => {
    history("/");
  };
  // const popUpMessage = isEmailVerified ? (
  //   <div>
  //     <h1>Popup/Modal Windows without JavaScript</h1>
  //     <div class="box">
  //       <a class="button" href="#popup1">
  //         Let me Pop up
  //       </a>
  //     </div>

  //     <div id="popup1" class="overlay">
  //       <div class="popup">
  //         <h2>Here i am</h2>
  //         <a class="close" href="#">
  //           &times;
  //         </a>
  //         <div class="content">
  //           Thank to pop me out of that button, but now i'm done so you can
  //           close this window.
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   <div></div>
  // );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keepUserName = (event) => {
    setUserName(event.target.value);
  };

  // const keepPassword = (event) => {
  //   setPassword(event.target.value);
  // };

  const onClickLiginLink = () => {
    history("/login");
  };

  const onClickSignUpLink = () => {
    history("/signup");
  };

  useEffect(() => {
    if (!isEmpty(userName)) {
      setUserNameError("");
    }
  }, [userName]);

  useEffect(() => {
    if (!isEmpty(password)) {
      setPasswordError("");
    }
  }, [password]);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isPassword = (password) => {
    const re =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_])(?=.*[A-Z]{2})(?=.*[a-z]{2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    if (password.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };

  const onClickLogin = () => {
    if (!isEmpty(userName) && isEmail(userName)) {
      setError("");
      setIsDisable(true);
      setSubmitIsLoading(true);
      axios
        .post(`/sendEmailForForgetPassword`, { userEmail: userName })
        .then((data) => {
          setIsDisable(false);
          setSubmitIsLoading(false);
          if (data.data?.message) {
            setErrorColor("red");
            if (data.data.message == "emailNotSent") {
              setError("Reset link is not sent. Please try again!");
            } else if (data.data.message == "email") {
              setError("Email is not registered.");
            } else if (data.data.message == "verify") {
              setError("Email is not verified.");
            } else {
              setError("System error. Please try again!");
            }
          } else {
            setErrorColor("green");
            setError("Reset password link in successfully sent to your email");
          }
        });
    } else {
      if (isEmpty(userName)) {
        setError("Please fill out the field with your email address.");
      } else if (!isEmail(userName)) {
        setError("Invalid email address.");
      }
    }
  };

  return (
    <Container>
      <ContainerHeader />

      <LoginFormContainer>
        <LoginForm>
          {/* <Cloud2Container>
            <Cloud2 src={cloud2} />
          </Cloud2Container> */}
          <LogoContainer>
            <SlackImage src={logo} onClick={() => onClickCompanyLogo()} />
          </LogoContainer>

          <MainContent>
            {/* <Cloud1Container>
              <Cloud1 src={cloud1} />
            </Cloud1Container> */}
            {/* <InstructionContainer>
              <h1>Forgot Password?</h1>
              <p>
                Please enter the email address you created an account in
                SafeT1st with. We will send you instructions to reset your
                password.
              </p>
            </InstructionContainer> */}

            <UserNameContainer>
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                onChange={(e) => keepUserName(e)}
                value={userName}
              />
              <ErrorContainer style={{ color: errorColor }}>
                {error}
              </ErrorContainer>
            </UserNameContainer>
            <LoginButtonContainer>
              {!submitIsLoading ? (
                <LoginButton
                  onClick={() => onClickLogin()}
                  disabled={isDisable}>
                  Submit
                </LoginButton>
              ) : (
                <LoadingLoginButton>
                  Submit
                  <Box sx={{ position: "absolute", lineHeight: "10px" }}>
                    <CircularProgress
                      variant="determinate"
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[
                            theme.palette.mode === "light" ? 200 : 800
                          ],
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                      value={100}
                    />
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "#254336"
                            : "#308fe8",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                      size={20}
                      thickness={4}
                      {...props}
                    />
                  </Box>
                </LoadingLoginButton>
              )}
            </LoginButtonContainer>
            <Links>
              <LoginLink onClick={() => onClickLiginLink()}>Login</LoginLink>
              <SignUpLink onClick={() => onClickSignUpLink()}>
                Signup
              </SignUpLink>
            </Links>
          </MainContent>
        </LoginForm>
      </LoginFormContainer>
    </Container>
  );
}

export default ForgetPassword;

const SignUpButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const SignUpButton = styled.button`
  border-color: #001a00;
  border-radius: 4px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #003300;
    color: #003300;
  }
`;

const ForgetUsernameContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  color: #001a00;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Cloud1Container = styled.div`
  position: absolute;
  top: 130px;
  right: -60px;
`;
const Cloud2Container = styled.div`
  position: absolute;
  top: 25px;
  right: -80px;
`;
const Cloud3Container = styled.div`
  position: absolute;
  top: 190px;
  left: -130px;
`;
const Cloud4Container = styled.div`
  position: absolute;
  top: -6px;
  left: -160px;
`;

const Cloud3 = styled.img`
  width: 240px;
`;
const Cloud1 = styled.img`
  width: 100px;
`;
const Cloud2 = styled.img`
  width: 200px;
`;
const Cloud4 = styled.img`
  width: 300px;
`;

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const InstructionContainer = styled.div`
  max-width: fit-content;
  margin-bottom: 20px;

  h1 {
    text-align: left;
    margin: 10px 0;
    font-size: 24px;
    color: black;
  }

  p {
    font-size: 16px;
    text-align: justify;
  }
`;

const LoginLink = styled.div`
  color: rgba(17, 34, 17, 1);
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(17, 34, 17, 1);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
const SignUpLink = styled.div`
  color: rgba(17, 34, 17, 1);
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(17, 34, 17, 1);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

const ErrorContainer = styled.div`
  font-size: 12px;
  height: 10px;
  margin: 2px 0px;
`;
