import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

function CourseInnerContent(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [lectureId, setlectureId] = useState(props.lectureId);
  const [courseId, setCourseId] = useState(props.courseId);
  const [isFinished, setIsFinished] = useState(false);
  useEffect(() => {
    if (lectureId && courseId) {
      axios
        .post("/extractUserCourseInfo", {
          courseId: courseId,
          lectureId: lectureId,
          userEmail: user.email,
        })
        .then((data) => {
          setIsFinished(data.data);
          // console.log("is finished:", data.data);
        });
    }
  }, [lectureId, courseId]);
  return (
    <Container>
      {isFinished ? (
        <FilledCircle>
          <Color />
        </FilledCircle>
      ) : (
        <EmptyCircle />
      )}
    </Container>
  );
}

export default CourseInnerContent;

const Container = styled.div`
  margin-right: 5px;
`;

const UserCourseInfoContainer = styled.div``;
const FilledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  border: 1px solid black;
  border-radius: 50%;
`;
const EmptyCircle = styled.div`
  height: 15px;
  width: 15px;
  border: 1px solid black;
  border-radius: 50%;
`;

const Color = styled.div`
  height: 11px;
  width: 11px;
  border: 1px solid #5fb05f;
  background-color: #5fb05f;
  border-radius: 50%;
`;
