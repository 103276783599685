import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "@emotion/styled";
import ShowCoursePrice from "./showCoursePrice";
// import ShowCourseName from "./showCourseName";
import ShowUserEmail from "./showUserEmail";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import Big from "big.js";

import firebaseApp from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { loadStripe } from "@stripe/stripe-js";
// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
const stripePromiseTestMode = loadStripe(
  "pk_test_51HC8ruLYpiPz9FF416QMwOiY1LJDy6oF3JqZ5YuATtIreOoh6ppEnQk5FQlhFzcpWumBZTefBvznFz595REDm48g00uktcs3u7"
);

const stripePromiseLiveMode = loadStripe(
  "pk_live_51HC8ruLYpiPz9FF4gF6Suk6enWcV7kocqHJdU1jMWm1sck1SlJCrsUftYPDDwWoZ8L6Txo9olw0l6Yv5NAZs2eDJ00KJiF3o00"
);

const stripePK = stripePromiseLiveMode;
const auth = getAuth();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: "flex",
  },
  detail: {
    width: "100%",
  },
}));

function TeamsBasket(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [teamCartItem, setTeamCartItem] = useState(
    JSON.parse(localStorage.getItem("teamCart"))
  );

  const [subTotal, setSubTotal] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [counter, setCounter] = useState(0);
  const [priceArray, setPriceArray] = useState([]);
  const [courseNameArray, setCourseNameArray] = useState([]);

  const [clientSecret, setClientSecret] = useState("");
  const [items, setItems] = useState([]);

  const onClickDeleteItem = (cartId, itemId) => {
    const db = firebaseApp.firestore();

    onAuthStateChanged(auth, (userm) => {
      if (userm) {
        db.collection("TeamsCart")
          .doc(cartId)
          .collection("Cart")
          .doc(itemId)
          .delete()
          .then(() => {
            // setSubTotal(0);
            // let tempArr = [...teamCartItem];
            // let filteredArr = tempArr.filter(
            //   (obj) => !(obj.cartId === cartId && obj.itemId === itemId)
            // );
            // setTeamCartItem(filteredArr);
            props.onClickResetItemNumber(props.currentTeamId);
          })
          .then(() => {
            setCounter(counter + 1);
          });
      }
    });
  };

  useEffect(() => {
    setTeamCartItem(JSON.parse(localStorage.getItem("teamCart")));
  }, [props.cartItemNum]);

  // useEffect(() => {
  //   if (localStorage.getItem("teamCart")) {
  //     //   const count=0;
  //     const items = JSON.parse(localStorage.getItem("teamCart"));
  //     setTeamCartItem(items);
  //     // items.forEach((eachItem)=>{
  //     //     count+=eachItem.
  //     // })
  //   }
  // }, [localStorage.getItem("teamCart")]);

  // useEffect(() => {
  //   if (currentPrice > 0) {

  //     setSubTotal(
  //       (Number.parseFloat(currentPrice) + Number.parseFloat(subTotal)).toFixed(
  //         2
  //       )
  //     );
  //     setCurrentPrice(0);
  //   }
  // }, [currentPrice]);

  useEffect(() => {
    console.log("Team Cart:", teamCartItem);
    localStorage.setItem("teamCart", JSON.stringify(teamCartItem));
    if (teamCartItem && teamCartItem.length > 0) {
      let arr = [...teamCartItem];
      let result = arr.reduce(
        (acc, obj) => {
          acc.subTotal = (
            Number.parseFloat(acc.subTotal) + Number.parseFloat(obj.total)
          ).toFixed(2);
          return acc;
        },
        { subTotal: 0 }
      );
      setSubTotal(result.subTotal);
    }
  }, [teamCartItem]);

  //   useEffect(() => {
  //     if (teamCartItem) {
  //       setCounter(counter + 1);
  //       setSubTotal(0);
  //     }
  //   }, [teamCartItem]);

  const onClickCheckOut = async () => {
    if (teamCartItem && teamCartItem.length > 0) {
      if (user && props.currentTeamId) {
        navigate("/checkout", {
          state: {
            buyerId: user.UserID,
            buyerEmail: user.email,
            teamId: props.currentTeamId,
            items: teamCartItem,
            total: subTotal,
            teamName: props.teamName,
          },
        });
      }
    }
  };

  return (
    <Container>
      {teamCartItem && teamCartItem.length > 0 ? (
        <CheckOutContainer onClick={() => onClickCheckOut()}>
          <CheckOutButton>Proceed to Payment with ${subTotal}</CheckOutButton>
        </CheckOutContainer>
      ) : (
        <div>The cart is empty.</div>
      )}
      <div>
        {teamCartItem ? (
          teamCartItem.map((item, key) => (
            // expanded={expanded === key} onChange={handleChange(key)}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                {/* what is price array? */}
                <Typography className={classes.heading} component={"span"}>
                  <ShowCoursePrice
                    courseId={item.courseId}
                    quantityProp={item.usersNotTookCourse.length}
                    cartData={teamCartItem}
                    changeTeamCartItems={setTeamCartItem}
                    //   subTotalProp={subTotal}
                    changeSubTotalProp={setCurrentPrice}
                    key={key + counter}
                    index={key}
                    changePriceArray={setPriceArray}
                    companyPriceArray={priceArray}
                  />
                </Typography>

                <Typography
                  className={classes.secondaryHeading}
                  component={"span"}>
                  <SomeText>Item added by:</SomeText>
                  {/* <ShowUserEmail userId={item.createdBy} /> */}
                  <div>{item?.createdBy}</div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.detail} component={"span"}>
                  <ShowCourseName>{item?.courseName}</ShowCourseName>
                  {/* <ShowCourseName
                    courseId={item.courseId}
                    changeCourseNameArray={setCourseNameArray}
                    courseNameArrayValue={courseNameArray}
                    index={key}
                  /> */}
                  <NumberOfUsersContainer>
                    <NumberOfUsersNotTookCourse>
                      {item.usersNotTookCourse.length} user(s) have not taken
                      the course.
                    </NumberOfUsersNotTookCourse>
                    <NumberOfUsersTookCourse>
                      {item.usersTookCourse.length} user(s) already took the
                      course.
                    </NumberOfUsersTookCourse>
                  </NumberOfUsersContainer>
                  <NoteContainer>
                    * You are charged for {item.usersNotTookCourse.length} users
                  </NoteContainer>
                  <DeleteItemContainer>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickDeleteItem(item.cartId, item.itemId);
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </DeleteItemContainer>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </Container>
  );
}

export default TeamsBasket;

const Container = styled.div`
  padding: 20px;
`;

const SomeText = styled.div`
  margin-right: 5px;
  color: black;
`;

const CheckOutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 10px 0px;
  margin-bottom: 10px;
`;
const SubTotal = styled.div``;
const CheckOutButton = styled.button`
  border: none;
  background-color: #5fb05f;
  padding: 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
`;

const NumberOfUsersNotTookCourse = styled.div``;
const NumberOfUsersTookCourse = styled.div``;
const NumberOfUsersContainer = styled.div`
  display: flex;
`;
const NoteContainer = styled.div`
  color: red;
  font-size: 10px;
`;

const DeleteItemContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const ShowCourseName = styled.div`
  font-weight: 600;
`;
