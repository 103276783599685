import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { addItems, deleteItems } from "../features/counter/counterSlice";

function CartItem(props) {
  const [color, setColor] = useState("#e8c45f");
  const dispatch = useDispatch();

  const addItemsToBasket = () => {
    const product = { props };
    dispatch(addItems(product));
  };

  const RemoveItemFromBasket = () => {
    dispatch(deleteItems(props.id));
  };

  useEffect(() => {
    // console.log("Props:", props);
  }, [props]);

  return (
    <InlineContainer>
      <div className="ribbon ribbon-top-left">
        <span>${props.price}</span>
      </div>
      <CourseTitle>{props.title}</CourseTitle>
      <CourseDetail>{props.description}</CourseDetail>
      <CourseInstructor></CourseInstructor>
      {/* <CoursePrice>{props.price}</CoursePrice> */}
      <DeleteButtonContainer onClick={() => RemoveItemFromBasket()}>
        {/* <DeleteFromBasket>Remove</DeleteFromBasket> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-circle-fill"
          viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
      </DeleteButtonContainer>
      {/* <PresentPriceContainer>
          <Quantity>Quantity:{props.quantity}</Quantity>
          <PresentPrice>{props.price}</PresentPrice>
          <PriceTag>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-tag-fill"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          </PriceTag>
        </PresentPriceContainer> */}
      <CourseColor />
    </InlineContainer>
  );
}

export default CartItem;

const Container = styled.div`
  z-index: 10;
  /* padding: 10px; */
  border: 1px solid #e6e6e6;
  background-color: #f2f2f2;
  border-radius: 5px;
  // height: 250px;
  transition: all 0.2s ease-in-out;
  position: relative;
  /* display: flex; */
  /* flex-direction: row; */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  /* &:hover {
    cursor: pointer;
    box-shadow: 8px 12px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transform: scale(1.05);
  } */
`;

const CourseDetail = styled.div`
  margin: 10px 20px 20px 20px;
  font-size: 0.8rem;
  text-align: justify;
`;

const CourseColor = styled.div`
  height: 20px;
  width: 100%;
  background-color: #e8c45f;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-radius: 0 0 5px 5px;
`;

const CourseTitle = styled.div`
  margin: 20px 20px 0px 20px;
  font-size: 0.8rem;
  text-align: justify;
  font-weight: 600;
`;

const AddToCartButton = styled.button``;
const AddToFavoriteButton = styled.button``;
const BuyForGiftButton = styled.button``;
const DetailsButton = styled.button``;

const InlineContainer = styled.div`
  padding: 40px;
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  // background: #f2f2f2;
  background: white;
  color: black;
`;

const CourseInstructor = styled.div``;
const CoursePrice = styled.div``;
const DeleteFromBasket = styled.button`
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: white;

  background-color: #3e7197;
  width: 25rem;
  &:hover {
    background-color: #345e7f;
    cursor: pointer;
  }
`;
const DeleteButtonContainer = styled.div`
  position: absolute;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  cursor: pointer;

  &:hover svg {
    fill: #f15b5d;
  }

  svg {
    fill: #f26669;
    width: 20px;
    height: 20px;
  }
`;

const PresentPriceContainer = styled.div`
  padding: 0px 40px 0px 15px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;
const PresentPrice = styled.div``;
const PriceTag = styled.div`
  position: absolute;
  right: 20px;
  padding-top: 5px;
`;
const Quantity = styled.div``;
