import React from "react";
import styled from "styled-components";

function Success() {
  return <Container>Your order is successfully placed!</Container>;
}

export default Success;

const Container = styled.div`
  margin: 90px 20px 20px 20px;
`;
