import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Team() {
  const history = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [teamsName, setTeamsName] = useState(null);
  //   const [teamName, setTeamName] = useState(null);

  const onClickTeamsName = (teamId) => {
    history("/teammembers", { teamID: teamId });
  };

  const onClickCreateATeam = () => {
    const teamName = prompt("Team Name:");
    if (teamName) {
      axios
        .post("/addTeam", { email: user.email, team: teamName })
        .then((data) => {
          // console.log(data.data);

          setTeamsName([...teamsName, data.data]);
        });
    }
  };

  useEffect(() => {
    if (user) {
      axios.post("/returnTeams", { email: user.email }).then((data) => {
        // console.log(data.data);
        setTeamsName(data.data);
      });
    }
  }, []);

  useEffect(() => {
    // console.log("New teamsName:", teamsName);
  }, [teamsName]);

  return (
    <Container>
      {/* <SubHeader/> */}
      <AddNewTeam>
        <AddNewTeamButton onClick={() => onClickCreateATeam()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-plus-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
          </svg>
        </AddNewTeamButton>
      </AddNewTeam>

      {teamsName ? (
        teamsName.map((items) => (
          <TeamContainer onClick={() => onClickTeamsName(items.teamId)}>
            {/* <TeamName>{items.teamName}</TeamName> */}
            {items.teamName}
          </TeamContainer>
        ))
      ) : (
        <div>Loading..</div>
      )}
    </Container>
  );
}

export default Team;

const Container = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`;
const AddNewTeam = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 250px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ; */
`;
const AddNewTeamButton = styled.div`
  color: #fdcc03;

  svg {
    height: 100px;
    width: 100px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    cursor: pointer;
  }
`;

const TeamContainer = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 250px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const TeamName = styled.button`
  border: none;
  outline: none;
`;
