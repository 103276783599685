import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function MyCourseBasicInfo(props) {
  const history = useNavigate();
  const [myCourseName, setMyCourseName] = useState(null);
  const [courseProgress, setCourseProgress] = useState(props.progress);
  const [courseIDProp, setCourseIDProp] = useState(props.courseID);
  const [courseState, setCourseState] = useState(null);

  useEffect(() => {
    setCourseIDProp(props.courseID);
  }, [props.courseID]);

  useEffect(() => {
    setCourseProgress(props.progress);
  }, [props.progress]);

  useEffect(() => {
    if (courseIDProp) {
      axios
        .post("/returnMyCoursesInformation", { courseId: courseIDProp })
        .then((data) => {
          setMyCourseName(data.data.name);
          setCourseState(data.data.state);
        });
    }
  }, []);

  const onClickShowCourseContent = (courseID) => {
    history("/coursecontent", { state: { courseID, myCourseName } });
  };

  return (
    <Container>
      {courseIDProp ? (
        <MyCourse onClick={() => onClickShowCourseContent(courseIDProp)}>
          {myCourseName ? (
            <CourseNameContainer>{myCourseName}</CourseNameContainer>
          ) : (
            <div></div>
          )}
          {courseState ? (
            <CourseStateContainer>{courseState}</CourseStateContainer>
          ) : (
            <div></div>
          )}
          {courseProgress != null ? (
            <CourseProgressContainer>
              <ProgressBar>
                <div
                  style={{
                    height: "10px",
                    width: `${courseProgress}%`,
                    backgroundColor: "rgba(95, 176, 95, 1)",
                    borderRadius: "50px",
                  }}
                >
                  {/* {courseProgress}% */}
                </div>
              </ProgressBar>
              {/* {courseProgress} */}
            </CourseProgressContainer>
          ) : (
            <div></div>
          )}
        </MyCourse>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default MyCourseBasicInfo;

const Container = styled.div`
  /* padding: 20px; */
`;

const MyCourse = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 250px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  cursor: pointer;
`;

const CourseNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
`;

const CourseProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
`;
const ProgressBar = styled.div`
  height: "10px";
  width: 80%;
  border: 1px solid gray;
  border-radius: 50px;
`;

const CourseStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  text-transform: capitalize;
`;
