import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Course from "./Course";
import { useLocation } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";

import img1 from "../Images/1V9A4284.jpg";
import img2 from "../Images/001163_ID61136-05A_MAUVE_01.jpg";
import img3 from "../Images/http___static.theiconic.com.au_p_dazie-1069-2803231-1.jpg";
import img4 from "../Images/image.jpg";
import img5 from "../Images/red-dress-AL-60094-a.jpg";
import map1 from "../Images/map5.png";
// import map1 from "../Images/map1.jpg";
import topBackGroundPattern from "../Images/150fa2d0.png";

function Landing(props) {
  const { height, width } = useScrollbarSize();
  const location = useLocation();
  const [testState, setTestState] = useState(null);
  const sizeRef = useRef();

  const classAllScrollLocation = useRef();
  const classAScrollLocation = useRef();
  const classBScrollLocation = useRef();
  const classCScrollLocation = useRef();

  const classAllLeftButton = useRef();
  const classAllRightButton = useRef();

  const classALeftButton = useRef();
  const classARightButton = useRef();

  const classBLeftButton = useRef();
  const classBRightButton = useRef();

  const classCLeftButton = useRef();
  const classCRightButton = useRef();

  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [imageGap, setImageGap] = useState(5);
  const [decimal, setDecimal] = useState(0);

  const [leftLocation, setLeftLocation] = useState();

  const getListSize = () => {
    const newWidth = sizeRef?.current?.clientWidth;

    setImageWidth((newWidth - imageGap * 4) / 5);
    // console.log("Pahnaye Kol:", newWidth);

    const newHeight = sizeRef.current.clientHeight;
    setImageHeight(newHeight);
  };

  useEffect(() => {
    axios.get("/extractCourses").then((data) => {
      setTestState(data.data);
    });
    window.addEventListener("resize", getListSize);
    // console.log("Outer div width:", sizeRef.current.offsetWidth);

    // if (sizeRef?.current?.offsetWidth) {
    //   const width = sizeRef.current.offsetWidth;
    //   const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    //   setImageWidth((width - scrollbarWidth) / 5);
    //   console.log("Outer div width:", scrollbarWidth);
    // }
    return () => {
      setTestState(null);
    };
  }, []);

  useEffect(() => {
    if (testState) {
      // console.log("The test state:", testState);
    }
  }, [testState]);

  useEffect(() => {
    // if (window.innerWidth - document.body.clientWidth > 0) {
    const width = sizeRef.current.offsetWidth;
    const scrollbarWidth = window.innerWidth - document?.body?.clientWidth;
    setImageWidth((width - imageGap * 4) / 5);
    // console.log("There you go:", (width - imageGap * 4) / 5);
    // }

    //Slider buttons display control

    if (
      classAllScrollLocation.current.scrollLeft -
        classAllScrollLocation.current.offsetWidth <=
      -classAllScrollLocation.current.offsetWidth
    ) {
      classAllLeftButton.current.style.display = "none";
    } else {
      classAllLeftButton.current.style.display = "flex";
    }

    if (
      classAllScrollLocation.current.scrollLeft +
        classAllScrollLocation.current.offsetWidth >=
      classAllScrollLocation.current.scrollWidth
    ) {
      classAllRightButton.current.style.display = "none";
    } else {
      classAllRightButton.current.style.display = "flex";
    }

    // ======================================================

    if (
      classAScrollLocation.current.scrollLeft -
        classAScrollLocation.current.offsetWidth <=
      -classAScrollLocation.current.offsetWidth
    ) {
      classALeftButton.current.style.display = "none";
    } else {
      classALeftButton.current.style.display = "flex";
    }

    if (
      classAScrollLocation.current.scrollLeft +
        classAScrollLocation.current.offsetWidth >=
      classAScrollLocation.current.scrollWidth
    ) {
      classARightButton.current.style.display = "none";
    } else {
      classARightButton.current.style.display = "flex";
    }

    //===================================================
    if (
      classBScrollLocation.current.scrollLeft -
        classBScrollLocation.current.offsetWidth <=
      -classBScrollLocation.current.offsetWidth
    ) {
      classBLeftButton.current.style.display = "none";
    } else {
      classBLeftButton.current.style.display = "flex";
    }

    if (
      classBScrollLocation.current.scrollLeft +
        classBScrollLocation.current.offsetWidth >=
      classBScrollLocation.current.scrollWidth
    ) {
      classBRightButton.current.style.display = "none";
    } else {
      classBRightButton.current.style.display = "flex";
    }
    //===================================================
    if (
      classCScrollLocation.current.scrollLeft -
        classCScrollLocation.current.offsetWidth <=
      -classCScrollLocation.current.offsetWidth
    ) {
      classCLeftButton.current.style.display = "none";
    } else {
      classCLeftButton.current.style.display = "flex";
    }

    if (
      classCScrollLocation.current.scrollLeft +
        classCScrollLocation.current.offsetWidth >=
      classCScrollLocation.current.scrollWidth
    ) {
      classCRightButton.current.style.display = "none";
    } else {
      classCRightButton.current.style.display = "flex";
    }
  });

  // useEffect(() => {
  //   if (secondLocation?.current) {
  //     console.log("Left", secondLocation.current);
  //   }
  // }, [secondLocation]);

  useEffect(() => {
    // console.log("Image width:", imageWidth);
  }, [imageWidth]);

  // useEffect(() => {
  //   let states = JSON.parse(localStorage.getItem("states"));
  //   if (states == "" || states == null) {
  //     if (testState) {
  //       let newArray = [...testState];
  //       let anotherArray = [];
  //       newArray.forEach((item) => {
  //         anotherArray.push(item.state);
  //       });
  //       let noDuplicate = [...new Set(anotherArray)];
  //       if (!localStorage.getItem("states")) {
  //         localStorage.setItem("states", JSON.stringify(noDuplicate));
  //       }

  //       props.changeState(noDuplicate);
  //       // console.log("the outputt:", noDuplicate);
  //     }
  //   }
  // }, [testState]);

  // useEffect(() => {
  //   // console.log("Search key:", props.passSearchKey);
  //   // console.log("State:", props.useStateName);
  //   if (props.passSearchKey) {
  //     if (
  //       props.useStateName &&
  //       props.useStateName != "All" &&
  //       props.passSearchKey &&
  //       props.passSearchKey.trim() != ""
  //     ) {
  //       axios
  //         .post("/extractCoursesWithStateSearch", {
  //           state: props.useStateName,
  //           search: props.passSearchKey,
  //         })
  //         .then((data) => {
  //           setTestState(data.data);
  //           // console.log(data.data);
  //         });
  //     } else if (
  //       (props.useStateName &&
  //         props.useStateName == "All" &&
  //         props.passSearchKey &&
  //         props.passSearchKey.trim() != "") ||
  //       (props.passSearchKey &&
  //         !props.useStateName &&
  //         props.passSearchKey.trim() != "")
  //     ) {
  //       axios
  //         .post("/extractCoursesWithAllStateSearch", {
  //           state: props.useStateName,
  //           search: props.passSearchKey,
  //         })
  //         .then((data) => {
  //           setTestState(data.data);
  //           // console.log(data.data);
  //         });
  //     } else if (props.useStateName) {
  //       axios
  //         .post("/extractCoursesWithState", { state: props.useStateName })
  //         .then((data) => {
  //           setTestState(data.data);
  //           // console.log(data.data);
  //         });
  //     }
  //   } else if (
  //     (props.useStateName == "All" && !props.passSearchKey) ||
  //     (props.useStateName == "All" && props.passSearchKey.trim() == "")
  //   ) {
  //     axios.get("/extractCourses").then((data) => {
  //       setTestState(data.data);
  //     });
  //   }
  // }, [props.passSearchKey, props.useStateName]);

  // useEffect(() => {
  //   // console.log("Reset key:", props.keyReset);
  // }, [props.keyReset]);

  const onClickRightButtonAll = () => {
    classAllLeftButton.current.style.display = "flex";
    let tempWidth = classAllScrollLocation.current.scrollLeft;
    let tempElementWidth = classAllScrollLocation.current.offsetWidth;
    tempWidth += tempElementWidth + imageGap;
    classAllScrollLocation.current.scrollLeft = tempWidth;
    if (
      tempWidth + tempElementWidth >=
      classAllScrollLocation.current.scrollWidth
    )
      classAllRightButton.current.style.display = "none";
  };

  const onClickLeftButtonAll = () => {
    classAllRightButton.current.style.display = "flex";
    let tempWidth = classAllScrollLocation.current.scrollLeft;
    let tempElementWidth = classAllScrollLocation.current.offsetWidth;
    tempWidth -= tempElementWidth + imageGap;
    classAllScrollLocation.current.scrollLeft = tempWidth;

    if (tempWidth - tempElementWidth <= -tempElementWidth)
      classAllLeftButton.current.style.display = "none";
  };

  const onClickRightButtonA = () => {
    classALeftButton.current.style.display = "flex";
    let tempWidth = classAScrollLocation.current.scrollLeft;
    let tempElementWidth = classAScrollLocation.current.offsetWidth;
    tempWidth += tempElementWidth + imageGap;
    // tempWidth = tempWidth + Math.ceil(imageWidth * 2 + imageGap * 2);
    // console.log("Div width:", classAScrollLocation.current.offsetWidth);
    // console.log("where the scroll is:", tempWidth);
    // tempWidth = tempWidth + (imageWidth * 2 + imageGap * 2) + decimal;
    // let decCount = decimalCount(tempWidth);
    // let numFloor = Math.floor(tempWidth);
    // let noDecimal = tempWidth * Math.pow(10, decCount);
    // numFloor = numFloor * Math.pow(10, decCount);
    // let finalDec = (noDecimal - numFloor) / Math.pow(10, decCount);
    classAScrollLocation.current.scrollLeft = tempWidth;
    // console.log(classAScrollLocation.current.scrollWidth);

    // console.log(tempWidth);
    if (
      tempWidth + tempElementWidth >=
      classAScrollLocation.current.scrollWidth
    )
      classARightButton.current.style.display = "none";
    // console.log("Calculation:", tempWidth - Math.floor(tempWidth));
    // console.log("Correct Decimal:", finalDec);
    // console.log("image width:", imageWidth);
    // console.log("image gap:", imageGap);
    // console.log("fee:", tempWidth);

    // let tempWidth = classAScrollLocation.current.scrollLeft;
    // tempWidth = tempWidth + 829 + 5;
    // classAScrollLocation.current.scrollLeft = tempWidth;
  };

  const onClickLeftButtonA = () => {
    classARightButton.current.style.display = "flex";
    let tempWidth = classAScrollLocation.current.scrollLeft;
    let tempElementWidth = classAScrollLocation.current.offsetWidth;
    tempWidth -= tempElementWidth + imageGap;
    classAScrollLocation.current.scrollLeft = tempWidth;

    if (tempWidth - tempElementWidth <= -tempElementWidth)
      classALeftButton.current.style.display = "none";
  };

  const onClickRightButtonB = () => {
    // let tempWidth = classBScrollLocation.current.scrollLeft;
    // let tempElementWidth = classBScrollLocation.current.offsetWidth;
    // tempWidth += tempElementWidth + imageGap;
    // classBScrollLocation.current.scrollLeft = tempWidth;

    classBLeftButton.current.style.display = "flex";
    let tempWidth = classBScrollLocation.current.scrollLeft;
    let tempElementWidth = classBScrollLocation.current.offsetWidth;
    tempWidth += tempElementWidth + imageGap;
    classBScrollLocation.current.scrollLeft = tempWidth;
    if (
      tempWidth + tempElementWidth >=
      classBScrollLocation.current.scrollWidth
    )
      classBRightButton.current.style.display = "none";
  };

  const onClickLeftButtonB = () => {
    // let tempWidth = classBScrollLocation.current.scrollLeft;
    // let tempElementWidth = classBScrollLocation.current.offsetWidth;
    // tempWidth -= tempElementWidth + imageGap;
    // classBScrollLocation.current.scrollLeft = tempWidth;

    classBRightButton.current.style.display = "flex";
    let tempWidth = classBScrollLocation.current.scrollLeft;
    let tempElementWidth = classBScrollLocation.current.offsetWidth;
    tempWidth -= tempElementWidth + imageGap;
    classBScrollLocation.current.scrollLeft = tempWidth;

    if (tempWidth - tempElementWidth <= -tempElementWidth)
      classBLeftButton.current.style.display = "none";
  };

  const onClickRightButtonC = () => {
    // let tempWidth = classCScrollLocation.current.scrollLeft;
    // let tempElementWidth = classCScrollLocation.current.offsetWidth;
    // tempWidth += tempElementWidth + imageGap;
    // classCScrollLocation.current.scrollLeft = tempWidth;

    classCLeftButton.current.style.display = "flex";
    let tempWidth = classCScrollLocation.current.scrollLeft;
    let tempElementWidth = classCScrollLocation.current.offsetWidth;
    tempWidth += tempElementWidth + imageGap;
    classCScrollLocation.current.scrollLeft = tempWidth;
    if (
      tempWidth + tempElementWidth >=
      classCScrollLocation.current.scrollWidth
    )
      classCRightButton.current.style.display = "none";
  };

  const onClickLeftButtonC = () => {
    // let tempWidth = classCScrollLocation.current.scrollLeft;
    // let tempElementWidth = classCScrollLocation.current.offsetWidth;
    // tempWidth -= tempElementWidth + imageGap;
    // classCScrollLocation.current.scrollLeft = tempWidth;

    classCRightButton.current.style.display = "flex";
    let tempWidth = classCScrollLocation.current.scrollLeft;
    let tempElementWidth = classCScrollLocation.current.offsetWidth;
    tempWidth -= tempElementWidth + imageGap;
    classCScrollLocation.current.scrollLeft = tempWidth;

    if (tempWidth - tempElementWidth <= -tempElementWidth)
      classCLeftButton.current.style.display = "none";
  };

  const decimalCount = (num) => {
    const numStr = String(num);
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    return 0;
  };

  return (
    <Container>
      {/* <ComingSoon></ComingSoon> */}
      {/* <NewsSectionContainer>
        <MapBorder>
          <img
            src={map1}
            style={{
              width: "70%",
              maxWidth: "700px",
              zIndex: "1",
              filter: "drop-shadow(0px 5px 10px #666666)",
            }}
          />
          <BackgroundPattern />
        </MapBorder>
      </NewsSectionContainer> */}
      <Space />
      <CourseOuterContainer>
        <CourseContainer ref={sizeRef}>
          <CoursesCategory>Petroleum Courses</CoursesCategory>
          <ButtonsContainer>
            <LeftButton
              onClick={() => onClickLeftButtonAll()}
              ref={classAllLeftButton}
            >
              {"<"}
            </LeftButton>
            <RightButton
              onClick={() => onClickRightButtonAll()}
              ref={classAllRightButton}
            >
              {">"}
            </RightButton>

            <OuterSliderContainer ref={classAllScrollLocation}>
              <SliderContainer>
                <ClassSlider style={{ gap: imageGap }}>
                  {testState ? (
                    testState.map(
                      (subData, key) =>
                        location.state?.users && location.state?.teamId ? (
                          // subData.name.toLowerCase().indexOf("class a") >= 0 ? (
                          <Course
                            // style={{ width: imageWidth }}
                            id={subData.id}
                            title={subData.name}
                            description={subData.description}
                            price={subData.price}
                            state={subData.state}
                            stImg={subData.stateImg}
                            crsImg={subData.courseImg}
                            stColor={subData.stateColor}
                            teamId={location.state.teamId}
                            users={location.state.users}
                            containerWidth={imageWidth}
                            isCourseAvailable={subData.courseAvailability}
                            promoCodeId={subData.promoCode}
                            stripePriceId={subData.stripePrice}
                          />
                        ) : (
                          // ) : (
                          //   <div style={{ display: "none" }} />
                          // )
                          // subData.name.toLowerCase().indexOf("class a") >= 0 ? (
                          <Course
                            id={subData.id}
                            title={subData.name}
                            description={subData.description}
                            price={subData.price}
                            state={subData.state}
                            stImg={subData.stateImg}
                            crsImg={subData.courseImg}
                            stColor={subData.stateColor}
                            containerWidth={imageWidth}
                            isCourseAvailable={subData.courseAvailability}
                            promoCodeId={subData.promoCode}
                            stripePriceId={subData.stripePrice}
                          />
                          // <div></div>
                        )
                      // ) : (
                      //   <div style={{ display: "none" }} />
                      // )
                    )
                  ) : (
                    <div>loading...</div>
                  )}
                </ClassSlider>
              </SliderContainer>
            </OuterSliderContainer>
          </ButtonsContainer>
          {/* ============================================================================== */}

          <CoursesCategory>Petroleum Class A Operator</CoursesCategory>
          <ButtonsContainer>
            <LeftButton
              onClick={() => onClickLeftButtonA()}
              ref={classALeftButton}
            >
              {"<"}
            </LeftButton>
            <RightButton
              onClick={() => onClickRightButtonA()}
              ref={classARightButton}
            >
              {">"}
            </RightButton>

            <OuterSliderContainer ref={classAScrollLocation}>
              <SliderContainer>
                <ClassSlider style={{ gap: imageGap }}>
                  {testState ? (
                    testState.map((subData, key) =>
                      location.state?.users && location.state?.teamId ? (
                        subData.name.toLowerCase().indexOf("class a") >= 0 ? (
                          <Course
                            // style={{ width: imageWidth }}
                            id={subData.id}
                            title={subData.name}
                            description={subData.description}
                            price={subData.price}
                            state={subData.state}
                            stImg={subData.stateImg}
                            crsImg={subData.courseImg}
                            stColor={subData.stateColor}
                            teamId={location.state.teamId}
                            users={location.state.users}
                            containerWidth={imageWidth}
                            isCourseAvailable={subData.courseAvailability}
                            promoCodeId={subData.promoCode}
                            stripePriceId={subData.stripePrice}
                          />
                        ) : (
                          <div style={{ display: "none" }} />
                        )
                      ) : subData.name.toLowerCase().indexOf("class a") >= 0 ? (
                        <Course
                          id={subData.id}
                          title={subData.name}
                          description={subData.description}
                          price={subData.price}
                          state={subData.state}
                          stImg={subData.stateImg}
                          crsImg={subData.courseImg}
                          stColor={subData.stateColor}
                          containerWidth={imageWidth}
                          isCourseAvailable={subData.courseAvailability}
                          promoCodeId={subData.promoCode}
                          stripePriceId={subData.stripePrice}
                        />
                      ) : (
                        // <div></div>
                        // )
                        <div style={{ display: "none" }} />
                      )
                    )
                  ) : (
                    <div>loading...</div>
                  )}
                </ClassSlider>
              </SliderContainer>
            </OuterSliderContainer>
          </ButtonsContainer>

          {/* =============================================================================== */}

          <CoursesCategory>Petroleum Class B Operator</CoursesCategory>
          <ButtonsContainer>
            <LeftButton
              onClick={() => onClickLeftButtonB()}
              ref={classBLeftButton}
            >
              {"<"}
            </LeftButton>
            <RightButton
              onClick={() => onClickRightButtonB()}
              ref={classBRightButton}
            >
              {">"}
            </RightButton>

            <OuterSliderContainer ref={classBScrollLocation}>
              <SliderContainer>
                <ClassSlider style={{ gap: imageGap }}>
                  {testState ? (
                    testState.map((subData, key) =>
                      location.state?.users && location.state?.teamId ? (
                        subData.name.toLowerCase().indexOf("class b") >= 0 ? (
                          <Course
                            // style={{ width: imageWidth }}
                            id={subData.id}
                            title={subData.name}
                            description={subData.description}
                            price={subData.price}
                            state={subData.state}
                            stImg={subData.stateImg}
                            crsImg={subData.courseImg}
                            stColor={subData.stateColor}
                            teamId={location.state.teamId}
                            users={location.state.users}
                            containerWidth={imageWidth}
                            isCourseAvailable={subData.courseAvailability}
                            promoCodeId={subData.promoCode}
                            stripePriceId={subData.stripePrice}
                          />
                        ) : (
                          <div style={{ display: "none" }} />
                        )
                      ) : subData.name.toLowerCase().indexOf("class b") >= 0 ? (
                        <Course
                          id={subData.id}
                          title={subData.name}
                          description={subData.description}
                          price={subData.price}
                          state={subData.state}
                          stImg={subData.stateImg}
                          crsImg={subData.courseImg}
                          stColor={subData.stateColor}
                          containerWidth={imageWidth}
                          isCourseAvailable={subData.courseAvailability}
                          promoCodeId={subData.promoCode}
                          stripePriceId={subData.stripePrice}
                        />
                      ) : (
                        <div style={{ display: "none" }} />
                      )
                    )
                  ) : (
                    <div>loading...</div>
                  )}
                </ClassSlider>
              </SliderContainer>
            </OuterSliderContainer>
          </ButtonsContainer>

          <CoursesCategory>Petroleum Class C</CoursesCategory>
          <ButtonsContainer>
            <LeftButton
              onClick={() => onClickLeftButtonC()}
              ref={classCLeftButton}
            >
              {"<"}
            </LeftButton>
            <RightButton
              onClick={() => onClickRightButtonC()}
              ref={classCRightButton}
            >
              {">"}
            </RightButton>

            <OuterSliderContainer ref={classCScrollLocation}>
              <SliderContainer>
                <ClassSlider style={{ gap: imageGap }}>
                  {testState ? (
                    testState.map((subData, key) =>
                      location.state?.users && location.state?.teamId ? (
                        subData.name.toLowerCase().indexOf("class c") >= 0 ? (
                          <Course
                            // style={{ width: imageWidth }}
                            id={subData.id}
                            title={subData.name}
                            description={subData.description}
                            price={subData.price}
                            state={subData.state}
                            stImg={subData.stateImg}
                            crsImg={subData.courseImg}
                            stColor={subData.stateColor}
                            teamId={location.state.teamId}
                            users={location.state.users}
                            containerWidth={imageWidth}
                            isCourseAvailable={subData.courseAvailability}
                            promoCodeId={subData.promoCode}
                            stripePriceId={subData.stripePrice}
                          />
                        ) : (
                          <div style={{ display: "none" }} />
                        )
                      ) : subData.name.toLowerCase().indexOf("class c") >= 0 ? (
                        <Course
                          id={subData.id}
                          title={subData.name}
                          description={subData.description}
                          price={subData.price}
                          state={subData.state}
                          stImg={subData.stateImg}
                          crsImg={subData.courseImg}
                          stColor={subData.stateColor}
                          containerWidth={imageWidth}
                          isCourseAvailable={subData.courseAvailability}
                          promoCodeId={subData.promoCode}
                          stripePriceId={subData.stripePrice}
                        />
                      ) : (
                        <div style={{ display: "none" }} />
                      )
                    )
                  ) : (
                    <div>loading...</div>
                  )}
                </ClassSlider>
              </SliderContainer>
            </OuterSliderContainer>
          </ButtonsContainer>
          {/* <Courses key={props.keyReset}>
          {testState ? (
            testState.map((subData, key) =>
              location.state?.users && location.state?.teamId ? (
                <Course
                  // style={{ width: imageWidth }}
                  id={subData.id}
                  title={subData.name}
                  description={subData.description}
                  price={subData.price}
                  state={subData.state}
                  teamId={location.state.teamId}
                  users={location.state.users}
                  containerWidth={imageWidth}
                />
              ) : (
                <Course
                  id={subData.id}
                  title={subData.name}
                  description={subData.description}
                  price={subData.price}
                  state={subData.state}
                  containerWidth={imageWidth}
                />
              )
            )
          ) : (
            <div>loading...</div>
          )}
        </Courses> */}
        </CourseContainer>
      </CourseOuterContainer>
    </Container>
  );
}

export default Landing;

const CourseOuterContainer = styled.div`
  padding: 60px;
`;

const Space = styled.div`
  padding-top: 70px;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23bbbbbb' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E"); */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23b8e1f0' fill-opacity='0.21' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E"); */

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23b8dcf0' fill-opacity='0.61' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
`;
const OuterSliderContainer = styled.div`
  scroll-behavior: smooth;
  position: relative;
  height: 270px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const NewsSectionContainer = styled.div`
  /* position: relative; */
  box-sizing: border-box;
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  background-color: white;
  /* background-color: #eee4c9; */
  /* background-color: rgba(248, 233, 205, 0.8);
  background-image: url(${topBackGroundPattern}); */
`;
const ButtonsContainer = styled.div`
  position: relative;
`;

const LeftButton = styled.div`
  cursor: pointer;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 20%);

  position: absolute;
  left: -15px;
  height: 31px;
  width: 30px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  top: 45%;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 50%);
    transform: scale(1.05);
  }
`;
const RightButton = styled.div`
  cursor: pointer;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 20%);

  position: absolute;
  right: -15px;
  height: 31px;
  width: 30px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  top: 45%;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 50%);
    transform: scale(1.05);
  }
`;
const SliderContainer = styled.div`
  position: absolute;
  /* overflow-x: auto;
  overflow-y: hidden; */
`;

const ClassSlider = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  /* gap: 5px; */

  /* div { */
  /* flex: 0 0 19.7%; */
  /* margin: 0px 5px; */
  /* position: absolute; */
  /* height: 270px;
    border-radius: 5px;
  } */

  /* img { */
  /* height: fit-content; */
  /* border-radius: 5px;
    object-fit: cover;
    height: 100%;
    width: 100%;
  } */
`;

const Container = styled.div`
  /* padding: 60px; */
  background-color: #f2f2f2;
`;
const ComingSoon = styled.div``;
const CourseContainer = styled.div`
  /* box-sizing: border-box;
  padding: 60px; */
`;
const Courses = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: 5rem;
  padding: 5rem;
`;

const CoursesCategory = styled.div`
  /* padding-bottom: 35px; */
  margin: 5px 0 10px 0;
  font-weight: 600;
  font-size: 1.2rem;
`;

const MapBorder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
