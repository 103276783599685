import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addItems } from "../features/counter/counterSlice";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { style } from "@mui/system";
import { keyframes } from "styled-components";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SellRoundedIcon from "@mui/icons-material/SellRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function Course(props) {
  const history = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [color, setColor] = useState("#e8c45f");
  const dispatch = useDispatch();
  const location = useLocation();

  const [propsWidth, setPropsWidth] = useState(props.containerWidth);

  const [addToCartPageIndex, setAddToCartPageIndex] = useState("-1");
  const [isCourseTaken, setIsCourseTaken] = useState(null);
  const [isCourseChecked, setIsCourseChecked] = useState(false);

  const addItemsToBasket = () => {
    if (user?.email) {
      // const product = { props };
      const product = {
        courseImg: props.crsImg,
        description: props.description,
        id: props.id,
        name: props.title,
        price: props.price,
        state: props.state,
        stateColor: props.stColor,
        stateImg: props.stImg,
        stripePromo: props.promoCodeId,
        stripePrice: props.stripePriceId,
      };
      dispatch(addItems(product));
    } else {
      history("/login");
    }
    ///
  };

  const onClickSelectCourse = () => {
    if (location.state) {
      // console.log("Users:", props.users);
      // console.log("team Id:", props.teamId);
      axios
        .post("/inviteToShare", {
          emails: props.users,
          teamId: props.teamId,
          courseId: props.id,
          fullName: user.fullName,
        })
        .then(() => {
          history("/team", {
            state: { clickStatus: "registration", teamId: props.teamId },
          });
        });
    }
  };

  const onMouseOverShowUpAddToCart = (event) => {
    event.preventDefault();
    if (user?.email && isCourseTaken == null) {
      // console.log("on mouse over");
      axios
        .post("/haveUserTakenCourse", {
          courseID: props.id,
          userID: user.email,
        })
        .then((data) => {
          // console.log(data.data);
          setIsCourseTaken(data.data);
        });
    }
  };

  const onClickGoToCourse = () => {
    history("/mycourses");
  };

  return (
    <Container style={{ width: props.containerWidth }}>
      <MultiButton>
        <AddToCartButton
          style={{ background: `${color}` }}
          onClick={addItemsToBasket}
          title="Add to cart"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-cart-plus-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
          </svg>
        </AddToCartButton>
        <AddToFavoriteButton
          style={{ background: `${color}` }}
          title="Add to favorite"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-heart-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
            />
          </svg>
        </AddToFavoriteButton>
        <DetailsButton style={{ background: `${color}` }} title="Read more">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chat-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" />
          </svg>
        </DetailsButton>
        <CustomerService
          style={{ background: `${color}` }}
          title="Customer Service"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-send-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338 7.494-7.494Z"
            />
          </svg>
        </CustomerService>
      </MultiButton>

      <InlineContainer onClick={() => onClickSelectCourse()}>
        {/* <CourseColor
        style={{
          backgroundColor:
            props.state == "MI"
              ? "#EF9771"
              : props.state == "PA"
              ? "#F8D7A8"
              : props.state == "DC"
              ? "#7B583D"
              : props.state == "IL"
              ? "#74D4CF"
              : props.state == "WI"
              ? "#E1DAA0"
              : "#FFDB15",
        }}
        >
          {props.state}
        </CourseColor> */}
        {/* <div className="ribbon ribbon-top-left">
          <span>${props.price}</span>
        </div> */}
        {/* <CourseTitle>{props.title}</CourseTitle> */}
        {/* <CourseDetail>{props.description}</CourseDetail> */}
        {/* <CourseInstructor></CourseInstructor> */}
        {/* <CoursePrice></CoursePrice> */}
        {/* <PriceAbsolContainer>
          <PriceRelativeContainer>
            <PriceTag>${props.price}</PriceTag>
          </PriceRelativeContainer>
        </PriceAbsolContainer> */}
        <TopMenu>
          <PriceIconButtonContainer>
            <IconButton style={{ zIndex: "2" }}>
              <SellRoundedIcon />
            </IconButton>
            <PriceRelativeContainer>${props.price}</PriceRelativeContainer>
          </PriceIconButtonContainer>
          <IconButtonContainer
            style={{ zIndex: "2" }}
            className="test_it"
            onMouseEnter={(e) => onMouseOverShowUpAddToCart(e)}
          >
            <IconButton
              aria-label="add to shopping cart"
              style={{ zIndex: "2" }}
            >
              <MoreHorizIcon />
            </IconButton>
            <AddToCartPage>
              {/* <PriceAbsolContainer> */}
              {/* <PriceRelativeContainer>${props.price}</PriceRelativeContainer> */}
              {/* </PriceAbsolContainer> */}

              {/* <AddShoppingCartIcon /> Add to cart */}
              {isCourseTaken ? (
                <AddToCartButton
                  style={{ backgroundColor: "white" }}
                  onClick={onClickGoToCourse}
                  // title="Add to cart"
                >
                  <LockOpenIcon /> Go to course
                </AddToCartButton>
              ) : props.isCourseAvailable ? (
                <AddToCartButton
                  style={{ backgroundColor: "white" }}
                  onClick={addItemsToBasket}
                  // title="Add to cart"
                >
                  <AddShoppingCartIcon /> Add to cart
                </AddToCartButton>
              ) : (
                <ComingSoonButton
                  style={{ backgroundColor: "white", curson: "default" }}

                  // title="Add to cart"
                >
                  <AccessTimeIcon /> Coming Soon
                </ComingSoonButton>
              )}
            </AddToCartPage>
          </IconButtonContainer>
        </TopMenu>
        <BottomContent>
          <StateImageContainer>
            <StateImage>
              <img src={props.crsImg} style={{ height: "100px" }} />
            </StateImage>
            <StateColor style={{ backgroundColor: props.stColor }}>
              {props.state}
            </StateColor>
          </StateImageContainer>
          {/* <CourseTitle>{props.title}</CourseTitle> */}
          <CourseDetail>{props.description}</CourseDetail>
        </BottomContent>
      </InlineContainer>
      {/* <AddToCartPage></AddToCartPage> */}
      {/* style={{ zIndex: addToCartPageIndex }} */}
    </Container>
  );
}

export default Course;

const AddToCartAnimation = keyframes`
 0% { transform: scale(1); opacity: 1;}
 /* 30% { height: 400px; width: 400px; opacity: 1 }*/
 50% { transform: scale(1.5); opacity: 0; } 
 100% { transform: scale(1); opacity: 0; }
`;

const BottomContent = styled.div`
  position: relative;
`;

const MultiButton = styled.div`
  z-index: 0;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: 100%;
  width: 0rem;
  height: 0rem;
  transform: translate(-50%, -50%);
  transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
  button {
    display: grid;
    place-items: center;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: none;
    border-radius: 100%;
    background: #e8c45f;
    color: white;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
    box-shadow: 0 0 0rem -0.25rem #e8c45f;
    &:hover {
      background: #e8c45f;
      color: white;
      box-shadow: 0 0 1rem -0.25rem #e8c45f;
    }
    &:first-child:nth-last-child(1) {
      left: 25%;
      top: 25%;
    }
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ * {
      &:nth-child(1) {
        left: 37.5%;
        top: 18.75%;
      }
      &:nth-child(2) {
        left: 18.75%;
        top: 37.5%;
      }
    }
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ * {
      &:nth-child(1) {
        left: 50%;
        top: 15.625%;
      }
      &:nth-child(2) {
        left: 25%;
        top: 25%;
      }
      &:nth-child(3) {
        left: 15.625%;
        top: 50%;
      }
    }
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ * {
      &:nth-child(1) {
        left: 62.5%;
        top: 18.75%;
      }
      &:nth-child(2) {
        left: 37.5%;
        top: 18.75%;
      }
      &:nth-child(3) {
        left: 18.75%;
        top: 37.5%;
      }
      &:nth-child(4) {
        left: 18.75%;
        top: 62.5%;
      }
    }
  }
`;

const CourseDetail = styled.div`
  margin: 10px 20px 20px 20px;
  font-size: 0.8rem;
  text-align: justify;
`;

const CourseColor = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 20px; */
  height: 100%;
  width: 100%;
  /* background-color: #e8c45f; */
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  border-radius: 0 0 5px 5px;
`;

const CourseTitle = styled.div`
  margin-left: 20px;
  margin: 20px 20px 0px 20px;
  font-size: 0.8rem;
  text-align: justify;
  font-weight: 600;
`;

const AddToFavoriteButton = styled.button``;
const DetailsButton = styled.button``;
const CustomerService = styled.button``;

const InlineContainer = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;

  /* background: #f2f2f2; */
  background: white;
  color: black;
`;

const CourseInstructor = styled.div``;
const CoursePrice = styled.div``;

const PriceTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 30px;
  background-color: #f26669;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  border-left: 1px solid #f26669;
  margin-left: 19px;
  position: absolute;
  right: -30px;
  top: 35px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  padding: 0 10px 0 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(70deg);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  &:after {
    content: "";
    background-color: white;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 13px;
    /* background: #fff; */
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    left: -19px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 20px solid #f26669;
  }
`;

const PriceAbsolContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;

const PriceRelativeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  height: 40px;
  opacity: 0;
  /* padding: 5px;
  background-color: #f26669;
  border-radius: 5px;
  margin-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  /* position: relative; */
`;

const TopMenu = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceIconButtonContainer = styled.div`
  position: relative;

  &:hover {
    ${PriceRelativeContainer} {
      left: 35px;
      opacity: 1;
    }
  }
`;

const breatheAnimation = keyframes`
 0% { transform: scale(1); opacity: 1;}
 /* 30% { height: 400px; width: 400px; opacity: 1 }*/
 50% { transform: scale(1.5); opacity: 0; } 
 100% { transform: scale(1); opacity: 0; }
`;

const testAnimation = keyframes`
0% { transform: opacity: 0 !important; height:0% !important;}
100% { transform: opacity: 1 !important; height:100% !important;}
`;

const StateColor = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0;
  display: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
`;

const StateImage = styled.div`
  position: absolute;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  /* display: block; */
`;

const StateImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
`;

const Container = styled.div`
  /* z-index: 10; */
  /* padding: 10px; */
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  /* background-color: #f2f2f2; */
  background-color: white;
  border-radius: 5px;
  height: 250px;
  transition: all 0.2s ease-in-out;
  position: relative;
  /* display: flex; */
  /* flex-direction: row; */
  /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); */
  &:hover {
    cursor: context-menu;
    ${StateImage} {
      transform: scale(1.5);
      opacity: 0;
      /* animation-name: ${breatheAnimation};
        animation-duration: 1s;
        animation-iteration-count: 1; */
      /* display: none; */
    }

    ${StateColor} {
      opacity: 1;
    }
    /* box-shadow: 8px 12px 16px 0px rgba(0, 0, 0, 0.1); */
    /* border-radius: 5px; */

    /* transform: scale(1.05); */
  }

  /* &:hover ${MultiButton} {
    
    width: 10rem;
    height: 10rem;
  } */
`;

const AddToCartPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  height: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  /* transition: all 0.4s ease-in-out; */
  transition: opacity 0.8s ease-in-out, height 0.2s ease-in-out;
  opacity: 0;
  z-index: 1;
  border-radius: 4px;

  /* &:hover {
    opacity: 1;
    z-index:1;
  } */
`;

const AddToCartButton = styled.div`
  display: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: rgba(95, 176, 95, 0.9) !important;
  }
`;

const ComingSoonButton = styled.div`
  display: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
`;

const IconButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: white;
  &:hover {
    ${AddToCartPage} {
      /* cursor: pointer; */
      /* { */
      /* z-index: 1 !important;
        position: absolute !important; */
      /* width: 100% !important;
        background-color: black !important; */
      opacity: 1 !important;
      /* transform: scale(1); */
      height: 100% !important;
      /* animation-name: ${testAnimation};
      animation-duration: 1s;
      animation-iteration-count: 1; */
    }

    ${AddToCartButton} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${ComingSoonButton} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
