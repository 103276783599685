import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import ShowTeamName from "../pages/showTeamName";
import axios from "axios";

const columns = [
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "select", label: "Select User" }, //, minWidth: 100
];

function TeamUserTransfer(props) {
  const history = useNavigate();
  //   const [recordProps, setRecordProps] = useState(props.teamUsers);
  const [recordProps, setRecordProps] = useState([]);
  const [teamId, setTeamId] = useState(props.currentTeamId);
  const [courseNameEntered, setCourseNameEntered] = useState("");
  const [courseStatusSelected, setCourseStatusSelected] = useState("");
  const [changesApplied, setChangesApplied] = useState([]);
  const [userSelectForTransfer, setUserSelectForTransfer] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const [courseInf, setCourseInf] = useState("");

  const theme = useTheme();
  const [completeOrders, setCompleteOrders] = useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(typeof value === "string" ? value.split(",") : value);
  // };

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const [usersTookCourses, setUsersTookCourses] = useState(null);
  const [usersNotTookCourses, setUsersNotTookCourses] = useState(null);
  const [usersTookCoursesIndex, setUsersTookCoursesIndex] = useState(null);
  const [transferUserButtonVisible, setTransferUserButtonVisible] =
    useState(false);
  const [newTeamName, setNewTeamName] = useState(null);
  const [transferMessage, setTransferMessage] = useState("");
  const [inviteTeamButtonVisible, setInviteTeamButtonVisible] = useState(false);

  // ========================================================

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [searchedRecords, setSearchedRecords] = useState(null);
  const [allUsersTeam, setAllUsersTeam] = useState(props.allTeams);
  const [usersTeamIDName, setUsersTeamIDName] = useState([{}]);
  const [newTeamId, setNewTeamId] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChangeUserSelect = (e, userEmail, key) => {
    // console.log("The checkbox is changed");
    if (e.target.checked) {
      setUserSelectForTransfer([
        ...userSelectForTransfer,
        { userEmail: userEmail },
      ]);
      let tempArr = [...recordProps];
      tempArr[key].isChecked = true;
      setRecordProps(tempArr);
    } else {
      let tempArr = [...userSelectForTransfer];
      let findIndx = tempArr.map((item) => item.userEmail).indexOf(userEmail);
      if (findIndx >= 0) {
        tempArr.splice(findIndx, 1);
        setUserSelectForTransfer(tempArr);
      }

      let tempSecArr = [...recordProps];
      tempSecArr[key].isChecked = false;
      setRecordProps(tempSecArr);
    }
  };

  const onChangeEmailSearch = (e) => {
    if (recordProps) {
      let searchKey = e.target.value;
      let tempAllArr = [...mainRecordProps];
      let tempSearchArr = [...searchedRecords];

      if (
        courseStatusSelected.trim() === "" ||
        courseStatusSelected === "all"
      ) {
        if (searchKey.trim() === "") {
          setSearchedRecords(tempAllArr);
        } else {
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) >
              -1
            );
          });

          setSearchedRecords(filtered);
        }
      } else {
        var filtered = tempAllArr.filter(function (value, index, arr) {
          return (
            value.role === courseStatusSelected &&
            value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) > -1
          );
        });

        setSearchedRecords(filtered);
      }
    }
  };

  const userTable = (
    <div style={{ margin: "30px 0px 20px 0px" }}>
      <CustomTable>
        <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
        </SearchTable>
        {/* ===========main pagination table======= */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "350px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedRecords ? (
                  searchedRecords
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            display:
                              !row?.displayEmail &&
                              !row?.displayCourse &&
                              !row?.displayStatus
                                ? ""
                                : "none",
                          }}
                        >
                          <TableCell>{row.userEmail}</TableCell>
                          <TableCell>
                            <SelectCompanyUser
                              type="checkbox"
                              onChange={(e) =>
                                onChangeUserSelect(e, row.userEmail, row.id)
                              }
                              disabled={
                                row.userEmail != user.email ? false : true
                              }
                              //   checked={row?.isChecked}
                              onFocus={() => setTransferMessage("")}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={
              searchedRecords
                ? searchedRecords?.length
                : searchedRecords?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CustomTable>
    </div>
  );

  const onClickTransferUserButton = async () => {
    if (newTeamId && userSelectForTransfer)
      if (newTeamId !== "No" && userSelectForTransfer.length > 0) {
        setTransferUserButtonVisible(true);

        await axios
          .post("/userTransfer", {
            currentTeamId: teamId,
            nextTeamId: newTeamId,
            users: userSelectForTransfer,
          })
          .then((output) => {
            if (output.data && output.data.length > 0) {
              setTransferUserButtonVisible(false);
              setTransferMessage(
                output.length + " user(s) are already in the team."
              );
            } else {
              //   console.log("The output:", output);
              setTransferUserButtonVisible(false);
              setTransferMessage("User(s) are successfully added to the team.");
            }
          })
          .catch(() => {
            setTransferUserButtonVisible(false);
            setTransferMessage("User(s) transfer was failed.");
          });
      } else {
        setTransferMessage("Please select a team and at least a user.");
      }
  };

  useEffect(() => {
    if (props.data) {
      setRecordProps(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (recordProps) {
      let tempArr = [...recordProps];
      // console.log("Team members:", recordProps);
      tempArr.forEach((item, key) => {
        item.id = key;
      });
      // console.log("Temp array:", tempArr);
      setMainRecordProps(tempArr);
    }
    return () => {
      setMainRecordProps(null);
    };
  }, [recordProps]);

  useEffect(() => {
    if (mainRecordProps) {
      setSearchedRecords(mainRecordProps);
    }
  }, [mainRecordProps]);

  useEffect(() => {
    if (allUsersTeam) {
      let newArr = [...allUsersTeam];

      //   console.log("team ids:", newArr);
      axios
        .post("/returnCompaniesTeamName", { teamId: newArr })
        .then((data) => {
          //   console.log("team names:", data.data);
          let teamNames = data.data;

          setUsersTeamIDName(teamNames);
        });
    }
  }, [allUsersTeam]);

  useEffect(() => {
    if (newTeamId) {
      //   console.log("New team Id:", newTeamId);
    }
  }, [newTeamId]);

  useEffect(() => {
    if (userSelectForTransfer) {
      //   console.log("Users", userSelectForTransfer);
    }
  }, [userSelectForTransfer]);

  useEffect(() => {
    return () => {
      setMainRecordProps(null);
      setSearchedRecords(null);
      setRecordProps(null);
    };
  }, []);

  useEffect(() => {
    if (props.teamUsers) {
      setRecordProps([...props.teamUsers]);
    }
  }, [props.teamUsers]);

  return (
    <Container>
      <UpperItemsContainer>
        <IndexLabel>Destination team name:</IndexLabel>
        <select
          name="cars"
          id="cars"
          value={newTeamId}
          onChange={(e) => setNewTeamId(e.target.value)}
          onFocus={() => setTransferMessage("")}
        >
          <option value="No"></option>
          {usersTeamIDName ? (
            usersTeamIDName.map((items) =>
              items.teamId !== teamId ? (
                <option value={items.teamId}>{items?.teamName}</option>
              ) : (
                <option value={items.teamId} style={{ display: "none" }}>
                  {items?.teamName}
                </option>
              )
            )
          ) : (
            <option value="No"></option>
          )}

          {/* <option value="saab">Saab</option>
          <option value="opel">Opel</option>
          <option value="audi">Audi</option> */}
        </select>
        {/* <InviteUserFullName
          onChange={(e) => setNewTeamName(e.target.value)}
          value={newTeamName}
          onFocus={() => setTransferMessage("")}
          placeholder="Fullname..."
        ></InviteUserFullName> */}
        {!transferUserButtonVisible ? (
          <InviteUserButton
            onClick={() => onClickTransferUserButton()}
            disabled={inviteTeamButtonVisible}
          >
            Transfer User
          </InviteUserButton>
        ) : (
          <InviteUserButton
            onClick={() => onClickTransferUserButton()}
            disabled={inviteTeamButtonVisible}
          >
            <CircularProgress color="inherit" />
          </InviteUserButton>
        )}
      </UpperItemsContainer>
      <ErrorMessageContainer>{transferMessage}</ErrorMessageContainer>

      {userTable}
    </Container>
  );
}

export default TeamUserTransfer;

const IndexLabel = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const UpperItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 160px auto 150px;
  grid-gap: 5px;
`;
const InviteUserFullName = styled.input`
  padding: 5px;
  margin-right: 5px;
`;

const InviteUserButton = styled.button`
  cursor: pointer;
  padding: 5px;
  background-color: rgba(95, 176, 95, 0.9);
  transition: all 0.2s ease-in-out;
  color: white;
  border-radius: 4px;
  border: none;
  height: 40px;

  &:hover {
    background-color: rgba(95, 176, 95, 1);
  }

  &:disabled {
    background-color: rgba(1, 1, 1, 0.1);
    cursor: default;
  }

  span {
    height: 20px !important;
    width: 20px !important;
  }
`;

const CustomTable = styled.div`
  width: 100%;
`;

const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;

const SearchContainers = [
  `
    position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:5px;
  `,
];

const SearchBoxes = [
  `
  width: 100%;
  padding: 7px 7px 7px 28px;
  
  option{
      // padding:7px 0px 7px 0px;
      // height:20px;
  }
  `,
];

const SearchButtons = [
  `
  position:absolute;
  border:none;
  outline:none;
  left:15px;
  background-color: transparent;
  // cursor:pointer;
  svg{
      fill:rgba(0,0,0,0.5)
  }
  `,
];

const EmailSearchContainer = styled.div(SearchContainers);
const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);
const SelectCompanyUser = styled.input``;
const Container = styled.div`
  padding: 20px;
`;

const ErrorMessageContainer = styled.div`
  height: 15px;
  color: red;
  font-size: 10px;
`;
