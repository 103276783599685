import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";

function PaginationTable(props) {
  const [data, setData] = useState(props.data);
  useEffect(() => {
    // console.log("Pagination table:", props.data);
  }, []);
  // return (
  //     <Container>

  //     </Container>
  // )
  const columns = React.useMemo(
    () => [
      // {
      //     Header: 'Name',
      //     columns: [
      //         {
      //             Header: 'First Name',
      //             accessor: 'firstName',
      //         },
      //         {
      //             Header: 'Last Name',
      //             accessor: 'lastName',
      //         },
      //     ],
      // },
      {
        Header: "Team Members",
        columns: [
          {
            Header: "Email",
            accessor: "useEmail",
          },
          {
            Header: "Class Name",
            accessor: "courseName",
          },
          {
            Header: "Class Progress",
            accessor: "progressing",
          },
        ],
      },
    ],
    []
  );

  //   const columns = [
  //     // {
  //     // //   Header: "User",
  //     //   columns: [
  //     //     {
  //     //       Header: "Email",
  //     //       accessor: "useEmail",
  //     //     },

  //     //   ],
  //     // },
  //     {
  //       Header: "Team Members",
  //       columns: [
  //         {
  //           Header: "Email",
  //           accessor: "useEmail",
  //         },
  //         {
  //           Header: "Class Name",
  //           accessor: "courseName",
  //         },

  //         // {
  //         //     Header: 'Visits',
  //         //     accessor: 'visits',
  //         // },
  //         // {
  //         //     Header: 'Status',
  //         //     accessor: 'status',
  //         // },
  //         {
  //           Header: "Class Progress",
  //           accessor: "progressing",
  //         },
  //       ],
  //     },
  //   ];

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  return (
    <Container>
      <div>
        <Table className="table" {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <TR {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TH {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TH>
                ))}
              </TR>
            ))}
          </THead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TR {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TD {...cell.getCellProps()}>{cell.render("Cell")}</TD>
                    );
                  })}
                </TR>
              );
            })}
          </Tbody>
        </Table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <UL className="pagination">
          <li
            className="page-item"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <a className="page-link">First</a>
          </li>
          <li
            className="page-item"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"<"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <a className="page-link">{">"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <a className="page-link">Last</a>
          </li>
          <li>
            <a className="page-link">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </a>
          </li>
          <li>
            <a className="page-link">
              <input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px", height: "20px" }}
              />
            </a>
          </li>{" "}
          <Select
            className="form-control"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "38px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </UL>
      </div>
    </Container>
  );
}

export default PaginationTable;

const Container = styled.div`
  padding: 20px;
`;
const Table = styled.div`
  /* border: 1px solid rgba(0, 0, 0, 0.2); */

  position: relative;
  display: block;
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: center;
`;

const THead = styled.thead`
  width: 100%;
`;

const TR = styled.tr`
  margin: 0;
  padding: 0;
  border: 0;
  border: 1px solid #999;
  width: 100%;
`;

const TH = styled.th``;

const TD = styled.td`
  margin: 0;
  padding: 4px 8px;
  border: 0;
  border: 1px solid #999;
  width: 100vw;
`;

const Tbody = styled.tbody`
  width: 500px;
`;

const UL = styled.ul`
  display: flex;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 30px;
  align-items: center;
  list-style-type: none;
`;

const Select = styled.select`
  height: 80% !important;
`;
