import React, { useState, useRef } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  const [contactUsSubject, setContactUsSubject] = useState("");
  const [contactUsText, setContactUsText] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  //   const [isTextSent, setIsTextSent] = useState(false);
  const [subjectBoxShadow, setSubjectBoxShadow] = useState("0 0 3px white");
  const [textBoxShadow, setTextBoxShadow] = useState("0 0 3px white");
  const [emailBoxShadow, setEmailBoxShadow] = useState("0 0 3px white");
  const [notification, setNotofication] = useState("none");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isRecaptchaChanged, setIsRecaptchaChanged] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const recaptchaRef = useRef();
  const recaptchaKey = "6LdmJMwpAAAAANWWLHpFppYLnhHCU7JbGTK8lpM5";

  const onChangeSubject = (event) => {
    // console.log(event.target.value);
    setContactUsSubject(event.target.value);
  };
  const onChangeContactUsText = (event) => {
    // console.log(event.target.value);
    setContactUsText(event.target.value);
  };

  const onChangeContactUsEmail = (event) => {
    setContactUsEmail(event.target.value);
  };

  const onClickSubmitContactUs = () => {
    if (
      contactUsSubject != "" &&
      contactUsText.trim() != "" &&
      contactUsEmail.trim() != "" &&
      reCaptchaToken != null
    ) {
      setButtonDisabled(true);
      axios
        .post("/verifyRecaptchaToken", { inputToken: reCaptchaToken })
        .then((data) => {
          //   console.log(data.data);
          if (data.data.success) {
            // setButtonDisabled(true);
            setIsRecaptchaChanged(false);
            //   console.log("Helloooooooo");
            axios
              .post("/contactUsSendMessage", {
                inputSubject: contactUsSubject,
                inputText: contactUsText,
                inputEmail: contactUsEmail,
              })
              .then(() => {
                //   setIsTextSent(true);
                setContactUsSubject("");
                setContactUsText("");
                setContactUsEmail("");
                setNotofication("block");
                setButtonDisabled(false);
              })
              .catch(() => {
                //   setIsTextSent(false);
                setButtonDisabled(false);
                setNotofication("none");
              });
          } else {
            setIsRecaptchaChanged(true);
            setButtonDisabled(false);
          }
        });
    } else if (contactUsEmail.trim() == "") {
      setEmailBoxShadow("0 0 3px red");
    } else if (contactUsSubject == "") {
      //   console.log("Please select a subject");
      setSubjectBoxShadow("0 0 3px red");
    } else if (contactUsText.trim() == "") {
      //   console.log("Please share your concerns to us");
      setTextBoxShadow("0 0 3px red");
    } else {
      // console.log("Please select the reCaptcha");
      setIsRecaptchaChanged(true);
    }
  };

  const OnFocusSubject = () => {
    setSubjectBoxShadow("0 0 3px white");
    setNotofication("none");
  };

  const onFocusTextField = () => {
    setTextBoxShadow("0 0 3px white");
    setNotofication("none");
  };

  const OnFocusEmail = () => {
    setEmailBoxShadow("0 0 3px white");
    setNotofication("none");
  };

  const onChangeReCaptcha = (token) => {
    // console.log("Recaptcha is changed", token);
    setReCaptchaToken(token);
    if (token) setIsRecaptchaChanged(false);

    // setIsRecaptchaChanged(true);
  };
  return (
    <Container>
      <ContactUsForm>
        {/* <TextField
          id="outlined-basic"
          label="Subject*"
          variant="outlined"
          className="contact-us-text-fields"
          
        /> */}

        <TextField
          className="contact-us-text-fields"
          id="outlined-basic"
          label="Email*"
          variant="outlined"
          onChange={(e) => onChangeContactUsEmail(e)}
          onFocus={() => OnFocusEmail()}
          value={contactUsEmail}
          style={{
            borderRadius: "4px",
            boxShadow: emailBoxShadow,
          }}
        />

        <FormControl fullWidth className="contact-us-text-fields">
          <InputLabel id="demo-simple-select-label">Subject*</InputLabel>
          <Select
            // className="select-subject-items"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={contactUsSubject}
            label="Subject"
            onChange={(e) => onChangeSubject(e)}
            style={{
              borderRadius: "4px",
              boxShadow: subjectBoxShadow,
            }}
            onFocus={() => OnFocusSubject()}>
            {/* <MenuItem value={10}>Select a Subject</MenuItem> */}
            <MenuItem value={"General Questions"}>General Questions</MenuItem>
            <MenuItem value={"Support"}>Support</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="outlined-multiline-static"
          label="How may we help you?*"
          //   variant="outlined"
          value={contactUsText}
          className="contact-us-text-fields"
          multiline
          rows={4}
          onChange={(e) => onChangeContactUsText(e)}
          style={{
            borderRadius: "4px",
            boxShadow: textBoxShadow,
          }}
          onFocus={() => onFocusTextField()}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={recaptchaKey}
          onChange={(e) => onChangeReCaptcha(e)}
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        />
        <ReCaptchaNote
          style={{
            display: isRecaptchaChanged ? "block" : "none",
            marginBottom: "10px",
          }}>
          ReCaptcha is expired or invalid.
        </ReCaptchaNote>
        <ButtonContainer>
          <Button
            variant="contained"
            style={{ backgroundColor: "#5fb05f", width: "100%" }}
            onClick={() => onClickSubmitContactUs()}
            disabled={buttonDisabled}>
            Submit
          </Button>
          <NotificationContainer style={{ display: notification }}>
            Your message is successfully sent to SafeT1st.
          </NotificationContainer>
        </ButtonContainer>
      </ContactUsForm>
    </Container>
  );
}

export default ContactUs;

const Container = styled.div`
  padding-top: 70px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContactUsForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  .select-subject-items {
    width: fit-content !important;
  }
  .contact-us-text-fields {
    margin-bottom: 10px !important;
    min-width: 200px;

    div {
      width: 400px !important;
    }
  }
`;
const Subject = styled.input``;
const Comment = styled.input``;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
const NotificationContainer = styled.div`
  margin-left: 10px;

  display: block;
  color: #5fb05f;
`;

const ReCaptchaNote = styled.div`
  color: red;
`;
