import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

function MoreInfoPage(props) {
  const [courseId, setCourseId] = useState(props.courseId);
  const [lectureId, setLectureId] = useState(props.lectureId);
  const [moreInfoContent, setMoreInfoContent] = useState(null);
  const [htmlCreation, setHtmlCreation] = useState(null);

  useEffect(() => {
    axios
      .post("/extractMoreInfoCourseContent", { courseId, lectureId })
      .then((data) => {
        // console.log(data.data);
        setMoreInfoContent(data.data);
      });
  }, []);

  return (
    <Container>
      {moreInfoContent ? ReactHtmlParser(moreInfoContent) : <div></div>}
    </Container>
  );
}

export default MoreInfoPage;

const Container = styled.div``;
