import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

function ShowTeamName(props) {
  const [teamName, setTeamName] = useState(null);

  useEffect(() => {
    if (props.teamId) {
      // console.log("kijsd:", props.teamId);
      axios.post("/returnTeamName", { teamId: props.teamId }).then((data) => {
        setTeamName(data.data);
        // props?.setAddDeleteUserTeamName(data.data);
      });
    }
  }, [props.teamId]);

  return <Container>{teamName ? teamName : <div></div>}</Container>;
}

export default ShowTeamName;

const Container = styled.div`
  margin: 0px 5px 0px 5px;
  font-weight: 600;
  display: contents;
`;
