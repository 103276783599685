import React, { useEffect, useState } from "react";
import styled from "styled-components";

function Questions(props) {
  const [questions, setQuestions] = useState(props.questions);
  const [messageIsHidden, setMessageIsHidden] = useState(props.isHidden);
  const [answers, setAnswers] = useState([]);
  const [isDiabled, setIsDiabled] = useState(props.isDiabled);
  const [countGrade, setCountGrade] = useState(0);

  useEffect(() => {
    if (countGrade > 0) {
      // console.log("Count grade:", countGrade);
      // console.log("Length:", Object.entries(questions).length);
      let totalCount = (countGrade / Object.entries(questions).length) * 100;

      props.grade(totalCount);
    }
  }, [countGrade]);

  useEffect(() => {
    setMessageIsHidden(props.isHidden);
  }, [props.isHidden]);

  useEffect(() => {
    setIsDiabled(props.isDiabled);
  }, [props.isDiabled]);

  useEffect(() => {
    // console.log("inner question:", questions);
    // console.log("inner question length:", Object.entries(questions).length);
  }, [questions]);

  useEffect(() => {
    // console.log("inner answers:", answers);
    // console.log("real length of array:", Object.values(answers).length);
    if (Object.values(answers).length == Object.entries(questions).length) {
      props.buttonDisable(false);
    }
  }, [answers]);

  useEffect(() => {
    // console.log("inner isHidden:", messageIsHidden);
  }, [messageIsHidden]);

  const keepAnswers = (answer, number, correctAnswer) => {
    // console.log(
    //   "Answer:",
    //   answer.target.value + "Correct answer:",
    //   correctAnswer
    // );

    if (answer.target.value == correctAnswer) {
      setCountGrade(countGrade + 1);
    }
    let newArray = [...answers];
    newArray[number] = answer.target.value;
    setAnswers(newArray);
  };

  return (
    <Container>
      {questions ? (
        Object.entries(questions).map((item, key) => (
          <QuizContainer>
            <QuestionContainer>
              <QuestionNumber>
                {key + 1}
                {`)`}
              </QuestionNumber>
              <Question>{item[1].question}</Question>
            </QuestionContainer>
            <OptionContainer key={key}>
              {Object.entries(item[1].options).map((innerItem) => (
                <Option>
                  <RadioButtonContainer
                    onChange={(e) => keepAnswers(e, key, item[1].answer)}
                  >
                    <input
                      name={`options${key}`}
                      type="radio"
                      value={innerItem[0]}
                      disabled={isDiabled}
                      // checked={
                      //   radioChecked == innerItem[0] + "" + key + "" + key2
                      // }
                      // onClick={() => setRadioChecked(!radioChecked)}
                      // onChange={(e) => handleRadioChange(e, key, key2)}
                      // checked={radioChecked=="" ? false}
                    />
                  </RadioButtonContainer>
                  <div>{innerItem[1]}</div>
                </Option>
              ))}
            </OptionContainer>
            {/* <div>{item[1].answer}</div> */}
            <MessageContainer>
              <Message style={{ display: messageIsHidden ? "none" : "block" }}>
                {answers[key] ? (
                  answers[key] == item[1].answer ? (
                    <div style={{ color: "green" }}>Correct</div>
                  ) : (
                    <div style={{ color: "red" }}>Wrong</div>
                  )
                ) : (
                  <div>Not Answered</div>
                )}
              </Message>
            </MessageContainer>
          </QuizContainer>
        ))
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default Questions;

const Container = styled.div``;
const Message = styled.div``;

const QuizContainer = styled.div``;
const QuestionContainer = styled.div`
  display: flex;
`;
const QuestionNumber = styled.div`
  padding-right: 5px;
`;
const Question = styled.div``;

const OptionContainer = styled.div``;

const Option = styled.div`
  display: flex;
  align-items: center;
`;

const RadioButtonContainer = styled.div`
  padding-top: 4px;
  padding-right: 5px;
`;

const MessageContainer = styled.div`
  height: 30px;
`;
