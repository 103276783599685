import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";

function MyCertificates() {
  const history = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [certifiedCourses, setCertifiedCourses] = useState();

  const onClickCourseNameContainer = (createAt, courseId) => {
    console.log("Certified Date:", createAt);
    history("/certificate", {
      state: {
        createAt,
        courseId,
        fullName: user.fullName,
        userEmail: user.email,
      },
    });
  };

  useEffect(() => {
    if (user) {
      axios
        .post("/returnCertifiedCourses", { email: user.email })
        .then((data) => {
          setCertifiedCourses(data.data);
        });
    }
  }, []);

  useEffect(() => {
    // console.log(certifiedCourses);
  }, [certifiedCourses]);
  return (
    <Container>
      <Certificates>
        {certifiedCourses ? (
          certifiedCourses.map((item, key) => (
            // <CourseContent courseInfo={item.courseId}></CourseContent>
            // <MyCourse
            //   onClick={() => onClickShowCourseContent(item.courseId)}
            //   key={key}
            // >
            //   {item.courseName}
            // </MyCourse>
            <CourseNameContainer
              onClick={() =>
                onClickCourseNameContainer(
                  item._fieldsProto.certifiedAt.stringValue,
                  item._fieldsProto.courseId.stringValue
                )
              }>
              <ShowCourseName
                key={key}
                courseId={item._fieldsProto.courseId.stringValue}
              />
            </CourseNameContainer>
            // <MyCourseBasicInfo
            //   // onClick={() => onClickShowCourseContent(item.courseId)}
            //   key={key}
            //   courseID={item.courseId}
            // />
          ))
        ) : (
          <div></div>
        )}
      </Certificates>
    </Container>
  );
}

export default MyCertificates;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
`;

const Certificates = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: 2rem;
  /* padding: 5rem; */
`;

const CourseNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;
  background-color: #f2f2f2;
  border-radius: 5px;
  height: 250px;
  transition: all 0.2s ease-in-out;
  position: relative;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
