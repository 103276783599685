import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../Images/full-logo.png";
import backgroundImage from "../Images/Login_Background_forgetPass1.png";
import topPattern from "../Images/pattern_top.png";
import cloud1 from "../Images/Login_Background_forgetPass_cloud.png";
import cloud2 from "../Images/Login_Background_forgetPass_sun.png";
import cloud3 from "../Images/cloud3.png";
import cloud4 from "../Images/cloud4.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";

const Container = styled.div`
  /* margin: 0px 10px 10px 10px; */
  height: 100vh;
  width: 100%;
  /* background: #f9f0ec; */

  /* background-repeat: repeat; */
  /* position: relative;
  background: url(${backgroundImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */

  display: grid;
  grid-template-rows: 50px auto;
  /* &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${backgroundImage}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
  /* opacity: 0.5; */
  /* }  */
`;

const ContainerHeader = styled.div`
  background: url(${topPattern}) repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  opacity: 0.3;
`;
const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  z-index: 2;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  /* border-left: 2px dashed #9c9fb0;
  border-right: 2px dashed #9c9fb0;
  border-bottom: 2px dashed #9c9fb0; */
  /* padding: 10px; */
  width: 80%;
  max-width: 600px;
  z-index: 1;
  background: white;
  padding: 50px 70px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* position: absolute;
  right: 10%;
  top: 10%; */

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(244, 238, 238, 0.25);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const LoginForm = styled.div`
  /* padding: 10px; */

  width: 80%;
  max-width: 600px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;

  position: relative;
  /* background: url(${backgroundImage}) no-repeat center center fixed; */
  /* background: url(${backgroundImage}) no-repeat bottom center;
  background-color: white;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain; */

  /* background: url(${backgroundImage}) no-repeat;
  background-size: cover; */
  /* background: url(${backgroundImage}) no-repeat right center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain; */
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlackImage = styled.img`
  height: 70px;
  cursor: pointer;

  /* margin-bottom: 30px; */
`;

const LoginButton = styled.button`
  width: 100%;
  /* margin-top: 10px; */
  /* background-color: #5fb05f; */

  background-color: rgba(17, 34, 17, 0.8);
  color: white;

  /* background-color: transparent; */
  border: none;

  /* border-color: #5fb05f; */
  /* border: 2px solid #5fb05f; */
  outline: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(17, 34, 17, 1);
    border-color: rgba(95, 176, 95, 0);
    /* color: #5fb05f; */
  }

  &:disabled {
    background: #999;
    color: #555;
    cursor: not-allowed;
  }
`;

const LoginButtonContainer = styled.div`
  width: 100%;
`;

const UserName = styled.input`
  padding: 11.5px;
  margin-top: 15px;
`;
const Password = styled.input`
  padding: 11.5px;
`;

const UserNameError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
  /* left: 0; */
`;
const PasswordError = styled.p`
  /* padding: 10px; */
  height: 20px;
  font-size: 11px;
  color: red;
`;

const UserNameContainer = styled.div`
  width: 100%;
`;
// const PasswordContainer = styled.div``;

function PasswordReset(props) {
  const history = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [specialCharsColor, setSpecialCharsColor] = useState("white");
  const [digitColor, setDigitColor] = useState("white");
  const [eightToSixColor, setEightToSixColor] = useState("white");
  const [lowerCaseColor, setLowerCaseColor] = useState("white");
  const [upperCaseColor, setUpperCaseColor] = useState("white");
  const [confirmPassword, setConfirmPassword] = useState("");
  //   const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [email, setEmail] = useState();
  const [key, setKey] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(true);

  useEffect(() => {
    // let finalEmail = new URLSearchParams(location.search).get("userEmail");
    // let finalKey = new URLSearchParams(location.search).get("key");

    let parameters = location.search;
    let keyIndex = parameters.indexOf("&key=");

    let finalEmail = parameters.slice(11, keyIndex);
    let finalKey = parameters.slice(keyIndex + 5);

    setEmail(finalEmail);
    setKey(finalKey);
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
    const eightToSix =
      /^[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    const lowerCase =
      /(?=(.*[a-z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const upperCase =
      /(?=(.*[A-Z]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const digit =
      /(?=(.*[0-9]){2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;
    const specialChar =
      /(?=(.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]){1})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/;

    // if (
    //   !isEmpty(event.target.value) &&
    //   (!event.target.value.match(eightToSix) ||
    //     !event.target.value.match(lowerCase) ||
    //     !event.target.value.match(upperCase) ||
    //     !event.target.value.match(digit) ||
    //     !event.target.value.match(specialChar))
    // ) {
    if (event.target.value.match(eightToSix)) {
      setEightToSixColor("green");
    } else {
      setEightToSixColor("white");
      // setPasswordError("Password should contain at least 8 to 16 characters");
    }

    if (event.target.value.match(lowerCase)) {
      setLowerCaseColor("green");
    } else {
      setLowerCaseColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 lowercase characters"
      // );
    }

    if (event.target.value.match(upperCase)) {
      setUpperCaseColor("green");
    } else {
      setUpperCaseColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 uppercase characters"
      // );
    }

    if (event.target.value.match(digit)) {
      setDigitColor("green");
    } else {
      setDigitColor("white");
      // setPasswordError("Password should contain at least 2 digits");
    }

    if (event.target.value.match(specialChar)) {
      setSpecialCharsColor("green");
    } else {
      setSpecialCharsColor("white");
      // setPasswordError(
      //   "Password should contain at least 2 special characters"
      // );
    }
    // }
    // else {
    //   setPasswordError("");
    // }
  };

  const onCopyPassword = (event) => {
    event.clipboardData.setData("text/plain", "");
    event.preventDefault();
  };

  //   const handleClickShowPassword = (event) => {
  //     setValues({
  //       ...values,
  //       showPassword: !values.showPassword,
  //     });
  //   };

  //   const handleMouseDownPassword = (event) => {
  //     event.preventDefault();
  //   };

  //   const [values, setValues] = useState({
  //     amount: "",
  //     password: "",
  //     weight: "",
  //     weightRange: "",
  //     showPassword: false,
  //   });

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  //   useEffect(() => {
  //     if (location.state?.email) {
  //       setUserName(location.state.email);
  //     }
  //   }, []);

  const onClickCompanyLogo = () => {
    history("/");
  };
  // const popUpMessage = isEmailVerified ? (
  //   <div>
  //     <h1>Popup/Modal Windows without JavaScript</h1>
  //     <div class="box">
  //       <a class="button" href="#popup1">
  //         Let me Pop up
  //       </a>
  //     </div>

  //     <div id="popup1" class="overlay">
  //       <div class="popup">
  //         <h2>Here i am</h2>
  //         <a class="close" href="#">
  //           &times;
  //         </a>
  //         <div class="content">
  //           Thank to pop me out of that button, but now i'm done so you can
  //           close this window.
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   <div></div>
  // );

  //   const handleChange = (prop) => (event) => {
  //     setValues({ ...values, [prop]: event.target.value });
  //     setPassword(event.target.value);
  //   };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setConfirmPassword(event.target.value);
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const keepUserName = (event) => {
    setUserName(event.target.value);
  };

  // const keepPassword = (event) => {
  //   setPassword(event.target.value);
  // };

  const onClickLiginLink = () => {
    history("/login");
  };

  const onClickSignUpLink = () => {
    history("/signup");
  };

  useEffect(() => {
    if (!isEmpty(userName)) {
      setUserNameError("");
    }
  }, [userName]);

  useEffect(() => {
    if (!isEmpty(password)) {
      setPasswordError("");
    }
  }, [password]);

  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(re)) return true;
    else return false;
  };

  const isPassword = (password) => {
    const re =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_])(?=.*[A-Z]{2})(?=.*[a-z]{2})[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,16}$/;
    if (password.match(re)) return true;
    else return false;
  };

  const isEmpty = (string) => {
    if (string.trim() == "") return true;
    else return false;
  };
  //
  const onClickLogin = () => {
    if (
      !isEmpty(values.password) &&
      // isPassword(values.password) &&
      eightToSixColor === "green" &&
      digitColor === "green" &&
      lowerCaseColor === "green" &&
      upperCaseColor === "green" &&
      specialCharsColor === "green" &&
      values.password === values.confirmPassword
    ) {
      setSubmitIsLoading(true);
      setIsDisable(true);
      axios
        .post(`/loginWithCustomToken`, {
          userEmail: email,
          password: values.password,
          customToken: key,
        })
        .then((data) => {
          setIsDisable(false);

          if (data.data?.message) {
            if (data.data.message == "success") {
              setConfirmPassword(
                "Password is successfully changed. Redirecting to login page..."
              );
              setPasswordError("");
              setConfirmPasswordError("");
              setValues({
                ...values,
                confirmPassword: "",
                password: "",
              });
              setPassword("");
              setConfirmPassword("");
              history("/login", { state: { email: email } });
            } else if (data.data.message == "update") {
              setSubmitIsLoading(false);
              setConfirmPasswordError("Password is not successfully changed.");
            } else if (data.data.message == "email") {
              setSubmitIsLoading(false);
              setConfirmPasswordError(
                "The reset password link is expired or not valid (email)."
              );
            } else if (data.data.message == "token") {
              setSubmitIsLoading(false);
              setConfirmPasswordError(
                "The reset password link is expired or not valid (token)."
              );
            } else {
              setSubmitIsLoading(false);
              setConfirmPasswordError("System Error.Please try again!");
            }
          } else {
            setSubmitIsLoading(false);
            setConfirmPasswordError("System Error.Please try again!");
          }
        });
    } else {
      // console.log("Error darim");
      setSubmitIsLoading(false);
      if (isEmpty(password)) {
        setPasswordError("Please fill out the password field.");
      } else if (!isPassword(password)) {
        setPasswordError("Please follow the password rules.");
      } else {
        setPasswordError("");
      }

      if (isEmpty(confirmPassword)) {
        setConfirmPasswordError("Please fill out the confirm password field.");
      } else if (password !== confirmPassword) {
        setConfirmPasswordError("Password does not match.");
        //sjkljkj
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  return (
    <Container>
      <ContainerHeader />

      <LoginFormContainer>
        <LoginForm>
          {/* <Cloud2Container>
              <Cloud2 src={cloud2} />
            </Cloud2Container> */}
          <LogoContainer>
            <SlackImage src={logo} onClick={() => onClickCompanyLogo()} />
          </LogoContainer>
          <MainContent>
            <PasswordContainer>
              {/* <Password
            type="password"
            placeholder="Password"
            onChange={(e) => keepPassword(e)}
          /> */}

              {/* <Tooltip
                title={longText}
                className="password-tooltip"
                data-html="true"
                placement="bottom-start"
              > */}
              {/* <Button sx={{ m: 1 }}>Default Width [300px]</Button> */}

              <FormControl sx={{ m: 1 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  onCopy={onCopyPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* </Tooltip> */}
              <PasswordError>
                {passwordError !== "" ? `${passwordError}` : ""}
              </PasswordError>

              <ErrorsContainer>
                <PasswordLength style={{ color: `${eightToSixColor}` }}>
                  -8-16 characters
                </PasswordLength>
                <PasswordLoweCase style={{ color: `${lowerCaseColor}` }}>
                  -At least 2 lower case characters
                </PasswordLoweCase>
                <PasswordUpperCase style={{ color: `${upperCaseColor}` }}>
                  -At least 2 upper case characters
                </PasswordUpperCase>
                <PasswordDigits style={{ color: `${digitColor}` }}>
                  -At least 2 digits
                </PasswordDigits>

                <PasswordSpecialChars style={{ color: `${specialCharsColor}` }}>
                  -At least 1 special char, can be any of !@#$%^&*+=-?
                  {/* -At least 2 special characters */}
                  {/* -Special characters are not required but highly recommended */}
                </PasswordSpecialChars>
              </ErrorsContainer>
            </PasswordContainer>
            <ConfirmPasswordContainer>
              <FormControl
                sx={{ m: 1 }}
                variant="outlined"
                className="confirm-password-field">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  type={values.showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={handleConfirmPasswordChange("confirmPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="ConfirmPassword"
                />
              </FormControl>
              <ConfirmPasswordError>
                {confirmPasswordError !== "" ? `${confirmPasswordError}` : ""}
              </ConfirmPasswordError>
              {/* <ConfirmPassword
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          /> */}
            </ConfirmPasswordContainer>

            <LoginButtonContainer>
              <LoginButton onClick={() => onClickLogin()} disabled={isDisable}>
                Submit
              </LoginButton>
            </LoginButtonContainer>
            <Links>
              <LoginLink onClick={() => onClickLiginLink()}>Login</LoginLink>
              <SignUpLink onClick={() => onClickSignUpLink()}>
                Signup
              </SignUpLink>
            </Links>
          </MainContent>
        </LoginForm>
      </LoginFormContainer>
    </Container>
  );
}

export default PasswordReset;

const SignUpButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const SignUpButton = styled.button`
  border-color: #001a00;
  border-radius: 4px;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #003300;
    color: #003300;
  }
`;

const ForgetUsernameContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  color: #001a00;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Cloud1Container = styled.div`
  position: absolute;
  top: 130px;
  right: -60px;
`;
const Cloud2Container = styled.div`
  position: absolute;
  top: 25px;
  right: -80px;
`;
const Cloud3Container = styled.div`
  position: absolute;
  top: 190px;
  left: -130px;
`;
const Cloud4Container = styled.div`
  position: absolute;
  top: -6px;
  left: -160px;
`;

const Cloud3 = styled.img`
  width: 240px;
`;
const Cloud1 = styled.img`
  width: 100px;
`;
const Cloud2 = styled.img`
  width: 200px;
`;
const Cloud4 = styled.img`
  width: 300px;
`;

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const InstructionContainer = styled.div`
  max-width: fit-content;
  margin-bottom: 20px;

  h1 {
    text-align: left;
    margin: 10px 0;
    font-size: 24px;
    color: black;
  }

  p {
    font-size: 16px;
    text-align: justify;
  }
`;

const LoginLink = styled.div`
  color: rgba(17, 34, 17, 1);
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(17, 34, 17, 1);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
const SignUpLink = styled.div`
  color: rgba(17, 34, 17, 1);
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 15px;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgba(17, 34, 17, 1);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

const ErrorsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;

  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 10px 5px 10px;
  /* position: absolute; */
  top: 60px;
  border-radius: 4px;
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  z-index: 10000000000000000000000000000000000000000000000000;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(10px); */
  /* z-index: 1; */
  background: rgba(0, 0, 0, 0.8);
  /* background: rgba(244, 238, 238, 0.25); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */

  transition: all 0.2s ease-in;
  transition-delay: 0.3s;
  transform: scale(0.98) translateX(0px) translateY(0px);
`;

const PasswordContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover ${ErrorsContainer} {
    background: rgba(0, 0, 0, 0.8);
    /* background: rgba(244, 238, 238, 0.25); */
    /* backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px); */
    transition-delay: 0s;
    border-radius: 5px;
    /* border: 1px solid #bfbfbf; */
    border: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1) translateX(0px) translateY(0px);
  }
`;

// const ConfirmPasswordContainer = styled.div`
//   /* margin-top: 10px; */
//   margin-bottom: 10px;
//   width: 100%;
// `;

const PasswordLength = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const PasswordLoweCase = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const PasswordUpperCase = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const PasswordDigits = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const PasswordSpecialChars = styled.p`
  /* height: 20px; */
  font-size: 11px;
  color: red;
`;

const ConfirmPasswordError = styled.p`
  height: 20px;
  font-size: 11px;
  color: red;
`;

const ConfirmPasswordContainer = styled.div`
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  width: 100%;
`;
