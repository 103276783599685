import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function CompanyTeam(props) {
  const history = useNavigate();
  const [teamName, setTeamName] = useState(null);

  useEffect(() => {
    if (props.teamIdProp) {
      axios
        .post("/returnCompanyTeamName", { teamId: props.teamIdProp })
        .then((data) => {
          setTeamName(data.data);
        });
    }
  }, [props.teamIdProp]);

  useEffect(() => {
    if (teamName) {
      // console.log("Helloooooo:", teamName);
    }
  }, [teamName]);

  const onClickTeamsName = (teamId) => {
    // console.log("Clicked", teamId);
    history("/team", {
      state: {
        teamId: teamId,
        teamName: teamName,
        allTeamsID: props.allTeamsID,
      },
    });
  };

  return (
    <Container>
      {/* <TeamContainer> */}
      {teamName ? (
        <TeamContainer onClick={() => onClickTeamsName(props.teamIdProp)}>
          {teamName}
        </TeamContainer>
      ) : (
        <div>Loading...</div>
      )}
      {/* </TeamContainer> */}
    </Container>
  );
}

export default CompanyTeam;

const Container = styled.div``;
const TeamContainer = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 250px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
