import React from "react";
import styled from "styled-components";

function AboutUs() {
  return (
    <Container>
      <AboutUsHeader>The eSpec Story</AboutUsHeader>
      <AboutUsMain>
        <p>
          eSpec Software was formed with the specific intent to develop
          inspection software for the fuel industry. The creators of eSpec
          looked at inspections as not just a Federal or State mandate but a way
          to protect the assets of owners and the environment we all share. We
          knew we would need a way to guarantee the inspections were being done
          where and when they were supposed to but also by whom. Costs per
          inspection for this mandated activity are born solely by the owners of
          these systems so efficiency was a top priority.
        </p>
        <p>
          In early 2012 the first version of the software was created and
          extensively field-tested on hundreds of inspections. Patent#
          10,740,696 B2. The feedback from these early inspections allowed us to
          make numerous changes and additions to get more functionality,
          reliability, and productivity. We called our software Fuel Proof.
        </p>
        <p>
          Fast forward to 2018 and the software of today has features
          unavailable on any other platform is incredibly reliable and best of
          all has been tested by inspectors in real-life conditions on thousands
          of inspections. Productivity has increased from the first paper and
          pencil inspections by about 300% for our current version.
        </p>
        <p>
          Future versions of our software will use Augmented Reality to increase
          inspection functionality and productivity. The value of software is
          its ease of use, Time savings, and accuracy. eSpec does all three
          better than any other software or process. We simply connect, inspect
          and protect. Your assets and the environment are safer with eSpec.
        </p>
      </AboutUsMain>
    </Container>
  );
}

export default AboutUs;

const Container = styled.div`
  padding: 90px 20px 20px 20px;
  height: 100%;
`;

const AboutUsHeader = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
`;
const AboutUsMain = styled.div`
  p {
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 10px;
  }
`;
