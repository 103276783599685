import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/full-logo.png";

function TestEmailTemplate() {
  return (
    <div
      style={{
        position: "relative",
        padding: "20px",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <div>
        <table width="100%" style={{ maxWidth: "640px" }}>
          <tbody>
            <tr>
              <td>
                <img src={logo} width="100%" />
              </td>
            </tr>
            <tr>
              <td style={{ border: "10px solid black", padding: "10px" }}>
                Please verify "your" email address is your to complete your
                registration.click on the following button and log into your
                account. If the button does not redirect you to the login page,
                copy and paste the link bellow into your browser's address bar.
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  <a
                    href="https://google.com"
                    style={{
                      textDecoration: "none",

                      padding: "10px",

                      fontWeight: "600",
                      background: "#5fb05f",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid #D9D9D9",
                    }}
                  >
                    Verify Your Email
                  </a>
                  {/* <form action="https://google.com">
                    <input
                      type="submit"
                      value="Verify Your Email"
                      style={{
                        padding: "10px",
                        backgroundColor: "#5fb05f",
                        color: "white",
                        fontWeight: "600",
                        border: "none",
                        outline: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </form> */}
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ marginTop: "10px" }}>
                If this wasn’t you, someone may have mistyped their email
                address. No other action is needed at this moment.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TestEmailTemplate;
