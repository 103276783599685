import axios from "axios";
import React, { useEffect, useState } from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import styled from "@emotion/styled";

function ShowCoursePrice(props) {
  const [coursePrice, setCoursePrice] = useState(0);
  useEffect(() => {
    if (props.cartData?.coursePrice) {
      let total = props.cartData.coursePrice * props.quantityProp;
      setCoursePrice(Number.parseFloat(total).toFixed(2));
      props.changeSubTotalProp(total);
      let tempData = [...props.cartData];
      tempData[props.index].total = total;
      props.changeTeamCartItems(tempData);
      let tempArray = props.companyPriceArray;
      tempArray[props.index] = props.cartData.coursePrice;
      props.changePriceArray(tempArray);
    } else {
      axios
        .post("/returnCourseInfo", { courseId: props.courseId })
        .then((data) => {
          const currentPrice = data.data.price;
          let tempData = [...props.cartData];
          tempData[props.index].coursePrice = currentPrice;
          tempData[props.index].courseName = data.data.name;
          let total = currentPrice * props.quantityProp;
          tempData[props.index].total = total;
          props.changeTeamCartItems(tempData);
          setCoursePrice(Number.parseFloat(total).toFixed(2));
          props.changeSubTotalProp(total);
          let tempArray = props.companyPriceArray;
          tempArray[props.index] = currentPrice;
          props.changePriceArray(tempArray);
        });
    }
  }, []);

  return (
    <Container>
      <LocalOfferIcon />${coursePrice}
    </Container>
  );
}

export default ShowCoursePrice;

const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
