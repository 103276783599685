import axios from "axios";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import UserCheckBox from "./UserCheckBox";

function Order(props) {
  const [items, setItems] = useState(
    JSON.parse(props.orderDetail.metadata.itemsCheckOut)
  );
  const [users, setUsers] = useState(
    JSON.parse(props.orderDetail.metadata.userCheckOut)
  );
  const [courseName, setCourseName] = useState([]);
  const [orderDate, setOrderDate] = useState(
    new Date(props.orderDetail.timestamp._seconds * 1000)
  );

  //   const returnItemsName = (courseId) => {

  //   };

  //   const hasTimePromise = new Promise((res, rej) => {
  //     let output = [];
  //     axios
  //       .post("/hasUserTeam", {
  //         userEmail: "bh.zoghi@gmail.com",
  //         courseId: "PsWXUDra9c8ph50myCRA",
  //       })
  //       .then((boolOutput) => {
  //         // output = boolOutput.data;
  //         res(boolOutput.data);
  //       });
  //     // console.log("output", output);
  //   });

  const hasTeam = (email, courseId) => {
    // hasTimePromise.then((out) => {
    //   console.log("Promisesssss:", out);
    //   return out;
    // });
    // if (email && courseId) {
    //   let output = [];
    const output = axios.post("/hasUserTeam", {
      userEmail: "bh.zoghi@gmail.com",
      courseId: "PsWXUDra9c8ph50myCRA",
    });
    //   .then(
    //     (boolOutput) =>
    //       new Promise((resolve) => {
    //         console.log("boolean value:", boolOutput.data);
    //         resolve(boolOutput.data);
    //       })
    // console.log("boolean output:", output.data);
    return output.data;

    // return false;
    //   output.push(boolOutput.data);
    //   );
    //   Promise.all(output).then((response) =>
    //     console.log("Promises:", response)
    //   );

    // }

    //   console.log("Yeki chap kon")
  };

  const courseNameList = courseName ? (
    courseName.map((item, key) => <div key={key}>{item}</div>)
  ) : (
    <div>Loading</div>
  );

  const promise = new Promise((res, rej) => {
    if (items) {
      let newArray = items;
      let output = [];
      let newItem;
      //   for (var i = 0; i < newArray.length; i++) {
      newArray.forEach((item) => {
        // console.log("Each item:", item);
        output.push(axios.post("/returnOrdersName", { courseId: item }));
        // axios.post("/returnOrdersName", { courseId: item }).then((data) => {
        //   console.log("data.data:", data.data);
        //   output.push(data.data);
        //   newItem = data.data;
        //   Object.assign(output, { orderName: data.data });
        // output = {...output, data.data};
        //   setCourseName([...courseName, data.data]);
        // });
        // output.push(newItem);
      });
      // Promise.all(output).then((response)=>console.log(response))
      //   }
      //   console.log("output:", output);
      res(output);
    }
  });

  const testPromise = () => {
    if (items) {
      let newArray = items;
      let output = [];
      newArray.forEach((item) => {
        output.push(
          axios
            .post("/returnOrdersName", { courseId: item })
            .then((data) => data.data)
        );
      });
      Promise.all(output).then((response) => setCourseName(response));
    }
  };

  useEffect(() => {
    // promise.then((out) => {
    //   setCourseName(out);
    //   console.log("Hello:", out);
    //   //   console.log(courseName);
    // });
    testPromise();
    // console.log("items ha:", items);
  }, [items]);

  useEffect(() => {
    // console.log("This is an order changes");
  }, [props.list]);

  //   useEffect(() => {
  //     // console.log("Jadid:", courseName);
  //   }, [courseName]);

  //   useEffect(() => {
  //     // console.log("Users:", users);
  //   }, [users]);

  //   useEffect(() => {
  //     // console.log("Order date:", orderDate);
  //   }, [orderDate]);

  return (
    <Container>
      <OrderDate>{orderDate ? orderDate.toDateString() : ""}</OrderDate>
      {/* <OderContainer> */}
      {courseName ? (
        courseName.map((item, index) => (
          <OrderContainer key={index}>
            {item}
            {/* ,{items[index]} */}
            {users ? (
              users.map((secItem, key) => (
                <UserContainer key={key}>
                  {/* {secItem.type == "Team" ? ( */}
                  <CheckBoxContainer>
                    <UserCheckBox
                      userEmail={secItem.userEmail}
                      courseId={items[index]}
                      oserdersUsersList={(val) => props.usersListProp(val)}
                      list={props.list}
                    />
                  </CheckBoxContainer>
                  {/* ) : ( */}
                  {/* <EmptySpace></EmptySpace>
                  )} */}

                  {secItem.userEmail}
                </UserContainer>
              ))
            ) : (
              <div></div>
            )}
          </OrderContainer>
        ))
      ) : (
        <div></div>
      )}
      {/* </OderContainer> */}
    </Container>
  );
}

export default Order;

const Container = styled.div`
  /* margin: 10px; */
  border: 1px solid gray;

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
  /* padding: 5px; */
`;
const OrderContainer = styled.div`
  padding: 5px;
  /* background-color: brown; */
  /* height: 100px; */
`;
const Test = styled.div``;
const OrderDate = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
`;

const CheckBoxContainer = styled.div`
  padding: 0px 5px 0px 15px;
`;

const UserContainer = styled.div`
  display: flex;
`;

const EmptySpace = styled.div`
  padding-left: 33px;
`;
