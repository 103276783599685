import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShowCourseName from "../pages/showCourseName";
import ShowCourseStatus from "./ShowCourseStatus";
import ShowCompanyUserRole from "./ShowCompanyUserRole";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";

// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import { loadStripe } from "@stripe/stripe-js";
const stripePromiseTestMode = loadStripe(
  "pk_test_51HC8ruLYpiPz9FF416QMwOiY1LJDy6oF3JqZ5YuATtIreOoh6ppEnQk5FQlhFzcpWumBZTefBvznFz595REDm48g00uktcs3u7"
);

const stripePromiseLiveMode = loadStripe(
  "pk_live_51HC8ruLYpiPz9FF4gF6Suk6enWcV7kocqHJdU1jMWm1sck1SlJCrsUftYPDDwWoZ8L6Txo9olw0l6Yv5NAZs2eDJ00KJiF3o00"
);

const stripePK = stripePromiseLiveMode;

const steps = ["Guidelines", "Select User(s)", "Select Course(s)"];

// const steps = [
//   {
//     label: "Select campaign settings",
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: "Create an ad group",
//     description:
//       "An ad group contains one or more ads which target a shared set of keywords.",
//   },
//   {
//     label: "Create an ad",
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

const columns = [
  { id: "email", label: "User Email" }, //,minWidth: 170
  { id: "select", label: "Select User" }, //, minWidth: 100
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CourseRegistrationCompany(props) {
  const history = useNavigate();
  const [recordProps, setRecordProps] = useState(props.data);
  const [courseNameEntered, setCourseNameEntered] = useState("");
  const [courseStatusSelected, setCourseStatusSelected] = useState("");
  const [changesApplied, setChangesApplied] = useState([]);
  const [userSelectForRegisteration, setUserSelectForRegisteration] = useState(
    []
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const [courseInf, setCourseInf] = useState("");

  const theme = useTheme();
  const [completeOrders, setCompleteOrders] = useState([]);
  const [itemsQuantity, setItemsQuantity] = useState(0);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(typeof value === "string" ? value.split(",") : value);
  // };

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const [usersTookCourses, setUsersTookCourses] = useState(null);
  const [usersNotTookCourses, setUsersNotTookCourses] = useState(null);
  const [usersTookCoursesIndex, setUsersTookCoursesIndex] = useState(null);

  const [usersTookCoursesIndexForCart, setUsersTookCoursesIndexForCart] =
    useState(null);
  const [usersTookCoursesForCart, setUsersTookCoursesForCart] = useState(null);
  const [usersNotTookCoursesForCart, setUsersNotTookCoursesForCart] =
    useState(null);

  // ========================================================

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [mainRecordProps, setMainRecordProps] = useState(null);
  const [searchedRecords, setSearchedRecords] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ==========================================================

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // const handleChange = (event, userID) => {
  //   setCourseInf(event.target.value);
  //   if (event.target.value) {
  //     let tempArr = [...completeOrders];
  //     const indx = tempArr.findIndex((item) => item.userId == userID);
  //     if (indx < 0) {
  //       setCompleteOrders([
  //         ...completeOrders,
  //         { userId: userID, courseID: event.target.value },
  //       ]);
  //     } else {
  //       tempArr[indx].courseID = event.target.value;
  //       setCompleteOrders(tempArr);
  //     }
  //   } else {
  //     let tempArr = [...completeOrders];
  //     const indx = tempArr.findIndex((item) => item.userId == userID);
  //     if (indx >= 0) {
  //       tempArr.splice(indx, 1);
  //       setCompleteOrders(tempArr);
  //     }
  //   }
  // };

  const handleChange = (event) => {
    console.log("Changing course:", event.target.value);
    setCourseInf(event.target.value);
    // ============If we select multiple courses===============
    // if (event.target.value) {
    //   let tempArr = [...completeOrders];
    //   const indx = tempArr.findIndex(
    //     (item) => item.id == event.target.value.id
    //   );
    //   if (indx < 0) {
    //     setCompleteOrders([...completeOrders, event.target.value]);
    //   }
    // }
    // ============If we select one course===============
    if (event.target.value) {
      let tempArr = [];
      tempArr.push(event.target.value);
      setCompleteOrders(tempArr);
    }
  };

  useEffect(() => {
    console.log("Evennnnnt:", completeOrders);
  }, [completeOrders]);

  const onChangeEmailSearch = (e) => {
    if (recordProps) {
      // setEmailSelected(e.target.value);
      // setCourseStatusSelected(e.target.value);
      let searchKey = e.target.value;
      let tempAllArr = [...mainRecordProps];
      let tempSearchArr = [...searchedRecords];
      // console.log("Course status selected:", courseStatusSelected);
      if (
        courseStatusSelected.trim() === "" ||
        courseStatusSelected === "all"
      ) {
        if (searchKey.trim() === "") {
          setSearchedRecords(tempAllArr);
          // console.log("You selected All");
        } else {
          // console.log("Course status selected ma inja inja");
          var filtered = tempAllArr.filter(function (value, index, arr) {
            return (
              value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) >
              -1
            );
          });
          // console.log("You returned:", filtered);
          setSearchedRecords(filtered);
        }
      } else {
        var filtered = tempAllArr.filter(function (value, index, arr) {
          return (
            value.role === courseStatusSelected &&
            value.userEmail.toUpperCase().indexOf(searchKey.toUpperCase()) > -1
          );
        });
        // console.log("You returned:", filtered);
        setSearchedRecords(filtered);
      }
    }
    // if (recordProps) {
    //   let newArray = [...recordProps];
    //   for (var i = 0; i < newArray.length; i++) {
    //     if (
    //       newArray[i].userEmail
    //         .toUpperCase()
    //         .indexOf(e.target.value.toUpperCase()) > -1
    //     ) {
    //       newArray[i].displayEmail = false;
    //     } else {
    //       newArray[i].displayEmail = true;
    //     }
    //   }
    //   setRecordProps(newArray);
    // }
  };

  const onChangeCourseSearch = (e) => {
    if (recordProps) {
      // console.log("Entering something:", e.target.value);
      setCourseNameEntered(e.target.value);
    }
  };

  const onChangeStatusSearch = (e) => {
    if (recordProps) {
      setCourseStatusSelected(e.target.value);
    }
  };

  const onClickSubmitChanges = () => {
    if (changesApplied && changesApplied.length > 0) {
      axios.post("/userRoleChanging", {
        indexArray: changesApplied,
        allData: recordProps,
      });
    }
  };

  const onClickUserDelete = (useremail, teamid, index) => {
    let tempArr = [...recordProps];
    tempArr.splice(index, 1);
    setRecordProps(tempArr);
    axios.post("/deleteUserFromTeam", { userEmail: useremail, teamId: teamid });
  };

  const onChangeUserSelect = (e, userEmail, key) => {
    if (e.target.checked) {
      setUserSelectForRegisteration([
        ...userSelectForRegisteration,
        { userEmail: userEmail, type: "Team" },
      ]);
      let tempArr = [...recordProps];
      tempArr[key].isChecked = true;
      setRecordProps(tempArr);
      setItemsQuantity(itemsQuantity + 1);
    } else {
      let tempArr = [...userSelectForRegisteration];
      let findIndx = tempArr.map((item) => item.userEmail).indexOf(userEmail);
      if (findIndx >= 0) {
        tempArr.splice(findIndx, 1);
        setUserSelectForRegisteration(tempArr);
      }

      let tempSecArr = [...recordProps];
      tempSecArr[key].isChecked = false;
      setRecordProps(tempSecArr);
      setItemsQuantity(itemsQuantity - 1);
    }
  };

  const onClickInviteToShare = () => {
    if (userSelectForRegisteration.length > 0) {
      history("/", {
        state: { users: userSelectForRegisteration, teamId: props.teamIdProp },
      });
    }
  };

  const onClickDeleteCourse = (courseID) => {
    if (courseID) {
      let tempArr = [...completeOrders];
      const indx = tempArr.findIndex((item) => item.id == courseID);
      if (indx >= 0) {
        tempArr.splice(indx, 1);
        setCompleteOrders(tempArr);
      }
    }
  };
  //
  const onClickSaveToCardProcess = () => {
    if (
      user &&
      userSelectForRegisteration.length > 0 &&
      completeOrders.length > 0
    ) {
      // console.log("users:", userSelectForRegisteration);
      // console.log("orders:", completeOrders);

      axios
        .post("/usersTookCourses", {
          courseId: completeOrders,
          users: userSelectForRegisteration,
        })
        .then((data) => {
          console.log("updated user took course");

          setUsersTookCoursesIndexForCart(data.data);
        });
    } else {
    }
  };

  useEffect(() => {
    if (usersTookCoursesIndexForCart != null) {
      let tempArr1 = [...userSelectForRegisteration];
      let tempArr2 = [...userSelectForRegisteration];
      let tempArr3 = [];
      for (var i = 0; i < usersTookCoursesIndexForCart.length; i++) {
        tempArr1.splice(usersTookCoursesIndexForCart[i], 1);
      }
      for (var i = 0; i < usersTookCoursesIndexForCart.length; i++) {
        tempArr3.push(tempArr2[usersTookCoursesIndexForCart[i]]);
      }
      setUsersTookCoursesForCart(tempArr3);
      setUsersNotTookCoursesForCart(tempArr1);
      console.log("Create user took/not took courses");
    }
  }, [usersTookCoursesIndexForCart]);

  useEffect(() => {
    if (
      usersTookCoursesForCart !== null &&
      usersNotTookCoursesForCart !== null
    ) {
      const usersTookCourses = [...usersTookCoursesForCart];
      const usersNotTookCourses = [...usersNotTookCoursesForCart];
      const orderedCourse = [...completeOrders];
      let tempSecArr = [...recordProps];
      tempSecArr.forEach((item) => (item.isChecked = false));

      axios
        .post("/addToTeamCart", {
          teamId: recordProps[0].teamId,
          userId: user.UserID,
          courseId: orderedCourse[0].id,
          coursePrice: orderedCourse[0].price,
          courseName: orderedCourse[0].name,
          usersTookCourse: usersTookCourses,
          usersNotTookCourse: usersNotTookCourses,
        })
        .then(() => {
          console.log("added to team cart");
          setUsersTookCoursesForCart(null);
          setUsersNotTookCoursesForCart(null);
          setCourseInf("");
          setCompleteOrders([]);
          setRecordProps(tempSecArr);

          setUserSelectForRegisteration([]);
          setActiveStep(0);
          setCompleted({});
          setUsersTookCoursesIndexForCart(null);
          // localStorage.setItem("teamCart", JSON.stringify(teamCartItem));
          props.onClickResetItemNumber(props.teamIdProp);
        });

      // if (!localStorage.getItem("teamCart")) {
      //   console.log("Inja");
      //   const orders = [
      //     { usersTookCourses, usersNotTookCourses, orderedCourse },
      //   ];
      //   localStorage.setItem("teamCart", JSON.stringify(orders));
      //   setUsersTookCoursesForCart(null);
      //   setUsersNotTookCoursesForCart(null);
      //   setCourseInf("");
      //   setCompleteOrders([]);
      //   setRecordProps(tempSecArr);
      //   setUserSelectForRegisteration([]);
      //   setActiveStep(0);
      //   setCompleted({});
      //   setUsersTookCoursesIndexForCart(null);

      // } else {
      //   const oldOrders = JSON.parse(localStorage.getItem("teamCart"));
      //   // console.log("old orders:", oldOrders);
      //   // oldOrders = [
      //   //   ...{ usersTookCourses, usersNotTookCourses, orderedCourse },
      //   // ];
      //   const courseIndex = oldOrders.findIndex(
      //     (item) => item.orderedCourse[0].id === orderedCourse[0].id
      //   );

      //   if (courseIndex >= 0) {
      //     const innerUsersTookCourses = oldOrders[courseIndex].usersTookCourses;
      //     const innerUsersNotTookCourses =
      //       oldOrders[courseIndex].usersNotTookCourses;

      //     for (var i = 0; i < usersTookCourses.length; i++) {
      //       const innerUsersTookCoursesIndex = innerUsersTookCourses.findIndex(
      //         (item) => item.userEmail === usersTookCourses[i].userEmail
      //       );
      //       if (innerUsersTookCoursesIndex < 0) {
      //         oldOrders[courseIndex].usersTookCourses.push(usersTookCourses[i]);
      //       }
      //     }

      //     for (var i = 0; i < usersNotTookCourses.length; i++) {
      //       const innerUsersNotTookCoursesIndex =
      //         innerUsersNotTookCourses.findIndex(
      //           (item) => item.userEmail === usersNotTookCourses[i].userEmail
      //         );
      //       if (innerUsersNotTookCoursesIndex < 0) {
      //         oldOrders[courseIndex].usersNotTookCourses.push(
      //           usersNotTookCourses[i]
      //         );
      //       }
      //     }
      //     localStorage.setItem("teamCart", JSON.stringify(oldOrders));
      //   } else {
      //     oldOrders.push({
      //       usersTookCourses,
      //       usersNotTookCourses,
      //       orderedCourse,
      //     });
      //     localStorage.setItem("teamCart", JSON.stringify(oldOrders));
      //   }

      //   setUsersTookCoursesForCart(null);
      //   setUsersNotTookCoursesForCart(null);
      //   setCourseInf("");
      //   setCompleteOrders([]);
      //   setRecordProps(tempSecArr);
      //   setUserSelectForRegisteration([]);
      //   setActiveStep(0);
      //   setCompleted({});
      //   setUsersTookCoursesIndexForCart(null);
      // }
    }
  }, [usersTookCoursesForCart, usersNotTookCoursesForCart]);

  const onClickCheckOutProcess = async () => {
    // console.log("The payment process");

    if (
      user &&
      userSelectForRegisteration.length > 0 &&
      completeOrders.length > 0
    ) {
      // console.log("items:", completeOrders);
      // console.log("users:", userSelectForRegisteration);
      // console.log("Quantity:", itemsQuantity);
      axios
        .post("/usersTookCourses", {
          courseId: completeOrders,
          users: userSelectForRegisteration,
        })
        .then((data) => {
          // console.log("Results:", data.data);
          setUsersTookCoursesIndex(data.data);
        });
      //go to stripe
      //   const stripe = await stripePromise;
      //   const stripeSession = await axios.post("/create-checkout-session", {
      //     items: completeOrders,
      //     email: user.email,
      //     // selfEmail: selfCheckOut,
      //     // companyTeams: teamCheckOut,
      //     // giftEmails: giftCheckOut,
      //     users: userSelectForRegisteration,
      //     quantity: itemsQuantity,
      //   });
      //   // console.log("Items:", items);
      //   const result = await stripe.redirectToCheckout({
      //     sessionId: stripeSession.data.id,
      //   });
      //   if (result.error) {
      //     alert(result.error.message);
      //   }
    } else {
      // history("/login" );
    }
  };

  useEffect(() => {
    if (usersTookCoursesIndex != null) {
      let tempArr1 = [...userSelectForRegisteration];
      let tempArr2 = [...userSelectForRegisteration];
      let tempArr3 = [];
      for (var i = 0; i < usersTookCoursesIndex.length; i++) {
        tempArr1.splice(usersTookCoursesIndex[i], 1);
      }
      for (var i = 0; i < usersTookCoursesIndex.length; i++) {
        tempArr3.push(tempArr2[usersTookCoursesIndex[i]]);
      }
      setUsersTookCourses(tempArr3);
      setUsersNotTookCourses(tempArr1);
    }
  }, [usersTookCoursesIndex]);

  const stripePayment = async () => {
    if (usersNotTookCourses && usersNotTookCourses.length != 0) {
      const finalItemsQuantity = usersNotTookCourses.length;
      const stripe = await stripePK;
      const stripeSession = await axios.post("/create-checkout-session", {
        customerID: user.UserID,
        items: completeOrders,
        email: user.email,
        users: usersNotTookCourses,
        quantity: finalItemsQuantity,
        teamId: recordProps[0].teamId,
      });
      // console.log("Items:", items);
      const result = await stripe.redirectToCheckout({
        sessionId: stripeSession.data.id,
      });
      if (result.error) {
        alert(result.error.message);
      }

      const addUserCourseToTeam = await axios.post("/addUserCourseToTeam", {
        emails: usersNotTookCourses,
        teamId: recordProps[0].teamId,
        courseId: completeOrders[0].id,
      });
    }

    // console.log("users took courses:", usersTookCourses);
    // console.log("team id:", recordProps[0].teamId);
    // console.log("order id:", completeOrders[0].id);
    // console.log("buyer:", user.fullName);
    if (usersTookCourses && usersTookCourses.length != 0) {
      const sendNote = await axios.post("/inviteToShare", {
        emails: usersTookCourses,
        teamId: recordProps[0].teamId,
        courseId: completeOrders[0].id,
        fullName: user.fullName,
      });
    }
  };

  useEffect(() => {
    if (usersTookCourses && usersNotTookCourses) {
      stripePayment();
    }
  }, [usersTookCourses, usersNotTookCourses]);

  useEffect(() => {
    console.log("All data:", recordProps);
  }, [recordProps]);

  // ====================functions============================

  const userTable = (
    <div style={{ margin: "30px 0px 20px 0px" }}>
      <CustomTable>
        <SearchTable>
          <EmailSearchContainer>
            <EmailSearch
              onChange={(e) => onChangeEmailSearch(e)}
              placeholder="Email..."
            />
            <EmailSearchButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </EmailSearchButton>
          </EmailSearchContainer>
        </SearchTable>
        {/* ===========main pagination table======= */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "350px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedRecords ? (
                  searchedRecords
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            display:
                              !row?.displayEmail &&
                              !row?.displayCourse &&
                              !row?.displayStatus
                                ? ""
                                : "none",
                          }}>
                          <TableCell>{row.userEmail}</TableCell>
                          <TableCell>
                            <SelectCompanyUser
                              type="checkbox"
                              onChange={(e) =>
                                onChangeUserSelect(e, row.userEmail, row.id)
                              }
                              disabled={
                                row.userEmail != user.email ? false : true
                              }
                              checked={row?.isChecked}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow></TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={
              searchedRecords
                ? searchedRecords?.length
                : searchedRecords?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* ======================================== */}
        {/* <Header>
          <FirstHeader>Student Email</FirstHeader>
          <ThirdHeader>Select User</ThirdHeader>
        </Header>
        <TableBody>
          {recordProps ? (
            recordProps.map((item, key) => (
              <TableRow
                style={{
                  display:
                    !item?.displayEmail &&
                    !item?.displayCourse &&
                    !item?.displayStatus
                      ? ""
                      : "none",
                }}
              >
                <FirstColumn>{item.userEmail}</FirstColumn>
                <ThirdColumn>
                  <SelectCompanyUser
                    type="checkbox"
                    onChange={(e) => onChangeUserSelect(e, item.userEmail, key)}
                    disabled={item.userEmail != user.email ? false : true}
                    checked={item?.isChecked}
                  ></SelectCompanyUser>
                </ThirdColumn>
              </TableRow>
            ))
          ) : (
            <div></div>
          )}
        </TableBody> */}
      </CustomTable>
      {/* <RegisterationButtonsContainer>
        <InviteToShareButton onClick={() => onClickInviteToShare()}>
          Invite to Share
        </InviteToShareButton>
        <CourseRegisterationButton onClick={() => onClickCourseRegisteration()}>
          Register a Course
        </CourseRegisterationButton>
      </RegisterationButtonsContainer>{" "} */}
    </div>
  );

  const guidLines = (
    <div style={{ margin: "30px 0px 20px 0px" }}>
      <p>
        - You can select user(s) in the second step to register course(s) for
        them. The course(s) can be selected in the third step.
      </p>
      <p>
        - Once you make sure that you complete a step, please click on COMPLETE
        STEP button on the bottom of the page. Otherwise, the FINISH button will
        not appear and the registration process will not be done.
      </p>
      <p>
        - Once you click on COMPLETE STEP button, you cannot return to the step
        unless you change the page to refresh the registration process.
      </p>
      <p>
        - You can move forward and backward among the steps by clicking on the
        BACK and NEXT buttons on the bottom of the page or click on the steps on
        the top of the page.
      </p>
      <p>
        - Once all the steps are completed, the FINISH button appears. By
        clicking on the button, you will be redirected to the payment page.
      </p>
      <p>
        - All the selected courses will be registered for all the selected
        users. For example, if you select users A and B, and courses C and D,
        you will pay for courses C and D two times. One time for user A, and the
        second time for user B.
      </p>
      <p>
        - If user(s) have already took course(s), they will only recieve a note
        to share their course status with your team and you will not be charged
        for the course(s).
      </p>
    </div>
  );

  const courseSelect = (
    <SelectUserCourse>
      <FormControl className="course-select">
        <InputLabel id="demo-multiple-name-label">Course</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          // multiple
          value={courseInf}
          onChange={(e) => handleChange(e)}
          input={<OutlinedInput label="Course" />}
          MenuProps={MenuProps}>
          {/* <MenuItem
            value={""}
            key={0}
            // style={getStyles(courseItems.name, personName, theme)}
          >
            None
          </MenuItem> */}
          {props.courseInformation.map((courseItems, key) => (
            <MenuItem
              value={courseItems}
              key={key + 1}
              // style={{ padding: "0px 5px 0px 5px" }}
              // style={getStyles(courseItems.name, personName, theme)}
            >
              {courseItems.name}-{courseItems.state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* ====================Showing selected courses when multiple section============= */}
      {/* <OrderItems>
        {completeOrders ? (
          completeOrders.map((order) => (
            <SingleOrder>
              <DeleteCourse onClick={() => onClickDeleteCourse(order.id)}>
                <CancelIcon />
              </DeleteCourse>
              <SingleOrderContent>
                {order.name}
                {"-"}
                {order.state}
              </SingleOrderContent>
            </SingleOrder>
          ))
        ) : (
          <div></div>
        )}
      </OrderItems> */}
      {/* ================================================================================= */}
    </SelectUserCourse>
  );

  const stepsContent = [guidLines, userTable, courseSelect];

  useEffect(() => {
    if (props.data) {
      setRecordProps(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (recordProps) {
      let tempArr = [...recordProps];
      // console.log("Team members:", recordProps);
      tempArr.forEach((item, key) => {
        item.id = key;
      });
      // console.log("Temp array:", tempArr);
      setMainRecordProps(tempArr);
    }
    return () => {
      setMainRecordProps(null);
    };
  }, [recordProps]);

  useEffect(() => {
    // console.log("My Indexes:", changesApplied);
  }, [changesApplied]);

  useEffect(() => {
    // console.log("user Select list:", userSelectForRegisteration);
  }, [userSelectForRegisteration]);

  useEffect(() => {
    // console.log("Some props ca i se:", props.courseInformation);
  }, [props.courseInformation]);

  useEffect(() => {
    return () => {
      setMainRecordProps(null);
      setRecordProps(null);
    };
  }, []);

  useEffect(() => {
    if (mainRecordProps) {
      setSearchedRecords(mainRecordProps);
    }
  }, [mainRecordProps]);

  return (
    <Container>
      <Box sx={{ width: "100%", height: "580px", position: "relative" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography> */}
              {/* {userTable} */}
              {stepsContent[activeStep]}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  position: "absolute",
                  bottom: "0",
                }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep !== totalSteps() - 1 ? (
                  <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button>
                ) : (
                  <div></div>
                )}

                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <></>
                  ) : completedSteps() === totalSteps() - 1 ? (
                    <EndOfProcessButtons>
                      <Button
                        onClick={() => onClickSaveToCardProcess()}
                        style={{ marginRight: "5px" }}>
                        Save to cart
                      </Button>
                      <Button onClick={() => onClickCheckOutProcess()}>
                        Check Out
                      </Button>
                    </EndOfProcessButtons>
                  ) : (
                    <div></div>
                    // <Button onClick={handleComplete}>Complete Step</Button>
                  ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      {completed[activeStep] ? (
        <Typography variant="caption" sx={{ display: "inline-block" }}>
          Step {activeStep + 1} already completed
        </Typography>
      ) : (
        <></>
      )}
      {/* =========================================================================== */}
    </Container>
  );
}

export default CourseRegistrationCompany;

const EndOfProcessButtons = styled.div``;

const Container = styled.div`
  /* padding: 90px 20px 20px 20px; */
  padding: 20px;
`;
const CustomTable = styled.div`
  width: 100%;
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 5px; */
  margin-bottom: 10px;
`;
const Header = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
`;
// const TableBody = styled.div`
//   overflow: auto;
// `;
const FirstColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const SecondColumn = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;
const ThirdColumn = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 165px;
`;

const SelectUserCourse = styled.div`
  .course-select {
    width: 100% !important;
    margin: 30px 0px 20px 0px !important;
  }
`;
// const TableRow = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;

const FirstHeader = styled.div``;
const SecondHeader = styled.div``;
const ThirdHeader = styled.div``;

const SearchContainers = [
  `
  position:relative;
display: flex;
justify-content: center;
align-items: center;
padding:5px;
`,
];

const EmailSearchContainer = styled.div(SearchContainers);
const CourseSearchContainer = styled.div(SearchContainers);
const StatusSearchContainer = styled.div(SearchContainers);

const SearchBoxes = [
  `
width: 100%;
padding: 7px 7px 7px 28px;

option{
    // padding:7px 0px 7px 0px;
    // height:20px;
}
`,
];

const SearchButtons = [
  `
position:absolute;
border:none;
outline:none;
left:15px;
background-color: transparent;
// cursor:pointer;
svg{
    fill:rgba(0,0,0,0.5)
}
`,
];

const EmailSearch = styled.input(SearchBoxes);
const EmailSearchButton = styled.button(SearchButtons);

const CourseSearch = styled.input(SearchBoxes);
const CourseSearchButton = styled.button(SearchButtons);

const StatusSearch = styled.select`
  ${SearchBoxes}
`;
const StatusSearchButton = styled.button(SearchButtons);

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  background-color: green;
  border: none;
  outline: none;
`;

const DeleteCompanyUserButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`;
const InviteUserContainer = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 150px;
  grid-gap: 5px;
`;
const InviteUserText = styled.input`
  padding: 5px;
  margin-right: 5px;
`;
const InviteUserButton = styled.button`
  cursor: pointer;
  padding: 5px;
`;

const SelectCompanyUser = styled.input``;

const RegisterationButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CourseRegisterationButton = styled.button`
  padding: 5px;
`;
const InviteToShareButton = styled.button`
  padding: 5px;
  margin-right: 5px;
`;

const OrderItems = styled.div``;
const SingleOrder = styled.div`
  margin: 5px 0px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid gray;
  border-radius: 50px;
  width: fit-content;
`;

const DeleteCourse = styled.div`
  position: absolute;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SingleOrderContent = styled.div`
  padding-left: 30px;
`;
