import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import axios from "axios";

// Function to create data objects
function createData(name, calories, fat, protein, description) {
  return {
    name,
    calories,
    fat,
    protein,
    description,
  };
}

// Sample data

function TeamsOrder(props) {
  const [filters, setFilters] = useState({
    name: "",
    calories: "",
    fat: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [initialData, setInitialData] = useState([
    // createData("Frozen yoghurt", 159, 6.0, 24, 4.0, "A cold, delicious treat."),
    // createData("Ice cream sandwich", 237, 9.0, 37, 4.3, "A classic favorite."),
    // createData("Eclair", 262, 16.0, 24, 6.0, "A delightful pastry."),
    // createData("Cupcake", 305, 3.7, 67, 4.3, "A small cake."),
    // createData("Gingerbread", 356, 16.0, 49, 3.9, "A spicy, sweet cookie."),
    // createData("Jelly Bean", 375, 0.0, 94, 0.0, "A sweet, colorful candy."),
    // createData("Lollipop", 392, 0.2, 98, 0.0, "A sugary treat on a stick."),
    // createData("Honeycomb", 408, 3.2, 87, 6.5, "A sweet and crunchy delight."),
    // createData("Donut", 452, 25.0, 51, 4.9, "A popular fried dessert."),
    // createData("Frozen", 518, 26.0, 65, 7.0, "A chocolate-covered wafer bar."),
  ]);

  // Handler for filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setPage(0); // Reset to first page on filter change
  };

  // Function to filter data based on multiple filters
  const filteredData = useMemo(() => {
    return initialData.filter((row) => {
      const nameMatch = row.fat
        ?.toLowerCase()
        .includes(filters.fat.toLowerCase());

      // For numeric filters, allow partial matching or exact matching
      const caloriesMatch =
        filters.calories === "" ||
        row.calories?.toString().includes(filters.calories);

      const fatMatch =
        filters.name === "" || row.name.toString().includes(filters.name);

      return nameMatch && caloriesMatch && fatMatch;
    });
  }, [filters, initialData]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {}, []);

  useEffect(async () => {
    if (props.currentTeamId) {
      console.log("Tem Id in:", props.currentTeamId);
      await axios
        .post("/getTeamOrder", { teamId: props.currentTeamId })
        .then((data) => {
          console.log(data.data);
          let tempArr = [...initialData];
          console.log("All Data:", tempArr);
          for (const item of data.data.orders) {
            const date = new Date(item.timestamp);
            const formattedDate = date.toLocaleString();
            tempArr.push(
              createData(
                item.amount,
                formattedDate,
                item.buyerEmail,
                item.completePurchases,
                item.pendingPurchases
              )
            );
          }
          setInitialData(tempArr);
          console.log("All Data after:", tempArr);
        });
    }
  }, [props.currentTeamId]);

  return (
    <Paper sx={{ padding: 2, height: "100%", borderRadius: "0px" }}>
      {/* Filter Section */}
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Price"
              variant="outlined"
              fullWidth
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Date"
              variant="outlined"
              fullWidth
              name="calories"
              value={filters.calories}
              onChange={handleFilterChange}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Filter by Order By"
              variant="outlined"
              fullWidth
              name="fat"
              value={filters.fat}
              onChange={handleFilterChange}
              type="number"
              inputProps={{ min: 0, step: 0.1 }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Table Section */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Price</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Ordered by</TableCell>
              {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row key={index} row={row} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

// Component for each row with expandable details
function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  const collapseRef1 = useRef(null);
  const collapseRef2 = useRef(null);

  useEffect(() => {
    if (open) {
      // Measure the heights of both Collapse components
      const height1 = collapseRef1.current
        ? collapseRef1.current.scrollHeight
        : 0;
      console.log("Height1:", height1);
      const height2 = collapseRef2.current
        ? collapseRef2.current.scrollHeight
        : 0;
      console.log("Height2:", height2);

      // Determine the maximum height
      const maxHeight = Math.max(height1, height2);
      setMaxHeight(maxHeight);
    }
  }, [
    open,
    collapseRef1.current?.scrollHeight,
    collapseRef2.current?.scrollHeight,
  ]);

  return (
    <>
      <TableRow hover>
        <TableCell style={{ width: "50px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right" style={{ width: "300px" }}>
          {row.calories}
        </TableCell>
        <TableCell align="right" style={{ width: "300px" }}>
          {row.fat}
        </TableCell>
        {/* <TableCell align="right">{row.carbs}</TableCell> */}
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            // style={{
            //   height: open ? `${maxHeight}px !important` : 0,
            //   overflow: "hidden",
            // }}
          >
            <Box
              margin={1}
              ref={collapseRef1}
              style={{ minHeight: `${maxHeight}px` }}>
              <Typography variant="h6" gutterBottom component="div">
                Complete Purchases:
              </Typography>

              {Object.entries(row.protein).map(([email, items]) => (
                <div key={email}>
                  <h3>{email}</h3>
                  {console.log("Items here:", items)}
                  {Array.isArray(items) && items.length > 0 ? (
                    <ul>
                      {items.map((item) => (
                        <li key={item.itemId}>
                          <strong>Item Name:</strong> {item.itemName} <br />
                          <strong>Item Price:</strong> $
                          {item.itemPrice.toFixed(2)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No items available.</p>
                  )}
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            // style={{
            //   height: open ? `${maxHeight}px !important` : 0,
            //   overflow: "hidden",
            // }}
          >
            <Box
              margin={1}
              ref={collapseRef2}
              style={{ minHeight: `${maxHeight}px` }}>
              <Typography variant="h6" gutterBottom component="div">
                Pending Purchases:
              </Typography>

              {Object.entries(row.description).map(([email, items]) => (
                <div key={email}>
                  <h3>{email}</h3>
                  {console.log("Items here:", items)}
                  {Array.isArray(items) && items.length > 0 ? (
                    <ul>
                      {items.map((item) => (
                        <li key={item.itemId}>
                          <strong>Item Name:</strong> {item.itemName} <br />
                          <strong>Item Price:</strong> $
                          {item.itemPrice.toFixed(2)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No items available.</p>
                  )}
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TeamsOrder;
